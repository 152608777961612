<div>
	<div>
		<form class="form-horizontal needs-validation submit-ticket-sec" novalidate="novalidate" [formGroup]="ticketForm"
			(ngSubmit)="submitTicket()">
			<div class="modal-content">
				<div class="modal-header py-2">
					<h5 class="modal-title">{{'HelpModule.Submit Ticket' | translate}}</h5>
					<button type="button" class="close " (click)="closeModal()">
						<span aria-hidden="true">×</span>
					</button>
				</div>
				<div class="modal-body">
					<div class="row">
						<div class="col-lg-3">
							<!-- <div class="card card-body" style="line-height: 1.8"> -->
							<div class="card p-3 mb-0" style="min-height: 350px;">
								<div class="row">
									<div class="col-lg-12 d-flex align-items-center" *ngIf="!isDigiSME">
										<label class="col-form-label mr-4" for="Product">{{'Product' | translate}}<span
												class="text-danger">*</span></label>
										{{ ProductID }}
										<br>
										<b>Info-Accounting</b>
									</div>
									<div class="col-lg-12" *ngIf="!isDigiSME">
										<label class="col-form-label" for="ticketTypeLst">{{'HelpModule.Ticket Type' | translate}}<span
												class="text-danger">*</span></label>
										<ng-select [items]="ticketTypeLst" placeholder="{{'HelpModule.Select Ticket Type' | translate}}"
											id="ticketTypeLst" class="auto-grow" [clearable]="false" bindLabel="title"
											bindValue="id" formControlName="ticketType">
										</ng-select>
										<div class="text-danger" *ngIf="submitted && ticket.ticketType.errors">
											<div *ngIf="ticket.ticketType.errors.required">Required</div>
										</div>
									</div>
									<div class="col-lg-12" *ngIf="!isDigiSME && customerData?.Inhousetransferc?.toUpperCase()=='NO'">
										<label class="col-form-label" for="assignTo">{{'HelpModule.Assign To' | translate}}<span class="text-danger ">*</span></label>
										<ng-select [items]="assignToLst" placeholder="{{'HelpModule.Select Assign To' | translate}}" id="assignTo" class="auto-grow"
										  bindLabel="title" bindValue="id" [clearable]="false" formControlName="assignTo">
										</ng-select>
										<div class="text-danger" *ngIf="submitted && ticket.assignTo.errors && customerData.Inhousetransferc.toUpperCase()=='NO'">
										  <div *ngIf="ticket.assignTo.errors.required">Required</div>
										</div>
									  </div>
									<!-- </div> -->
									<!-- <div class="row"> -->
									<div class="col-lg-12" *ngIf="!isDigiSME">
										<label class="col-form-label" for="ticketAccess">{{'HelpModule.Ticket Access' | translate}}<span
												class="text-danger">*</span></label>
										<ng-select [items]="ticketAccessLst" placeholder="{{'HelpModule.Select Ticket Access' | translate}}"
											id="ticketAccess" class="auto-grow" bindLabel="title" bindValue="id"
											[clearable]="false" formControlName="ticketAccess">
										</ng-select>
										<div class="text-danger" *ngIf="submitted && ticket.ticketAccess.errors">
											<div *ngIf="ticket.ticketAccess.errors.required">Required</div>
										</div>
										<!-- </div> -->
									</div>
									<!-- <div class="row"> -->
									<div class="col-lg-12" *ngIf="IsNew">
										<label class="col-form-label">{{'HelpModule.Subject' | translate}} <span class="text-danger">*</span></label>

										<input type="text" id="subject" name="subject" class="form-control"
											formControlName="subject">
										<div class="text-danger" *ngIf="submitted && ticket.subject.errors">
											<div *ngIf="ticket.subject.errors.required">Required</div>
										</div>
									</div>
									<div class="col-lg-12"
										*ngIf="IsNew || (ticketStatus == 'Provide More Data' || ticketStatus == 'Provide More Data - User')">
										<label class="col-form-label" for="remarks">{{'HelpModule.Remarks' | translate}} <span
												class="text-danger">*</span></label>
										<quill-editor class="w-100" id="remarks" formControlName="remarks" [preserveWhitespace]="true">
											<div quill-editor-toolbar>
												<!-- <span class="ql-formats">

													<select class="ql-size" [title]="'Size'">
														<option value="6pt"></option>
														<option value="8pt"></option>
														<option value="9pt"></option>
														<option value="10pt"></option>
														<option value="11pt"></option>
														<option value="12pt"></option>
														<option value="13pt"></option>
														<option value="14pt"></option>
														<option value="16pt"></option>
														<option value="18pt"></option>
														<option value="24pt"></option>
														<option value="30pt"></option>
														<option value="36pt"></option>
														<option value="48pt"></option>
													</select>
												</span> -->
												<span class="ql-formats">
													<button class="ql-bold"></button>
													<button class="ql-italic"></button>
													<!-- <button class="ql-underline"></button>
													<button class="ql-strike"></button> -->
												</span>
												<!-- <span class="ql-formats">
													 <select class="ql-color"></select>
													<select class="ql-background"></select> 
												</span> -->
												<span class="ql-formats">
													<button class="ql-list" value="ordered"></button>
													<button class="ql-list" value="bullet"></button>
													<select class="ql-align">
														<option selected></option>
														<option value="center"></option>
														<option value="right"></option>
														<option value="justify"></option>
													</select>
												</span>


											</div>
										</quill-editor>
									</div>
									<!-- </div> -->

									<!-- <div class="row col my-3" *ngIf="!IsNew && (ticketStatus == 'Provide More Data' || ticketStatus == 'Provide More Data - User')">
					<button style="height:35px;width:80px;margin-left: auto;" type="button" (click)="replyTicket()" class="btn btn-info"> Reply </button>
		
					<button style="height:35px;width:80px;margin-left: auto;" type="button" class="btn btn-info" (click)="cancel()"> Back </button>
				  </div> -->
									<!-- <div class="row">
					<div id="tui-image-editor"></div>

				</div> -->
								</div>
							</div>
						</div>
						<div class="col-lg-9">
							<label class="col-form-label d-none" for="attachment">{{'SharedModule.Attachment' | translate}}</label>
							<div id="tui-image-editor"></div>
								<a  (click)="deletescreenshot()" class="btn btn-link"
								  style="text-decoration: underline;">
								  <h6 class="card-subtitle">{{'HelpModule.Delete Screenshot' | translate}}</h6>
								</a>
						</div>
					</div>


				</div>
				<div class="modal-footer mt-0 py-1">

					<div class="row w-100">
						<div class="col d-flex align-items-center" >
							<div class="" *ngIf="!isDigiSME">
								<input type="checkbox" id="agreeTC" class="form-check-input" style=" border: 2px solid #000;"
									formControlName="agreeTerms" />
								<label class="form-check-label mr-4 text-danger font-weight-bold" for="agreeTC">
									{{'HelpModule.I agree to grant data view access to Info-Tech for trouble shooting purpose' | translate}}
								</label>
							</div>
						</div>
						<div class="col-auto">
							<button type="submit" class="btn btn-info"> {{'Common.Submit' | translate}} </button>

							<button type="button" class="btn btn-info" (click)="closeModal()"> {{'Common.Cancel' | translate}} </button>
						</div>
					</div>

				</div>
			</div>

		</form>
		<!-- /.modal-content-->
	</div>
	<!-- /.modal-dialog-->
</div>