import { AllCommunityModules, ColDef, EditableCallbackParams, GridApi, GridOptions, Module } from '@ag-grid-community/all-modules';
import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { AgdatepickerComponent } from '../gridComponent/agdatepicker.component';
import { AlphanumericEditor } from '../gridComponent/alphanumeric-editor.component';
import { QuanityUOMComponent } from '../gridComponent/quanity-uom.component';
import { HttpServices } from '../../../utilities/_services/http.service';
import { DatePipe } from '@angular/common';
import { AlertMessage } from '../../../utilities/_helpers/alert.message';
import * as moment from 'moment';
import { environment } from '../../../../environments/environment';
import { BatchlistComponent } from '../batchlist/batchlist.component';
import Swal from 'sweetalert2';
import { LocalCacheServices } from '../../../utilities/_services/acclocalcache.service';
import { WarehouselistComponent } from '../warehouselist/warehouselist.component';
// import { setColumnDefaults } from '@swimlane/ngx-datatable';

@Component({
  selector: 'app-assignbatch',
  templateUrl: './assignbatch.component.html',
  styleUrls: ['./assignbatch.component.scss']
})
export class AssignbatchComponent implements OnInit {

  @ViewChild('modalAssignBatch')
  public modalAssignBatch: ModalDirective;
  @Output() emitOnInvoiceSumbit = new EventEmitter<any>();
  trackingMethod: string = 'Batch Number';
  batchItemDetails: any = [];
  batchItemsGridOptions: GridOptions;
  modules: Module[] = AllCommunityModules;
  itemscolumnDefs: ColDef[] = [];
  batchMasterData: any = [];
  assignedQty: number = 0;
  unassignedQty: number = 0;
  _requiredQuantity: number = 0;
  _POQuantity: number = 0;
  frameworkComponents = {
    datePicker: AgdatepickerComponent,
    alphanumeric: AlphanumericEditor,
    quantity: QuanityUOMComponent,
    batchList: BatchlistComponent,
    warehouseList: WarehouselistComponent
  }
  orgSettings: any;
  isWarehouseEnabled: boolean = false;
  itemID: number = 0;
  _mode: string = 'View';
  _transactionTypeId: number = 0;
  _transactionDetailId: number = 0;
  DODetailId: number;
  transactionQuantity: number;
  _quantityLabel: string = 'Invoice';
  _transactionType: any = [
    { transactionTypeId: 6, transactionTypeName: 'Invoice' },
    { transactionTypeId: 21, transactionTypeName: 'Delivery Order' },
    { transactionTypeId: 30, transactionTypeName: 'Sales Debit Note' },
  ];
  lstBatch: any;
  searchText: string;
  totalGridRows: number = 0;
  lstWarehouse: any;
tracktype:string='';
  agGridHearderColumnDefTemplateString: string = '<div class="ag-cell-label-container" role="presentation">' +
    '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
    '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
    '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
    '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
    '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
    '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
    '    <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>' +
    '    &nbsp <span class="text-danger">*</span>' +
    '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
    '  </div>' +
    '</div>';
    gridApi:GridApi;
    isEnabledActionButtons: boolean = true;
    @Output() emitOnDebitNoteSumbit = new EventEmitter<any>();
  constructor(private http: HttpServices,
    private datepipe: DatePipe, private local: LocalCacheServices,
    private alertMessage: AlertMessage) {

  }

  ngOnInit(): void {
    this.orgSettings = JSON.parse(this.local.getlocalStorage(this.local.localCurrentOrganization));
    this.isWarehouseEnabled = this.orgSettings.enableWarehouse;
    this.batchItemsGridOptions = <GridOptions>{
      domLayout: "autoHeight",
      sortable: true,
      filter: true,
      wrapText: true,
      autoHeight: true,
      rowHeight: 40,
      stopEditingWhenCellsLoseFocus: true,
      onGridReady: (params: any) => {
        this.batchItemsGridOptions.api.sizeColumnsToFit();
        this.gridApi = params.api;
      },
      onGridSizeChanged: () => {
        this.batchItemsGridOptions.api.sizeColumnsToFit();
      },
    }
    this.itemscolumnDefs = [
      {
        headerName: "Warehouse",
        field: "warehouseID",
        sortable: true,
        filter: true,
        wrapText: true,
        editable: (params: EditableCallbackParams) => {
          return !this.isWarehouseEnabled || !!!params.data.IsLocked;
        },
        cellEditor: 'warehouseList',
        hide: !this.isWarehouseEnabled,
        cellEditorParams: {
          lstWarehouse: []
        },
        cellStyle: {
          'overflow-y': 'auto!important',
          'overflow': 'visible'
        },
        cellRenderer: (params: any) => {
          return this.lookupValue_Warehouse(this.lstWarehouse, params);
        },
        headerComponentParams: {
          template: this.agGridHearderColumnDefTemplateString
        }
      },
      {
        headerName: "Batch Number",
        field: "batchID",
        sortable: true,
        filter: true,
        wrapText: true,
        editable: (params: EditableCallbackParams) => {
          return !!!params.data.IsLocked;
        },
        autoHeight: true,
        cellEditor: 'batchList',
        cellEditorParams: {
          lstBatch: [],
          allBatchs: [],
          isWarehouseEnabled: this.isWarehouseEnabled
        },
        cellStyle: {
          'overflow-y': 'auto!important',
          'overflow': 'visible'
        },
        cellRenderer: (params: any) => {
          return this.lookupValue(this.lstBatch, params);
        },
        headerComponentParams: {
          template: this.agGridHearderColumnDefTemplateString
        }
      },
      {
        headerName: "Description",
        field: "description",
        sortable: true,
        filter: true,
        editable: false
      },
      {
        headerName: "Manufactured Date",
        field: "manufacturedDate",
        sortable: true,
        filter: true,
        editable: false,
        cellEditor: 'datePicker',
        valueFormatter: this.dateFormatter,
      },
      {
        headerName: "Expiry Date",
        field: "expiryDate",
        sortable: true,
        filter: true,
        wrapText: true,
        editable: false,
        cellEditor: 'datePicker',
        autoHeight: true,
        valueFormatter: this.dateFormatter,
      },
      {
        headerName: "Available Qty",
        field: "availableQty",
        sortable: true,
        filter: true,
        wrapText: true,
        editable: false,
        cellEditorParams: {
          decimalType : 'Decimal10'
        },
        autoHeight: true,
      },
      {
        headerName: "Quantity",
        field: "quantity",
        sortable: true,
        filter: true,
        wrapText: true,
        cellEditorParams: {
          decimalType : 'Decimal10'
        },
        editable: (params: EditableCallbackParams) => {
          return !!!params.data.IsLocked;
        },
        autoHeight: true,
        cellEditor: 'quantity',
        cellStyle: { height: '40px !important;' },
        headerComponentParams: {
          template: this.agGridHearderColumnDefTemplateString
        }
      },
      {
        headerName: "",
        field: "Delete",
        editable: false,
        cellStyle: { textAlign: 'center' },
        cellRenderer: (params) => {
          if (params.node.data.IsLocked && params.node.data.IsLocked) {
            return `<div  tabindex="-1" unselectable="on" role="gridcell" aria-colindex="1"  class="ag-cell ag-cell-not-inline-editing ag-cell-auto-height" style="left: 0px;bottom:0px; pointer-events: none; opacity: 0.4;">
            <span class="c-icon material-icons-outlined m-0" style="font-size:20px">lock</span>
          </div>`;
          }
          else {
            return '<span class="close-x" data-action="delete">x</span>';
          }
        },
        width: 50,
        maxWidth: 50,
      }
    ];
  }

  dateFormatter(params) {
    return params.value == "" ? params.value : (moment(params.value)).format('DD/MM/YYYY');
  }

  onCellClicked(params: any) {
    const colId = params.column.getId();
    if (!!!params.data.IsLocked && (colId === "Delete")) {
      this.onSortChanged();
      let BatchId = params.data.batchID;
      let isTemporary = params.data.isTemporary;
      const selectedRow = this.batchItemsGridOptions.api.getFocusedCell();
      this.batchMasterData.splice(selectedRow.rowIndex, 1);
      this.assignedQty = this.calculateAssingedBatchCount();
      this.batchItemsGridOptions.api.setRowData(this.batchMasterData);
    } else if (colId == 'batchID') {
      if (this.isWarehouseEnabled) {
        this.batchItemsGridOptions.api.refreshCells({ force: true });
      }
    }
    this.setDropdownValues_Warehouses_Batches();
  }

  onDeleteInvoiceBatchItemDetails() {
    this.http.deleteservice(environment.salesApiUrl + `/SalesInvoice/DeleteInvoiceBatchitems?ItemId=${this.itemID}&InvoiceTransDetailId=${this._transactionDetailId}`).subscribe({
      next: (data: any) => {
        if (data.isSuccess === true) {
          this.alertMessage.successNotifier("Saved Successfully", 0);
          this.emitOnInvoiceSumbit.emit({trans:'Invoice',track:this.tracktype});
          this.closeModal();
        }
        else {
          this.alertMessage.errorNotifier(data.ResponseData, 0);
        }
      }
    });
  }

  onDeleteDOBatchItemDetails() {
    this.http.deleteservice(environment.salesApiUrl + `/Delivery/DeleteDeliveryBatchitems?ItemId=${this.itemID}&DeliveryDetailId=${this._transactionDetailId}`).subscribe({
      next: (data: any) => {
        if (data.isSuccess === true) {
          this.alertMessage.successNotifier("Saved Successfully", 0);
          this.emitOnInvoiceSumbit.emit({trans:'DO',track:this.tracktype});
          this.closeModal();
        }
        else {
          this.alertMessage.errorNotifier(data.ResponseData, 0);
        }
      }
    });
  }

  closeModal() {
    this.modalAssignBatch.hide();
  }


  async onSubmit() {
    if (this._transactionTypeId == 21) {
      if (this.onUIValidate()) {
        this.closeModal();
        this.onSaveUpdateDeliveryBatchDetails();
      }
    }
    else if (this._transactionTypeId == 6){
      if (this.onUIValidate()) {
        this.onSaveUpdateInvoiceBatchDetails();
      }
    }
    else if (this._transactionTypeId == 30){
      if (this.onUIValidate()) {
        this.onSaveUpdateDebitNoteBatchDetails();
      }
    }
      
  }
  addRows(count): void {
    // const currentDate = this.datepipe.transform(new Date(), 'dd MMM yyyy');
    for (let i = 1; i <= count; i++) {
      let rowFeild = {
        batchID: 0,
        manufacturedDate: '',
        expiryDate: '',
        availableQty: '',
        quantity: '',
        isTemporary: 1,
        warehouseID: 0,
        transactionTypeId:this._transactionTypeId
        // itemId: this.itemID
      }
      this.batchMasterData.push(rowFeild);
      this.batchItemsGridOptions.api.addItems([rowFeild]);
    }
  }

  async openModal(_itemID: number, _transactionQuantity: number, _transactionDetailId: number, _transactionTypeId: number, _mode: string,_isPriceAdjustment?:boolean) {
    this.batchItemsGridOptions.columnApi.applyColumnState({ defaultState: { sort: null } });
    this._transactionTypeId = _transactionTypeId;
    this._transactionDetailId = _transactionDetailId;
    this._quantityLabel = this._transactionType.find(x => x.transactionTypeId == _transactionTypeId).transactionTypeName;
    if (_transactionTypeId == 6) { //Batch from Invoice Order
      await this.setBatchList(_itemID);
      await this.getAssignedBatchByInvoiceDetailId(_transactionDetailId, _itemID, _transactionQuantity);
      this.modalAssignBatch.show();
    } else if (_transactionTypeId == 21) { //Batch from Delivery Order
      await this.setBatchList(_itemID);
      await this.getAssignedBatchByDODetailId(_transactionDetailId, _itemID, _transactionQuantity);
      this.modalAssignBatch.show();
    }
    if (_transactionTypeId == 30) { //Batch from Sales Debit Note
      if(_isPriceAdjustment == true){
        this.isEnabledActionButtons = false;
      }
      else{
        this.isEnabledActionButtons = true;
      }
      await this.setBatchList(_itemID);
      await this.getAssignedBatchByDebitNoteDetailId(_transactionDetailId, _itemID, _transactionQuantity);
      this.modalAssignBatch.show();
    }
  }

  async setBatchList(_itemID: number) {
    this.lstBatch = await this.getBatchList(_itemID, this._transactionDetailId);
    this.lstWarehouse = await this.getWarehouseDetails();
    this.setDropdownValues_Warehouses_Batches();
  }

  async getWarehouseDetails(): Promise<any> {
    let returnvalue: any[];
    let data: any;
    data = await this.http.getserviceawait(environment.purchaseApiUrl + `/Bills/GetWareHouseDetails`);
    const Tn = data.responseData
    if (data.isSuccess && data.responseData != null) {
      returnvalue = data.responseData.map(row => ({
        warehouseID: row.warehouseID,
        warehouseName: row.warehouseName
      }));
    }
    return returnvalue;
  }

  async getAssignedBatchByInvoiceDetailId(_invoiceDetailId: number, _itemID: number, _transactionQuantity: number) {
    this.itemID = _itemID;
    let data: any = await this.http.getserviceawait(environment.salesApiUrl + `/SalesInvoice/GetAssignedBatch?InvoiceDetailId=${_invoiceDetailId}&ItemId=${_itemID}`);
    if (data.isSuccess && data.responseData != null) {
      this.batchMasterData = [];
      this.batchItemDetails = data.responseData;
      this.assignedQty = this.batchItemDetails.assignedQuantity;
      this.unassignedQty = this.batchItemDetails.unassignedQuantity;
      if (!isNaN(_transactionQuantity)) {
        this._requiredQuantity = _transactionQuantity;
      }
      data.responseData.batchMasterDetails.forEach(ele => {
        let rowFeild = {
          batchID: ele.batchID,
          batchNo: ele.batchNo,
          description: ele.description,
          manufacturedDate: ele.manufacturedDate == null ? "" : ele.manufacturedDate,
          expiryDate: ele.expiryDate == null ? "" : ele.expiryDate,
          lastSaleDate: ele.lastSaleDate == null ? "" : ele.lastSaleDate,
          quantity: ele.quantity,
          availableQty: ele.availableQty,
          // availableQty: this.lstBatch.find(lb=>lb.batchID==ele.batchID).availableQty,
          isTemporary: 0,
          warehouseID: ele.warehouseID,
          IsLocked: ele.isLocked
        }
        this.batchMasterData.push(rowFeild);
        this.batchItemsGridOptions.api.addItems([rowFeild]);
      });
      // if (this._mode == 'View') {
      //   this.batchItemsGridOptions.columnApi.setColumnVisible("Delete", false);
      // } else {
      //   this.batchItemsGridOptions.columnApi.setColumnVisible("Delete", true);
      // }
    }
  }

  async getAssignedBatchByDODetailId(_DODetailId: number, _itemID: number, _transactionQuantity: number) {
    this.itemID = _itemID;
    this.DODetailId = _DODetailId;
    this.transactionQuantity = _transactionQuantity;
    let data: any = await this.http.getserviceawait(environment.salesApiUrl + `/Delivery/GetAssignDeliveryBatchitems?DeliveryDetailId=${_DODetailId}&ItemId=${_itemID}`);
    if (data.isSuccess && data.responseData != null) {
      this.batchMasterData = [];
      this.batchItemDetails = data.responseData;
      this.assignedQty = this.batchItemDetails.assignedQuantity;
      this.unassignedQty = this.batchItemDetails.unassignedQuantity;
      if (!isNaN(_transactionQuantity)) {
        this._requiredQuantity = _transactionQuantity;
      }
      data.responseData.batchMasterDetails.forEach(ele => {
        let rowFeild = {
          batchID: ele.batchID,
          batchNo: ele.batchNo,
          description: ele.description,
          manufacturedDate: ele.manufacturedDate == null ? "" : ele.manufacturedDate,
          expiryDate: ele.expiryDate == null ? "" : ele.expiryDate,
          // lastSaleDate: ele.lastSaleDate == null ? "" : ele.lastSaleDate,
          quantity: ele.quantity,
          availableQty: ele.availableQty,
          isTemporary: 0,
          warehouseID: ele.warehouseID
        }
        this.batchMasterData.push(rowFeild);
        this.batchItemsGridOptions.api.addItems([rowFeild]);
      });
      // if (this._mode == 'View') {
      //   this.batchItemsGridOptions.columnApi.setColumnVisible("Delete", false);
      // } else {
      //   this.batchItemsGridOptions.columnApi.setColumnVisible("Delete", true);
      // }
    }

  }


  onUIValidate(): boolean {
    const errorMessage = [];
    const totalQuantity = this.batchMasterData.map((ele) => Number(ele.quantity)).reduce((a, b) => a + b, 0);
    const validRowCount = this.batchMasterData.filter(e => e.batchID > 0 || Number(e.quantity) > 0 || e.warehouseID > 0).length;
    this.batchMasterData.filter(col => (col.warehouseID > 0 || col.batchID > 0 || Number(col.quantity) > 0)).forEach((ele, index) => {
      index++;
      if (ele.batchNo == "") {
        errorMessage.push({ message: 'Row Number ' + (index) + ' : Batch No should not be empty' });
      } if (Number(ele.quantity) == 0) {
        errorMessage.push({ message: 'Row Number ' + (index) + ' : Quantity should not be blank or zero ' });
      } if (Number(ele.availableQty) < Number(ele.quantity)) {
        errorMessage.push({ message: 'Row Number ' + (index) + ' : Batch Quantity should not exceed than available Quantity' });
      } if (this.isWarehouseEnabled == true) {
        if (Number(ele.warehouseID) == 0) {
          errorMessage.push({ message: 'Row Number ' + (index) + ' : Warehouse Name should not be empty' });
        } if (this.batchMasterData.filter(column => column.warehouseID == ele.warehouseID && column.batchID == ele.batchID).length > 1) {
          const dublicateData = this.batchMasterData.filter(column => column.warehouseID == ele.warehouseID && column.batchNo == ele.batchNo);
          let warehouseName = this.lstWarehouse.find(e => e.warehouseID == dublicateData[0].warehouseID)?.warehouseName;
          let batchNo = this.lstBatch.find(e => e.batchID == dublicateData[0].batchID)?.batchNo;
          let compareText = ' ' + warehouseName + ' & ' + batchNo + ' cannot be duplicate';
          if (errorMessage.filter(msg => msg.message.toString().split(':')[1] == compareText).length == 0)
            errorMessage.push({ message: 'Row Number ' + (index) + ' : ' + warehouseName + ' & ' + batchNo + ' cannot be duplicate' });
          // errorMessage.push({ message: 'Row Number ' + (index) + ' : Warehouse & Batch No cannot be duplicate' });
        }
      } else {
        if (this.batchMasterData.filter(column => column.batchID == ele.batchID).length > 1) {
          errorMessage.push({ message: 'Row Number ' + (index) + ' : Batch No. cannot be duplicated' });
        }
      }
    });

    if (totalQuantity > this._requiredQuantity) {
      errorMessage.push({ message: 'Batch quantity should not be exceed than Invoiced quantity' });
    }
    // if (validRowCount == 0) {
    //   errorMessage.push({ message: 'There are no rows to save. Please add batch with quantity' });
    // }
    if (errorMessage.length > 0) {
      this.alertMessage.errorSummaryNotifierWithAutoClose(errorMessage, 0);
      return false;
    }
    return true;
  }

  async getBatchList(_itemID: number, _transDetailId: number): Promise<any> {
    let returnValue: any[];
    let data: any = await this.http.getserviceawait(environment.inventoryApiUrl + `/Inventory/GetBatchList?ItemId=${_itemID}&transDetailId=${_transDetailId}`);
    if (data.isSuccess && data.responseData != null) {
      returnValue = data.responseData.map(row => ({
        batchID: row.batchID,
        batchNo: row.batchNo,
        availableQty: row.quantity,
        manufacturedDate: row.manufacturedDate,
        description: row.description,
        expiryDate: row.expiryDate,
        warehouseID: row.warehouseID,
        isLocked: row.isLocked
      }));
    }
    return returnValue;
  }

  setNameCellEditorValues_Batch() {
    let colDefs: any = this.batchItemsGridOptions.api.getColumnDefs();
    let batches = this.getSelectableBatches();
    colDefs[1].cellEditorParams.lstBatch = batches;
    colDefs[1].cellEditorParams.allBatchs = this.lstBatch;
    this.batchItemsGridOptions.api.setColumnDefs(colDefs);
  }

  setNameCellEditorValues_Warehouse() {
    let colDefs: any = this.batchItemsGridOptions.api.getColumnDefs();
    let warehouses = this.getWarehousesTobeListed();
    colDefs[0].cellEditorParams.lstWarehouse = warehouses;
    this.batchItemsGridOptions.api.setColumnDefs(colDefs);
  }

  getWarehousesTobeListed() {
    let warehouses = [];
    this.lstWarehouse.forEach(e => {
      let isWarehouseHasBatch = this.getSelectableBatches().find(lb => lb.warehouseID == e.warehouseID);
      if (isWarehouseHasBatch) warehouses.push(e);
    });
    return warehouses;
  }

  lookupValue_Warehouse(warehouseList: any, params: any) {
    if (params.value == null || params.value == "" || params.value == undefined || warehouseList == null) {
      return;
    }
    else {
      let index = 0;
      if (params.column.colId == 'warehouseID') {
        index = warehouseList.findIndex(item => item.warehouseID == params.value);
        if (index != -1) {
          return warehouseList[index].warehouseName;
        } else {
          return "";
        }
      }
    }
  }


  lookupValue(batchList: any, params: any) {
    if (params.value == null || params.value == "" || params.value == undefined || batchList == null) {
      return;
    }
    else {
      let index = 0;
      if (params.column.colId == 'batchID') {
        index = batchList.findIndex(item => item.batchID == params.value);
        if (index !== -1) {
          return batchList[index].batchNo;
        } else {
          return "";
        }
      }
    }
  }

  lookupAvailableQty(batchList: any, params: any) {
    if (params?.data?.batchID) {
      let index = 0;
      if (params.column.colId == 'availableQty') {
        index = batchList.findIndex(item => item.batchID == params.data.batchID);
        if (index !== -1) {
          return batchList[index].availableQty;
        } else {
          return "";
        }
      }
    }
  }
  async onSaveUpdateInvoiceBatchDetails(): Promise<boolean> {
    let isSuccess = false;
    const batchMasterModel = [];
    if(this.assignedQty==0){
      this.tracktype='- Untracked';
    }
    else if(this.assignedQty==this._requiredQuantity){
      this.tracktype='- Fully Tracked'
    }
    else if(this._requiredQuantity>this.assignedQty){
      this.tracktype='- Partial Tracked';
    }
    if (this.batchMasterData.filter(col => col.batchID > 0 && Number(col.quantity) > 0).length > 0) {
      for (var i = 0; i < this.batchMasterData.filter(col => col.batchID > 0 && Number(col.quantity) > 0).length; i++) {
        batchMasterModel.push({
          "BatchID": this.batchMasterData[i].batchID,
          "ItemID": this.itemID,
          "Quantity": this.batchMasterData[i].quantity,
          "InvoiceDetailId": this._transactionDetailId,
          "WarehouseID": this.batchMasterData[i].warehouseID
        });
      }
      let APIResponse = await this.http.awaitPostservice(environment.salesApiUrl + '/SalesInvoice/SaveUpdateInvoiceBatchDetails', batchMasterModel);
      if (APIResponse["isSuccess"] == false) {
        let responseMessage = APIResponse["statusMessage"];
        responseMessage = JSON.parse(responseMessage);
        this.alertMessage.errorSummaryNotifierWithAutoClose(responseMessage, 0);
        isSuccess = false;
      } else {
        isSuccess = true;
        this.alertMessage.successNotifier('Save Successfully', 0);
        this.closeModal();
        this.emitOnInvoiceSumbit.emit({trans:'Invoice',track: APIResponse["statusMessage"]});
      }
    } else {
      this.onDeleteInvoiceBatchItemDetails();
    }
    return isSuccess
  }

  async onSaveUpdateDeliveryBatchDetails(): Promise<boolean> {
    let isSuccess = false;
    const batchMasterModel = [];
    if(this.assignedQty==0){
      this.tracktype=' - Untracked';
    }
    else if(this.assignedQty==this._requiredQuantity){
      this.tracktype=' - Fully Tracked'
    }
    else if(this._requiredQuantity>this.assignedQty){
      this.tracktype=' - Partial Tracked';
    }
    if (this.batchMasterData.filter(col => col.batchID > 0 && Number(col.quantity) > 0).length > 0) {
      for (var i = 0; i < this.batchMasterData.filter(col => col.batchID > 0 && Number(col.quantity) > 0).length; i++) {
        batchMasterModel.push({
          "BatchID": this.batchMasterData[i].batchID,
          "ItemID": this.itemID,
          "Quantity": this.batchMasterData[i].quantity,
          "DeliveryDetailID": this._transactionDetailId,
          "WarehouseID": this.batchMasterData[i].warehouseID
        });
      }
      let APIResponse = await this.http.awaitPostservice(environment.salesApiUrl + '/Delivery/SaveUpdateDeliveryBatchDetails', batchMasterModel);
      if (APIResponse["isSuccess"] == false) {
        let responseMessage = APIResponse["statusMessage"];
        responseMessage = JSON.parse(responseMessage);
        this.alertMessage.errorSummaryNotifierWithAutoClose(responseMessage, 0);
        isSuccess = false;
      } else {
        isSuccess = true;
        this.alertMessage.successNotifier("Saved Successfully", 0);
        this.emitOnInvoiceSumbit.emit({trans:'DO',track:this.tracktype});
      }
    } else {
      this.onDeleteDOBatchItemDetails();
    }
    return isSuccess
  }

  async onCellValueChanged(params: any) {
    const colId = params.column.getId();
    let index = params.rowIndex;
    if (colId === "batchID") {
      if (params.data.warehouseID && params.data.batchID) {
        let batchInfo = this.lstBatch.filter(item => item.batchID == params.data.batchID);
        if (batchInfo?.length == 1) {
          let availableQty = await this.onFetchAvailableBatchQuantity(this.itemID, this._transactionDetailId, params.data.batchID, params.data.warehouseID, this._transactionTypeId);
          this.batchMasterData[index].availableQty = availableQty;//batchInfo[0].availableQty;
          this.batchMasterData[index].manufacturedDate = batchInfo[0].manufacturedDate == null ? '' : batchInfo[0].manufacturedDate;
          this.batchMasterData[index].expiryDate = batchInfo[0].expiryDate == null ? '' : batchInfo[0].expiryDate;
          this.batchMasterData[index].description = batchInfo[0].description;
        } else {
          this.batchMasterData[index].availableQty = '';
          this.batchMasterData[index].manufacturedDate = '';
          this.batchMasterData[index].expiryDate = '';
          this.batchMasterData[index].description = '';
        }
      }
      params.api.refreshCells({
        force: true,
      });
    } else if (colId == 'quantity') {
      this.assignedQty = this.calculateAssingedBatchCount();
    } else if (colId === "warehouseID") {
      // let batches = this.getSelectableBatches().filter(item => item.warehouseID == params.data.warehouseID);
      // if (batches?.length > 0) {
      //   let availableQty = await this.onFetchAvailableBatchQuantity(this.itemID, this._transactionDetailId, batches[0].batchID, params.data.warehouseID, this._transactionTypeId);
      //   this.batchMasterData[index].batchID = batches[0].batchID;
      //   this.batchMasterData[index].availableQty = availableQty;// batches[0].availableQty;
      //   this.batchMasterData[index].manufacturedDate = batches[0].manufacturedDate == null ? '' : batches[0].manufacturedDate;
      //   this.batchMasterData[index].expiryDate = batches[0].expiryDate == null ? '' : batches[0].expiryDate;
      //   this.batchMasterData[index].description = batches[0].description;
      // } else {
      //   this.batchMasterData[index].batchID = '';
      //   this.batchMasterData[index].availableQty = '';
      //   this.batchMasterData[index].manufacturedDate = '';
      //   this.batchMasterData[index].expiryDate = '';
      // }
      params.api.refreshCells({
        force: true,
      });
    }
    this.setDropdownValues_Warehouses_Batches();
  }

  setDropdownValues_Warehouses_Batches() {
    this.setNameCellEditorValues_Batch();
    this.setNameCellEditorValues_Warehouse();
  }

  private getSelectableBatches() {
    let selectedBatchs: { WarehouseID: number; BatchID: number; }[] = [];
    this.batchMasterData.forEach(e => {
      selectedBatchs.push({ WarehouseID: e.warehouseID, BatchID: e.batchID });
    });
    let batches = [];
    this.lstBatch.forEach(e => {
      let isSelected = selectedBatchs.findIndex(sb => sb.WarehouseID == e.warehouseID && sb.BatchID == e.batchID);
      if (isSelected == -1) {
        batches.push(e);
      }
    });
    return batches;
  }

  toFindDuplicates(arry) {
    return [...new Set(arry.filter((elem, idx, arry) => arry.indexOf(elem) !== idx))]
  }

  onQuickFilterChanged() {
    this.batchItemsGridOptions.api.setQuickFilter(this.searchText);
    this.totalGridRows = this.batchItemsGridOptions.api.getDisplayedRowCount();
    if (this.totalGridRows == 0) {
      this.batchItemsGridOptions.api.showNoRowsOverlay();
    } else {
      this.batchItemsGridOptions.api.hideOverlay();
    }
  }

  calculateAssingedBatchCount(): number {
    return this.batchMasterData.map((ele) => Number(ele.quantity)).reduce((a, b) => a + b, 0);
  }

  async onFetchAvailableBatchQuantity(_itemID: number, _transDetailId: number, _batchID: number, _warehouseID: number, _transactionTypeId: number): Promise<number> {
    let availableBatchQty: number;
    let data: any = await this.http.getserviceawait(environment.inventoryApiUrl + `/Inventory/GetAvailableBatchQty?ItemId=${_itemID}&transDetailId=${_transDetailId}&batchID=${_batchID}&warehouseID=${_warehouseID}&transactionTypeId=${_transactionTypeId}`);
    if (data.isSuccess && data.responseData != null) {
      return availableBatchQty = data.responseData;
    }
  }


  async onSortChanged() {
    const isSortApplied = this.gridApi.getSortModel().length > 0;
    const rowElements = [];
    this.gridApi.forEachNode(ele => rowElements.push({ rowIndex: ele.rowIndex, data: ele.data }));
    rowElements.sort((a, b) => {
      return a.rowIndex - b.rowIndex
    });
    if (isSortApplied) {
      if (this._transactionTypeId == 4) {
        this.batchMasterData = rowElements.map(col => col.data).map(ele => ({
          batchNo: ele.batchID,
          description: ele.description,
          manufacturedDate: ele.manufacturedDate ?? "",
          expiryDate: ele.expiryDate ?? "",
          quantity: ele.quantity,
          IsLocked: ele.isLocked,
          warehouseID: ele.warehouseID,
          transactionTypeID: ele.transactionTypeID,
          billDetailId: ele.billDetailID,
          CNTransId: ele.cnTransId,
          isCreatedFromCN: ele.isCreatedFromCN,
          availableQty:ele.availableQty

        })) ?? [];
      } else if (this._transactionTypeId == 8) {
        this.batchMasterData = rowElements.map(col => col.data).map(ele => ({
          batchNo: ele.batchNo,
          description: ele.description,
          manufacturedDate: ele.manufacturedDate ?? "",
          expiryDate: ele.expiryDate ?? "",
          lastSaleDate: ele.lastSaleDate ?? "",
          quantity: ele.quantity,
          IsLocked: ele.isLocked,
          warehouseID: ele.warehouseID,
          invoiceDetailId: ele.invoiceDetailId,
          isCreatedFromCN: ele.isCreatedFromCN,
          availableQty:ele.availableQty
        })) ?? [];
      } else {
        this.batchMasterData = rowElements.map(col => col.data).map(ele => ({
          batchID: ele.batchID,
          batchNo: ele.batchNo,
          description: ele.description,
          manufacturedDate: ele.manufacturedDate ?? "",
          expiryDate: ele.expiryDate ?? "",
          lastSaleDate: ele.lastSaleDate ?? "",
          quantity: ele.quantity,
          IsLocked: ele.isLocked,
          warehouseID: ele.warehouseID,
          availableQty:ele.availableQty
        })) ?? [];
      }
    }
  }
  async getAssignedBatchByDebitNoteDetailId(_debitNoteDetailId: number, _itemID: number, _transactionQuantity: number) {
    this.itemID = _itemID;
    let data: any = await this.http.getserviceawait(environment.salesApiUrl + `/DebitNote/GetAssignedBatch?DebitNoteDetailId=${_debitNoteDetailId}&ItemId=${_itemID}`);
    if (data.isSuccess && data.responseData != null) {
      this.batchMasterData = [];
      this.batchItemDetails = data.responseData;
      this.assignedQty = this.batchItemDetails.assignedQuantity;
      this.unassignedQty = this.batchItemDetails.unassignedQuantity;
      if (!isNaN(_transactionQuantity)) {
        this._requiredQuantity = _transactionQuantity;
      }
      data.responseData.batchMasterDetails.forEach(ele => {
        let rowFeild = {
          batchID: ele.batchID,
          batchNo: ele.batchNo,
          description: ele.description,
          manufacturedDate: ele.manufacturedDate == null ? "" : ele.manufacturedDate,
          expiryDate: ele.expiryDate == null ? "" : ele.expiryDate,
          lastSaleDate: ele.lastSaleDate == null ? "" : ele.lastSaleDate,
          quantity: ele.quantity,
          availableQty: ele.availableQty,
          isTemporary: 0,
          warehouseID: ele.warehouseID,
          IsLocked: ele.isLocked,
          transactionTypeId:ele.transactionTypeId
        }
        this.batchMasterData.push(rowFeild);
        this.batchItemsGridOptions.api.addItems([rowFeild]);
      });
    }
  }

  async onSaveUpdateDebitNoteBatchDetails(): Promise<boolean> {
    let isSuccess = false;
    const batchMasterModel = [];
    if(this.assignedQty==0){
      this.tracktype='- Untracked';
    }
    else if(this.assignedQty==this._requiredQuantity){
      this.tracktype='- Fully Tracked'
    }
    else if(this._requiredQuantity>this.assignedQty){
      this.tracktype='- Partial Tracked';
    }
    if (this.batchMasterData.filter(col => col.batchID > 0 && Number(col.quantity) > 0 && col.transactionTypeId==30).length > 0) {
      for (var i = 0; i < this.batchMasterData.filter(col => col.batchID > 0 && Number(col.quantity) > 0).length; i++) {
        batchMasterModel.push({
          "BatchID": this.batchMasterData[i].batchID,
          "ItemID": this.itemID,
          "Quantity": this.batchMasterData[i].quantity,
          "DebitNoteDetailId": this._transactionDetailId,
          "WarehouseID": this.batchMasterData[i].warehouseID,
          "IsLocked":this.batchMasterData[i].IsLocked
        });
      }
      let APIResponse = await this.http.awaitPostservice(environment.salesApiUrl + '/DebitNote/SaveUpdateDebitNoteBatchDetails', batchMasterModel);
      if (APIResponse["isSuccess"] == false) {
        let responseMessage = APIResponse["statusMessage"];
        responseMessage = JSON.parse(responseMessage);
        this.alertMessage.errorSummaryNotifierWithAutoClose(responseMessage, 0);
        isSuccess = false;
      } else {
        isSuccess = true;
        this.alertMessage.successNotifier('Save Successfully', 0);
        this.closeModal();
        this.emitOnDebitNoteSumbit.emit({trans:'Sales Debit Note',track: APIResponse["statusMessage"],transactionDetailID:this._transactionDetailId});
      }
    } else {
      this.onDeleteDebitNoteBatchItemDetails();
    }
    return isSuccess
  }

  onDeleteDebitNoteBatchItemDetails() {
    this.http.deleteservice(environment.salesApiUrl + `/DebitNote/DeleteDebitNoteBatchitems?ItemId=${this.itemID}&DebitNoteDetailId=${this._transactionDetailId}`).subscribe({
      next: (data: any) => {
        if (data.isSuccess === true) {
          this.alertMessage.successNotifier("Saved Successfully", 0);
          this.emitOnDebitNoteSumbit.emit({trans:'Sales Debit Note',track:this.tracktype,transactionDetailID:this._transactionDetailId});
          this.closeModal();
        }
        else {
          this.alertMessage.errorNotifier(data.ResponseData, 0);
        }
      }
    });
  }

}
