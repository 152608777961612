<!-- <div class="row">
    <div class="col-md-12">
        transID: {{transID}}
        transTypeID: {{transTypeID}}
        statusTypeID: {{statusTypeID}}
        isGridEditable: {{isGridEditable}}
        contact: {{contact | json}}
        currencyCode: {{currencyCode}}
        taxTypeName: {{taxTypeName}}
        columns: {{columns | json}}
        rowData: {{rowData | json}}
    </div>
</div> -->
<div class="row">
  <div class="col-md-12">
    <table class="table table-bordered custom-table-grid-1" style="width:100%;">
      <thead>
        <tr>
          <ng-container *ngFor="let column of columns;">
            <ng-container *ngIf="column.isVisible == true">
              <th *ngIf="column.field!='discount' && column.field !='progressPercentage' && column.field !='itemAvgCost' && column.field !='IsQtyReturn' && column.field !='IsPriceAdjustment' && column.field !='return'" id="th-{{column.field}}"
                [ngStyle]="column.css ? column.css:''">
                {{column.title}}<span *ngIf="column.isMandatory" class="text-danger">*</span>
              </th>
              <th *ngIf="column.field=='discount'" id="th-{{column.field}}" [ngStyle]="column.css ? column.css:''">
                {{column.title}} <i class="fa fa-info-circle" [popover]="popTemplateHtml"
                  triggers="mouseenter:mouseleave"></i>
                <ng-template #popTemplateHtml>
                  <div>
                      <span class="help-block text-warning">
                          {{'SharedModule.Discounts can be entered as a numeric value or a percentage. Include the % symbol for a percentage discount' | translate}}.
                      </span>
                  </div>
                </ng-template>
              </th>

              <th *ngIf="column.field=='progressPercentage'" id="th-{{column.field}}"
                [ngStyle]="column.css ? column.css:''">
                {{column.title}} <i class="fa fa-info-circle" [popover]="popTemplateHtml"
                  triggers="mouseenter:mouseleave"></i>
                <ng-template #popTemplateHtml>
                    <div>
                        <span class="help-block text-warning" *ngIf="isDeposit">
                            {{'SharedModule.Deposit can be entered as a numeric value or a percentage. Include the % symbol for a Deposit Percentage.' | translate}}
                        </span>
                        <span class="help-block text-warning" *ngIf="!isDeposit">
                            {{'SharedModule.Progress can be entered as a numeric value or a percentage. Include the % symbol for a Progress Percentage.' | translate}}
                        </span>
                    </div>
                </ng-template>
              </th>
              <th *ngIf="column.field=='itemAvgCost'" id="th-{{column.field}}" [ngStyle]="column.css ? column.css:''">
                {{column.title}}
                 <!-- <i class="fa fa-info-circle" [popover]="popTemplateHtml"
                  triggers="mouseenter:mouseleave"></i>
               <ng-template #popTemplateHtml>
                  <div>
                    <span class="help-block text-warning">
                      This field will be disabled for Items which ever following FIFO costing method.
                    </span>
                  </div>
                </ng-template> -->
              </th>
              <th *ngIf="column.field == 'IsPriceAdjustment' || column.field == 'return'" id="th-{{column.field}}">
                {{column.title}}
                <i class="fa fa-info-circle" [popover]="popTemplateHtmlPA" triggers="mouseenter:mouseleave"></i>
                <ng-template #popTemplateHtmlPA>
                  <div>
                    <span class="help-block text-warning">
                      By default, Price Adjustment will be enabled in order to adjust the price of an item.
                      If you want to adjust the quantity, we are advised to create a new invoice. 
                    </span>
                  </div>
                </ng-template>
              </th>
              <th *ngIf="column.field=='IsQtyReturn'" id="th-{{column.field}}" [ngStyle]="column.css ? column.css:''">
               {{column.title}} <i class="fa fa-info-circle" [popover]="popTemplateHtml"
                  triggers="mouseenter:mouseleave"></i>
                <ng-template #popTemplateHtml>
                  <div>
                      <span class="help-block text-warning">
                          If return is ticked, entered item will be returned. If return is unticked, its not a item returned, will be a deduct the unit price.&nbsp;
                          Note : For price reduction, if entered unit price is greater than the bill or invoice unit price,it will go negative.
                      </span>
                  </div>
                </ng-template>
              </th>
            </ng-container>
          </ng-container>
        </tr>
      </thead>
      <tbody cdkDropList class="example-list" (cdkDropListDropped)="drop($event)">
        <ng-container *ngFor="let row of rowData; let parentIndex = index;">
          <tr>
            <ng-container *ngFor="let column of columns; let colInd = index">
              <ng-container *ngIf="column.isVisible == true">
                <td id="td-p{{parentIndex}}-c{{colInd}}" [ngStyle]="column.css ? column.css:''"
                  *ngIf="column.isCustom == false && column.field == 'drag'" cdkDrag class="draggable">
                  <i class="fa fa-bars" aria-hidden="true"></i>
                  <!-- <i *ngIf="rowData.length == (parentIndex+1)  && (isGridEditable == true)" class="fa fa-plus"
                                        aria-hidden="true"></i> -->
                </td>
                <!-- Non custom fields Start-->
                <td id="td-p{{parentIndex}}-c{{colInd}}" [ngStyle]="column.css ? column.css:''"
                  [ngClass]="(column.field == 'accountID' && row.isAccdisabled == true  && typeName != 'Prepayment' ) ?'disabled':''"
                  *ngIf="column.isCustom == false && column.field != 'drag'"
                  (click)="inlineEdit(parentIndex,-1,'p',column.field)">
                  <!-- itemLineNo Start-->
                  <ng-container *ngIf="(column.field == 'itemLineNo')">
                    {{row.itemLineNo}}
                  </ng-container>
                  <!-- itemLineNo End-->
                  <!-- itemID Start-->
                  <ng-container *ngIf="column.field == 'itemID'">
                    <ng-container *ngIf="column.field+parentIndex.toString() + '-1' === inlineIdx;">
                      <ng-select id="dd-p{{parentIndex}}-c{{colInd}}" class="itemID" [items]="((copyTransID!=0 && copyTransTypeID==21) || (transTypeID==8 && copyTransID!=0 && PaymentData!=undefined) || (transTypeID==4 && copyTransID!=null && transID==0) || (transTypeID==2 && copyTransID!=0 && copyTransTypeID==20))  &&  !row.isTrackedItem ? UntracklstInventoryItem:lstInventoryItem"
                        appAutofocus (keydown.Tab)="onTabIndex(parentIndex,-1,column.field);" [virtualScroll]="true"
                        [loading]="itemloading" bindLabel="itemName" [clearable]="false" bindValue="itemID" 
                        placeholder="{{'ShareModule.Select Item' | translate}}" [(ngModel)]="row[column.field]" (scroll)="onScroll($event)"
                        (scrollToEnd)="onScrollToEnd()" (focus)="OnItemFocus(row)"  
                        (change)="onCellValueChanged(row,column.field,parentIndex,-1,'p')" [searchFn]="customSearchFn" 
                        [disabled]="(transTypeID==4 && copyTransID!=null && row.IsBillCopyItem) || (copyTransID!=0 && copyTransTypeID==21 && row.isTrackedItem) || (transTypeID==8 && copyTransID!=0 && row.IsInvoiceCopyItem) || (transTypeID==2 && copyTransID!=0 && copyTransTypeID==20 && row.isTrackedItem)  ? true:false">
                        <ng-template ng-header-tmp  *ngIf="(copyTransID!=0 && copyTransTypeID==21) ? false:true">
                            <a class="t-link" (click)="addItem(parentIndex,-1)"><b>+</b>{{'SharedModule.New Item' | translate}}
                            </a>
                        </ng-template>
                        <ng-template ng-option-tmp let-item="item" let-index="index">
                            <div class="border-bottom">
                                <div class="d-flex justify-content-between align-items-start mx-2">
                                    <div>{{item.itemName }}</div>
                                    <div class="small" *ngIf="item.productCategoryName!!">
                                        {{'SharedModule.Category' | translate}}: {{item.productCategoryName }}</div>
                                </div>
                                <div class="d-flex justify-content-between align-items-start mx-2">
                                    <div class="small">{{'SharedModule.Code' | translate}}: {{item.itemCode }}</div>
                                    <div class="small text-danger">
                                        <ng-container *ngIf="item.isTrackedItem">
                                            {{'SharedModule.Stock on Hand' | translate}}: {{item.availableQuantity}}
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                      </ng-select>
                      <div class="badge badge-pill badge-success text-wrap" *ngIf="row.productCategoryName !!">
                        {{row.productCategoryName}}</div>
                    </ng-container>
                    <ng-container *ngIf="column.field+parentIndex.toString() + '-1' !== inlineIdx;">
                      {{row.itemName}} <br>
                      <div class="badge badge-pill badge-success text-wrap" *ngIf="row.productCategoryName !!">
                        {{row.productCategoryName}}</div>
                    </ng-container>
                  </ng-container>
                  <!-- itemID End-->



                  <!-- HistoricalPrice Start-->
                  <ng-container *ngIf="(column.field == 'HistoricalPrice' && !row.isGroup)">
                    <i id="btn-p{{parentIndex}}-c{{colInd}}" class="fa fa-history t-link"
                      title="Click to see item historical info" *ngIf="row.itemID > 0" aria-hidden="true"
                      (click)="viewItemHistoricalPrice(row)"></i>
                  </ng-container>
                  <!-- HistoricalPrice End-->
                  <!-- description Start-->
                  <ng-container *ngIf="column.field == 'description'">
                    <textarea id="txt-p{{parentIndex}}-c{{colInd}}"
                      *ngIf="column.field+parentIndex.toString() + '-1' === inlineIdx;" appAutofocus
                      (keydown.Tab)="onTabIndex(parentIndex,-1,column.field);"
                      (keyup)="onCellValueChanged(row,column.field,parentIndex,-1,'p')" class="form-control"
                      appTextareaAutoresize [(ngModel)]="row[column.field]"> </textarea>
                    <ng-container *ngIf="column.field+parentIndex.toString() + '-1' !== inlineIdx;">
                      <div [innerHTML]="row[column.field]"></div>
                    </ng-container>
                  </ng-container>
                  <!-- description End-->
                  <!-- HTMLDesc Start-->
                  <ng-container *ngIf="column.field == 'HTMLDesc' && !row.isGroup;">
                    <i id="btn-p{{parentIndex}}-c{{colInd}}" class="fa fa-file-text text-success"
                      *ngIf="row[column.field] != null && row[column.field] != '' && row[column.field] != undefined"
                      aria-hidden="true" (click)="addhtmlDescription(row,parentIndex,-1)"></i>
                    <i id="btn-p{{parentIndex}}-c{{colInd}}" class="fa fa-file-text"
                      *ngIf="row[column.field] == null || row[column.field] == '' || row[column.field] == undefined"
                      aria-hidden="true" (click)="addhtmlDescription(row,parentIndex,-1)"></i>
                  </ng-container>
                  <!-- HTMLDesc End-->
                  <!-- quantity Start-->
                  <ng-container *ngIf="column.field == 'quantity'">
                    <ng-container *ngIf="column.field+parentIndex.toString() + '-1' === inlineIdx;">
                      <input id="txt-p{{parentIndex}}-c{{colInd}}" type="text" class="form-control text-right" drQuantity
                        maxlength="16" onpaste="return true;" autocomplete="off" appAutofocus
                        (keydown.Tab)="onTabIndex(parentIndex,-1,column.field);"
                        (keyup)="onCellValueChanged(row,column.field,parentIndex,-1,'p')"
                        [(ngModel)]="row[column.field]" (blur)="calculate(parentIndex,-1,column.field)"/>
                      <div class="badge badge-pill badge-success" *ngIf="row.itemUnitTypeName !!">
                        {{row.itemUnitTypeName}}</div>
                    </ng-container>
                    <ng-container *ngIf="column.field+parentIndex.toString() + '-1' !== inlineIdx;">
                      <!-- {{row[column.field]}} -->
                      {{row[column.field] !=null && row[column.field] !="" ? (row[column.field] *
                      (row[column.field]<0 ? -1: 1) | number : '1.2-10' ) : "" }} <br>
                        <div class="badge badge-pill badge-success" *ngIf="row.itemUnitTypeName !!">
                          {{row.itemUnitTypeName}}</div>
                    </ng-container>
                  </ng-container>
                  <ng-container *ngIf="column.field == 'ReceivedQuantity' || column.field == 'QuantitytoReceive'">
                    <ng-container *ngIf="column.field+parentIndex.toString() + '-1' === inlineIdx;">
                      <input id="txt-p{{parentIndex}}-c{{colInd}}" type="text" onpaste="return false;"
                        autocomplete="off" class="form-control text-right" drQuantity maxlength="16" appAutofocus
                        [disabled]="column.field == 'QuantitytoReceive' && row.isGroup"
                        (keydown.Tab)="onTabIndex(parentIndex,-1,column.field);"
                        (keyup)="onCellValueChanged(row,column.field,parentIndex,-1,'p')"
                        [(ngModel)]="row[column.field]" (blur)="calculate(parentIndex,-1,column.field)"/>
                      <div class="badge badge-pill badge-success" *ngIf="row.itemUnitTypeName !!">
                        {{row.itemUnitTypeName}} </div>
                    </ng-container>
                    <ng-container *ngIf="column.field+parentIndex.toString() + '-1' !== inlineIdx;">
                      {{row[column.field]}}
                      <br>
                      <div class="badge badge-pill badge-success" *ngIf="row.itemUnitTypeName !!">
                        {{row.itemUnitTypeName}}</div>
                    </ng-container>
                  </ng-container>
                  <!-- quantity End-->
                  <!-- unitPrice Start-->
                  <ng-container *ngIf="column.field == 'unitPrice' || column.field == 'unitPriceExclusive'">
                    <input id="txt-p{{parentIndex}}-c{{colInd}}"
                      *ngIf="column.field+parentIndex.toString() + '-1' === inlineIdx;" appAutofocus
                      (keydown.Tab)="onTabIndex(parentIndex,-1,column.field);" class="form-control text-right"
                      [disabled]="row.isGroup" drUnitPrice maxlength="15" onpaste="return true;" autocomplete="off"
                      (keyup)="onCellValueChanged(row,column.field,parentIndex,-1,'p')"
                      [(ngModel)]="row[column.field]" [disabled]="transTypeID==4 && row.IsQtyReturn==true && row.isTrackedItem ==true && copyTransID!=null  ? true : false" (blur)="calculate(parentIndex,-1,column.field)"/>
                    <ng-container *ngIf="column.field+parentIndex.toString() + '-1' !== inlineIdx;">
                      {{row[column.field] < 0 ? "(" : "" }}{{row[column.field] !=null && row[column.field] !=="" ?
                        (row[column.field] * (row[column.field]<0 ? -1 : 1) | number : '1.2-4' ) : ""
                        }}{{row[column.field] < 0 ? ")" : "" }} </ng-container>
                    </ng-container>

                    <!-- unitPrice End-->
                    <!-- Item Avg cost Start-->
                    <ng-container *ngIf="column.field == 'itemAvgCost'">
                      <input id="txt-p{{parentIndex}}-c{{colInd}}"
                        *ngIf="column.field+parentIndex.toString() + '-1' === inlineIdx;" appAutofocus
                        (keydown.Tab)="onTabIndex(parentIndex,-1,column.field);" class="form-control text-right"
                        [disabled]="row.isGroup" decimal4 maxlength="15" onpaste="return false;" autocomplete="off"
                        (keyup)="onCellValueChanged(row,column.field,parentIndex,-1,'p')"
                        [(ngModel)]="row[column.field]" />
                      <ng-container *ngIf="column.field+parentIndex.toString() + '-1' !== inlineIdx;">
                        {{row[column.field] < 0 ? "(" : "" }}{{row[column.field] !=null && row[column.field] !=="" ?
                          (row[column.field] * (row[column.field]<0 ? -1 : 1) | number : '1.2-4' ) : "" }}
                          {{row[column.field] < 0 ? ")" : "" }} </ng-container>
                      </ng-container>

                      <!-- Item Avg cost End-->
                      <!-- discount Start-->
                      <ng-container *ngIf="column.field == 'discount'">
                        <input id="txt-p{{parentIndex}}-c{{colInd}}"
                          *ngIf="column.field+parentIndex.toString() + '-1' === inlineIdx;" appAutofocus
                          (keydown.Tab)="onTabIndex(parentIndex,-1,column.field);" class="form-control text-right"
                          decimal2WithPercentage [disabled]="row.isGroup" maxlength="15" onpaste="return false;"
                          autocomplete="off" (keyup)="onCellValueChanged(row,column.field,parentIndex,-1,'p')"
                          [(ngModel)]="row[column.field]" />
                        <ng-container *ngIf="column.field+parentIndex.toString() + '-1' !== inlineIdx;">
                          {{row[column.field]}}
                        </ng-container>
                      </ng-container>
                      <!-- discount End-->
                      <!-- accountID Start-->
                      <ng-container *ngIf="column.field == 'accountID'">
                        <ng-select id="dd-p{{parentIndex}}-c{{colInd}}" class="accountID"
                          *ngIf="column.field+parentIndex.toString() + '-1' === inlineIdx && !row.isGroup" appAutofocus
                          (keydown.Tab)="onTabIndex(parentIndex,-1,column.field);" [items]="lstChartAcounts"
                          placeholder="{{'SharedModule.Select Account' | translate}}" bindLabel="DisplayAccountName" bindValue="CAAccountID"
                          groupBy="AccountGroupName" [clearable]="false" [disabled]="row.isAccdisabled  && typeName != 'Prepayment'"
                          [(ngModel)]="row[column.field]"
                          (change)="onCellValueChanged(row,column.field,parentIndex,-1,'p')">
                          <ng-template ng-optgroup-tmp let-item="item">
                            {{item.AccountGroupName || 'Unnamed group'}}
                          </ng-template>
                        </ng-select>
                        <ng-container *ngIf="(column.field+parentIndex.toString() + '-1' !== inlineIdx);">
                          {{row.accountName}}
                        </ng-container>
                      </ng-container>
                      <!-- accountID End-->
                      <!-- taxRateID Start-->
                      <ng-container *ngIf="column.field == 'taxRateID'">
                        <ng-select id="dd-p{{parentIndex}}-c{{colInd}}" class="taxRateID"
                          *ngIf="column.field+parentIndex.toString() + '-1' === inlineIdx && !row.isGroup" appAutofocus
                          (keydown.Tab)="onTabIndex(parentIndex,-1,column.field);" [items]="lsttaxRate"
                          bindLabel="taxName" placeholder="{{'SharedModule.Select Tax Rate' | translate}}" bindValue="mstrTaxRateID"
                          [clearable]="false" [(ngModel)]="row[column.field]"
                          (change)="onCellValueChanged(row,column.field,parentIndex,-1,'p')">
                        </ng-select>
                        <ng-container *ngIf="column.field+parentIndex.toString() + '-1' !== inlineIdx;">
                          {{row.taxrateName}}
                        </ng-container>
                      </ng-container>
                      <!-- taxRateID End-->
                      <!-- taxAmount & amount Start
                    <ng-container
                      *ngIf="column.field == 'taxAmount' || column.field == 'amount'|| column.field == 'exclusiveAmount'">
                      {{ braceFormatter(row[column.field]) }}
                    </ng-container>
                      taxAmount & amount End-->
                      <!-- taxAmount Start-->
                      <ng-container *ngIf="column.field == 'taxAmount'">
                        <input id="txt-p{{parentIndex}}-c{{colInd}}"
                          *ngIf="column.field+parentIndex.toString() + '-1' === inlineIdx && !row.isGroup;" appAutofocus
                          onpaste="return false;" autocomplete="off" negdecimal2
                          (keydown.Tab)="onTabIndex(parentIndex,-1,column.field);onCellValueChanged(row,column.field,parentIndex,-1,'p');"
                          class="form-control text-right"
                          (focusout)="onCellValueChanged(row,column.field,parentIndex,-1,'p')"
                          [(ngModel)]="row[column.field]" />
                        <ng-container *ngIf="column.field+parentIndex.toString() + '-1' !== inlineIdx || row.isGroup;">
                          {{ braceFormatter(row[column.field]) }}
                        </ng-container>
                      </ng-container>
                      <!-- taxAmount End-->
                      <!-- amount Start-->
                      <ng-container *ngIf="column.field == 'amount'|| column.field == 'exclusiveAmount'">
                        {{ braceFormatter(row[column.field]) }}
                      </ng-container>
                      <!-- amount End-->

                      <!-- contact Start-->
                      <ng-container *ngIf="column.field == 'Contact'">
                        <ng-container *ngIf="column.field+parentIndex.toString() + '-1' !== inlineIdx;">
                          <a href="javascript:void(0);" [routerLink]="['/contacts/overview']"
                            [queryParams]="{ id: encodebtoa(row.ContactID)}">
                            <u>{{row[column.field]}}</u>
                          </a>
                        </ng-container>
                      </ng-container>
                      <!-- contact End-->
                      <!-- RefInvoiceNO Start-->
                      <ng-container *ngIf="column.field == 'RefInvoiceNO' || column.field == 'Billreferance'">
                        <ng-container *ngIf="column.field+parentIndex.toString() + '-1' !== inlineIdx;">
                          <!-- <div [innerHTML]="row[column.field]"></div> -->
                          <ng-container *ngIf="(row['MappingTransactionTypeID'] === 2)">
                            <a href="javascript:void(0);" class="t-link" [routerLink]="['/purchase/bills/viewbill']"
                              [queryParams]='{id:encodebtoa(row["MappingTransID"] +","+ 2 +","+ row["ContactID"])}'>
                              {{row[column.field]}}
                            </a>
                          </ng-container>
                          <ng-container *ngIf="(row['MappingTransactionTypeID'] === 6)">
                            <a href="javascript:void(0);" class="t-link" [routerLink]="['/sales/invoice/viewinvoice']"
                              [queryParams]='{id:encodebtoa(row["MappingTransID"] +","+ 6 +","+ row["ContactID"])}'>
                              {{row[column.field]}} 
                            </a>
                          </ng-container>
                          <ng-container *ngIf="(row['MappingTransactionTypeID'] === 30)">
                            <a href="javascript:void(0);" class="t-link" [routerLink]="['/sales/debitnote/viewdebitnote']"
                              [queryParams]='{id:encodebtoa(row["MappingTransID"] +","+ 30+","+ row["ContactID"])}'>
                              {{row[column.field]}}
                            </a>
                          </ng-container>
                          <ng-container *ngIf="(row['MappingTransactionTypeID'] === 31)">
                            <a href="javascript:void(0);" class="t-link" [routerLink]="['/purchase/debitnote/viewdebitnote']"
                              [queryParams]='{id:encodebtoa(row["MappingTransID"] +","+ 31+","+ row["ContactID"])}'>
                              {{row[column.field]}}
                            </a>
                          </ng-container>
                          <ng-container *ngIf="(row['MappingTransactionTypeID'] === 4)">
                            <a href="javascript:void(0);" class="t-link" [routerLink]="['/purchase/creditnotes/creditnoteview']"
                              [queryParams]='{id:encodebtoa(row["MappingTransID"] +","+ 4)}'>
                              {{row[column.field]}}
                            </a>
                          </ng-container>
                          <ng-container *ngIf="(row['MappingTransactionTypeID'] === 8)">
                            <a href="javascript:void(0);" class="t-link" [routerLink]="['/sales/creditnotes/creditnoteview']"
                              [queryParams]='{id:encodebtoa(row["MappingTransID"] +","+ 8 )}'>
                              {{row[column.field]}}
                            </a>
                          </ng-container>
                          <ng-container *ngIf="(row['MappingTransactionTypeID'] === 10)">
                            <a href="javascript:void(0);" class="t-link"
                              [routerLink]="['/purchase/bills/viewtransaction']"
                              [queryParams]='{account:encodebtoa(row["CAAccountID"]),tranid:encodebtoa(row["MappingTransID"]), trantypeid: encodebtoa(10)}'>
                              {{row[column.field]}}
                            </a>
                          </ng-container>
                          <ng-container *ngIf="(row['MappingTransactionTypeID'] === 11)">
                            <a href="javascript:void(0);" class="t-link"
                              [routerLink]="['/sales/invoice/viewtransaction']"
                              [queryParams]='{account:encodebtoa(row["CAAccountID"]),tranid:encodebtoa(row["MappingTransID"]), trantypeid: encodebtoa(11)}'>
                              {{row[column.field]}}
                            </a>
                          </ng-container>
                        </ng-container>
                      </ng-container>
                      <!-- RefInvoiceNO End-->
                      <!-- Date Start-->
                      <ng-container *ngIf="column.field == 'Date'">
                        <ng-container *ngIf="column.field+parentIndex.toString() + '-1' !== inlineIdx;">
                          <div [innerHTML]="row[column.field]"></div>
                        </ng-container>
                      </ng-container>
                      <!-- DueDate End-->
                      <ng-container *ngIf="column.field == 'DueDate'">
                        <ng-container *ngIf="column.field+parentIndex.toString() + '-1' !== inlineIdx;">
                          <div [innerHTML]="row[column.field]"></div>
                        </ng-container>
                      </ng-container>
                      <!-- DueDate End-->
                      <!-- Total Start-->
                      <ng-container *ngIf="column.field == 'Total'">
                        <ng-container *ngIf="column.field+parentIndex.toString() + '-1' !== inlineIdx;">
                          {{row[column.field]}}
                        </ng-container>
                      </ng-container>
                      <!-- Total End-->
                      <!-- PaymentAmount Start-->
                      <ng-container
                        *ngIf="column.field == 'PaymentAmount' || column.field == 'PaymentAmountByTransCur'">
                        <ng-container *ngIf="column.field+parentIndex.toString() + '-1' !== inlineIdx;">
                          {{row[column.field]}}
                        </ng-container>
                      </ng-container>
                      <!-- PaymentAmount End-->

                      <!-- delete Start-->
                      <ng-container *ngIf="(transTypeID==8 && copyTransID!=0)  ?  (!row.IsInvoiceCopyItem && column.field == 'delete'  && row.delete) :  (transTypeID==4 && copyTransID!=null) ? (!row.IsBillCopyItem && column.field == 'delete'  && row.delete) : (column.field == 'delete'  && row.delete)">
                        <i id="btn-p{{parentIndex}}-c{{colInd}}" *ngIf="rowData.length > 1" class="fa fa-trash"
                          aria-hidden="true" (click)="onCellValueClick(column.field,parentIndex,-1)" ></i>
                      </ng-container>
                      <!-- delete End-->

                      <!--Progress Percentage-->
                      <ng-container *ngIf="column.field == 'progressPercentage'">
                        <input id="txt-p{{parentIndex}}-c{{colInd}}" decimal2WithPercentage appAutofocus
                          *ngIf="column.field+parentIndex.toString() + '-1' === inlineIdx && !row.isGroup && !row.isDeposit"
                          (keydown.Tab)="onTabIndex(parentIndex,-1,column.field);"
                          class="form-control text-right border-box" [disabled]="row.isGroup" maxlength="15"
                          onpaste="return false;" autocomplete="off"
                          (keyup)="onCellValueChanged(row,column.field,parentIndex,-1,'p')"
                          [(ngModel)]="row[column.field]" />

                        <ng-container *ngIf="column.field+parentIndex.toString() + '-1' !== inlineIdx ">

                          <span *ngIf="isPercentageIncluded(row[column.field])">
                            {{ row[column.field] }}
                          </span>
                          <span *ngIf="!isPercentageIncluded(row[column.field])">
                            {{ braceFormatter(row[column.field],'0.00')}}
                          </span>
                        </ng-container>

                      </ng-container>



                      <!--Advance Inventory Tracking-->
                      <ng-container *ngIf="column.field == 'advanceInventory'">
                        <i *ngIf="row[column.field]==true && IsIncludedTransaction()" class="fa fa-hand-pointer-o"
                          title="Click here to Manage Tracking" aria-hidden="true"
                          (click)="InventoryTrackingClick(row)"> </i>
                      </ng-container>
                      <!--Advance Inventory Tracking-->

                      <ng-container *ngIf="column.type == 'checkbox' && column.field == 'IsPriceAdjustment'" >
                        <input type="checkbox" id="td-p{{parentIndex}}-c{{colInd}}" style="width: 20px;height: 20px;" [disabled]="(this.isGridEditable==true && row.isTrackedItem == true && this.statusTypeID!=4)  ? false : true"
                        [(ngModel)]="row[column.field]" (change)="onCellValueChanged(row,column.field,parentIndex,-1,'p')" checked>
                      </ng-container>

                      <ng-container *ngIf="column.type == 'checkbox' && (column.field == 'return')" >
                        <input type="checkbox" id="td-p{{parentIndex}}-c{{colInd}}" style="width: 20px;height: 20px;" [disabled]="true">
                      </ng-container>
                       
                      <ng-container *ngIf="column.type == 'checkbox' && column.field == 'IsQtyReturn'" >
                        <input type="checkbox" [disabled]="(this.isGridEditable==true && row.isTrackedItem == true && this.statusTypeID!=4)  ? false : true"  [(ngModel)]="row[column.field]"
                         (change)="onCellValueChanged(row,column.field,parentIndex,-1,'p')" id="td-p{{parentIndex}}-c{{colInd}}" style="width: 100px;height: 20px;" checked>
                      </ng-container>
                </td>
                <!-- Non custom fields End-->
                <!-- Custom fields Start-->
                <td id="td-p{{parentIndex}}-c{{colInd}}" [ngStyle]="column.css ? column.css:''"
                  *ngIf="column.isCustom == true && column.field != 'drag'"
                  (click)="inlineEdit(parentIndex,-1,'p',column.field)">
                  <ng-container *ngIf="column.type == 'text'">
                    <input id="txt-p{{parentIndex}}-c{{colInd}}"
                      *ngIf="column.field+parentIndex.toString() + '-1' === inlineIdx && !row.isGroup;" appAutofocus
                      (keydown.Tab)="onTabIndex(parentIndex,-1,column.field);" class="form-control" maxlength="50"
                      [(ngModel)]="row[column.field]" />
                    <ng-container *ngIf="column.field+parentIndex.toString() + '-1' !== inlineIdx && !row.isGroup;">
                      {{row[column.field]}}
                    </ng-container>
                  </ng-container>
                  <ng-container *ngIf="column.type == 'list'">
                    <ng-select id="dd-p{{parentIndex}}-c{{colInd}}" class="CustomField"
                      *ngIf="column.field+parentIndex.toString() + '-1' === inlineIdx && !row.isGroup" appAutofocus
                      (keydown.Tab)="onTabIndex(parentIndex,-1,column.field);" [items]="column.lstValues"
                      bindLabel="CustomValueText" bindValue="CustomValueID" [(ngModel)]="row[column.field]"
                      (change)="onSetText(column,row[column.field],parentIndex,-1);">
                    </ng-select>
                    <!-- <div id="div_p{{parentIndex}}_-1"
                                            [ngStyle]="{'display': column.field+parentIndex.toString() + '-1' !== inlineIdx && !row.isGroup ? '' : 'none' }">
                                        </div> -->
                    <ng-container *ngIf="column.field+parentIndex.toString() + '-1' !== inlineIdx && !row.isGroup;">
                      {{row['txt'+column.field]}}
                    </ng-container>
                  </ng-container>
                </td>
                <!-- Custom fields End-->
              </ng-container>
            </ng-container>
          </tr>
          <ng-container *ngFor="let childrow of row.lstChild; let childindex = index; ">
            <tr style="background-color: #f4f4f4;">
              <ng-container *ngFor="let column of columns; let colInd = index">
                <ng-container *ngIf="column.isVisible == true">
                  <td id="td-p{{parentIndex}}-ch{{childindex}}-c{{colInd}}" [ngStyle]="column.css ? column.css:''"
                    *ngIf="column.isCustom == false && column.field == 'drag'">
                  </td>
                  <!-- Non custom fields Start-->
                  <td id="td-p{{parentIndex}}-ch{{childindex}}-c{{colInd}}" [ngStyle]="column.css ? column.css:''"
                    [ngClass]="(column.field == 'accountID' && childrow.isAccdisabled == true  && typeName != 'Prepayment') ?'disabled':''"
                    *ngIf="column.isCustom == false && column.field != 'drag'"
                    (click)="inlineEdit(parentIndex,childindex,'c',column.field)">
                    <!-- itemLineNo Start-->
                    <ng-container *ngIf="(column.field == 'itemLineNo')">
                      {{childrow.itemLineNo}}
                    </ng-container>
                    <!-- itemLineNo End-->
                    <!-- itemID Start-->
                    <ng-container *ngIf="column.field == 'itemID'">
                      <i *ngIf="row.lstChild.length == (childindex+1) &&
                                            (column.field+parentIndex.toString() + childindex.toString() !== inlineIdx) &&
                                            (isGridEditable == true) && transTypeID != 20 && !isOnlyGridCustomCellEdit && isAddCnDnFromInvoiceBill"
                        class="fa fa-plus" aria-hidden="true"></i>
                        <ng-container *ngIf="column.field+parentIndex.toString() + childindex.toString() === inlineIdx;">
                          <ng-select id="dd-p{{parentIndex}}-ch{{childindex}}-c{{colInd}}" class="itemID"
                            [items]="((copyTransID!=0 && copyTransTypeID==21) || (transTypeID==8 && copyTransID!=0 && PaymentData!=undefined) || (transID==0 && transTypeID==4 && copyTransID!=null) || (transTypeID==2 && copyTransID!=0 && copyTransTypeID==20)) &&  !childrow.isTrackedItem ? UntracklstInventoryItem:lstInventoryItem" appAutofocus
                            (keydown.Tab)="onTabIndex(parentIndex,childindex,column.field);" [virtualScroll]="true"
                            [loading]="itemloading" bindLabel="itemName" [clearable]="false" bindValue="itemID"
                            placeholder="{{'ShareModule.Select Item' | translate}}" [(ngModel)]="childrow[column.field]" (scroll)="onScroll($event)"
                            (scrollToEnd)="onScrollToEnd()" [disabled]="(copyTransID!=0 && copyTransTypeID==21 &&  childrow.isTrackedItem) || (transTypeID==8 && copyTransID!=0 && childrow.IsInvoiceCopyItem) || (transTypeID==4 && copyTransID!=null && childrow.IsBillCopyItem) || (transTypeID==2 && copyTransID!=0 && copyTransTypeID==20 && childrow.isTrackedItem)"
                            (change)="onCellValueChanged(row,column.field,parentIndex,childindex,'c')"
                            [searchFn]="customSearchFn">
                            <ng-template ng-header-tmp *ngIf="(copyTransID!=0 && copyTransTypeID==21) ? false:true">
                                <a class="t-link" (click)="addItem(parentIndex,childindex)"><b>+</b>
                                {{'SharedModule.New Item' | translate}}
                                </a>
                            </ng-template>
                          <ng-template ng-option-tmp let-item="item" let-index="index">
                            <div class="border-bottom">
                              <div class="d-flex justify-content-between align-items-start mx-2">
                                  <div>{{'Common.Name' | translate}}: {{item.itemName }}</div>
                                  <div class="small">
                                      {{'SharedModule.Category' | translate}}:
                                      {{item.productCategoryName }}
                                  </div>
                              </div>
                              <div class="d-flex justify-content-between align-items-start mx-2">
                                  <div class="small">{{'SharedModule.Code' | translate}}: {{item.itemCode }}</div>
                                <div class="small text-danger">
                                    <ng-container *ngIf="item.isTrackedItem"> {{'SharedModule.Stock on Hand' | translate}} : {{item.availableQuantity}}
                                    </ng-container>
                                </div>
                              </div>
                            </div>
                          </ng-template>
                        </ng-select>
                        <div class="badge badge-pill badge-success text-wrap" *ngIf="childrow.productCategoryName !!">
                          {{childrow.productCategoryName}}</div>
                      </ng-container>
                      <ng-container *ngIf="column.field+parentIndex.toString() + childindex.toString() !== inlineIdx;">
                        {{childrow.itemName}}
                        <br>
                        <div class="badge badge-pill badge-success text-wrap" *ngIf="childrow.productCategoryName !!">
                          {{childrow.productCategoryName}}</div>
                      </ng-container>
                    </ng-container>
                    <!-- itemID End-->

                    <!--CHILD ROW : Advance Inventory Tracking-->
                    <ng-container *ngIf="column.field == 'advanceInventory'">
                      <i *ngIf="childrow[column.field]==true && IsIncludedTransaction()" class="fa fa-hand-pointer-o" title="Click here to Manage Tracking"
                        aria-hidden="true" (click)="InventoryTrackingClick(childrow)"> </i>
                    </ng-container>
                    <!--CHILD ROW : Advance Inventory Tracking-->

                    <!-- HistoricalPrice Start-->
                    <ng-container *ngIf="(column.field == 'HistoricalPrice')">
                      <i id="btn-p{{parentIndex}}-ch{{childindex}}-c{{colInd}}" class="fa fa-history t-link"
                        title="Click to see item historical info" *ngIf="childrow.itemID > 0" aria-hidden="true"
                        (click)="viewItemHistoricalPrice(childrow)"></i>
                    </ng-container>
                    <!-- HistoricalPrice End-->
                    <!-- description Start-->
                    <ng-container *ngIf="column.field == 'description'">
                      <textarea id="txt-p{{parentIndex}}-ch{{childindex}}-c{{colInd}}"
                        *ngIf="column.field+parentIndex.toString() + childindex.toString() === inlineIdx;" appAutofocus
                        (keydown.Tab)="onTabIndex(parentIndex,childindex,column.field);" 
                        (keyup)="onCellValueChanged(row,column.field,parentIndex,childindex,'c')" class="form-control"
                        appTextareaAutoresize [(ngModel)]="childrow[column.field]"></textarea>
                      <ng-container *ngIf="column.field+parentIndex.toString() + childindex.toString() !== inlineIdx;">
                        <div [innerHTML]="childrow[column.field]"></div>
                      </ng-container>
                    </ng-container>
                    <!-- description End-->
                    <!-- HTMLDesc Start-->
                    <ng-container *ngIf="column.field == 'HTMLDesc'">
                      <i id="btn-p{{parentIndex}}-ch{{childindex}}-c{{colInd}}" class="fa fa-file-text text-success"
                        *ngIf="childrow[column.field] != null && childrow[column.field] != '' && childrow[column.field] != undefined"
                        aria-hidden="true" (click)="addhtmlDescription(childrow,parentIndex,childindex)"></i>
                      <i id="btn-p{{parentIndex}}-ch{{childindex}}-c{{colInd}}" class="fa fa-file-text"
                        *ngIf="childrow[column.field] == null || childrow[column.field] == '' || childrow[column.field] == undefined"
                        aria-hidden="true" (click)="addhtmlDescription(childrow,parentIndex,childindex)"></i>
                    </ng-container>
                    <!-- HTMLDesc End-->
                    <!-- quantity End-->
                    <ng-container *ngIf="column.field == 'quantity'">
                      <ng-container *ngIf="column.field+parentIndex.toString() + childindex.toString() === inlineIdx;">
                        <input id="txt-p{{parentIndex}}-ch{{childindex}}-c{{colInd}}" type="text"
                          class="form-control text-right" drQuantity appAutofocus onpaste="return false;"
                          autocomplete="off" (keydown.Tab)="onTabIndex(parentIndex,childindex,column.field);"
                          maxlength="15" (keyup)="onCellValueChanged(row,column.field,parentIndex,childindex,'c')"
                          [(ngModel)]="childrow[column.field]" (blur)="calculate(parentIndex,childindex,column.field)"/>
                        <div class="badge badge-pill badge-success" *ngIf="childrow.itemUnitTypeName !!">
                          {{childrow.itemUnitTypeName}}</div>
                      </ng-container>
                      <ng-container *ngIf="column.field+parentIndex.toString() + childindex.toString() !== inlineIdx;">
                        {{childrow[column.field]}}
                        <br>
                        <div class="badge badge-pill badge-success" *ngIf="childrow.itemUnitTypeName !!">
                          {{childrow.itemUnitTypeName}}</div>
                      </ng-container>
                    </ng-container>
                    <ng-container *ngIf="column.field == 'ReceivedQuantity' || column.field == 'QuantitytoReceive'">
                      <ng-container *ngIf="column.field+parentIndex.toString() + childindex.toString() === inlineIdx;">
                        <input onpaste="return false;" autocomplete="off"
                          id="txt-p{{parentIndex}}-ch{{childindex}}-c{{colInd}}" type="text"
                          class="form-control text-right" drQuantity appAutofocus
                          (keydown.Tab)="onTabIndex(parentIndex,childindex,column.field);" maxlength="15"
                          (keyup)="onCellValueChanged(row,column.field,parentIndex,childindex,'c')"
                          [(ngModel)]="childrow[column.field]" (blur)="calculate(parentIndex,childindex,column.field)"/>
                        <div class="badge badge-pill badge-success" *ngIf="childrow.itemUnitTypeName !!">
                          {{childrow.itemUnitTypeName}}</div>
                      </ng-container>
                      <ng-container *ngIf="column.field+parentIndex.toString() + childindex.toString() !== inlineIdx;">
                        {{childrow[column.field]}}
                        <br>
                        <div class="badge badge-pill badge-success" *ngIf="childrow.itemUnitTypeName !!">
                          {{childrow.itemUnitTypeName}}</div>
                      </ng-container>
                    </ng-container>
                    <!-- quantity End-->
                    <!-- unitPrice Start-->
                    <ng-container *ngIf="column.field == 'unitPrice' || column.field == 'unitPriceExclusive'">
                      <input id="txt-p{{parentIndex}}-ch{{childindex}}-c{{colInd}}"
                        *ngIf="column.field+parentIndex.toString() + childindex.toString() === inlineIdx;" appAutofocus
                        drUnitPrice (keydown.Tab)="onTabIndex(parentIndex,childindex,column.field);" type="text"
                        class="form-control text-right" maxlength="15" onpaste="return true;" autocomplete="off"
                        (keyup)="onCellValueChanged(row,column.field,parentIndex,childindex,'c')"
                        [(ngModel)]="childrow[column.field]" (blur)="calculate(parentIndex,childindex,column.field)"/>
                      <ng-container *ngIf="column.field+parentIndex.toString() + childindex.toString() !== inlineIdx;">
                        <!-- {{ braceFormatter(childrow[column.field]) }} -->
                        {{ childrow[column.field] < 0 ? "(" : "" }}{{childrow[column.field] !=null &&
                          childrow[column.field] !=="" ? (childrow[column.field] * (childrow[column.field] < 0 ? -1 : 1)
                          | number : '1.2-4' ) : "" }}{{childrow[column.field] < 0 ? ")" : "" }} </ng-container>
                      </ng-container>
                      <!-- unitPrice End-->
                      <!-- discount Start-->
                      <ng-container *ngIf="column.field == 'discount'">
                        <input id="txt-p{{parentIndex}}-ch{{childindex}}-c{{colInd}}"
                          *ngIf="column.field+parentIndex.toString() + childindex.toString() === inlineIdx;"
                          appAutofocus (keydown.Tab)="onTabIndex(parentIndex,childindex,column.field);" type="text"
                          class="form-control text-right" decimal2WithPercentage maxlength="15" onpaste="return false;"
                          autocomplete="off" (keyup)="onCellValueChanged(row,column.field,parentIndex,childindex,'c')"
                          [(ngModel)]="childrow[column.field]" />
                        <ng-container
                          *ngIf="column.field+parentIndex.toString() + childindex.toString() !== inlineIdx;">
                          {{childrow[column.field]}}
                        </ng-container>
                      </ng-container>
                      <!-- discount End-->
                      <!-- accountID Start-->
                      <ng-container *ngIf="column.field == 'accountID'">
                        <ng-select id="dd-p{{parentIndex}}-ch{{childindex}}-c{{colInd}}" class="accountID"
                          *ngIf="column.field+parentIndex.toString() + childindex.toString() === inlineIdx;"
                          appAutofocus (keydown.Tab)="onTabIndex(parentIndex,childindex,column.field);"
                          [items]="lstChartAcounts" placeholder="{{'SharedModule.Select Account' | translate}}" bindLabel="DisplayAccountName"
                          bindValue="CAAccountID" groupBy="AccountGroupName" [clearable]="false"
                          [disabled]="childrow.isAccdisabled  && typeName != 'Prepayment'" [(ngModel)]="childrow[column.field]"
                          (change)="onCellValueChanged(row,column.field,parentIndex,childindex,'c')">
                          <ng-template ng-optgroup-tmp let-item="item">
                            {{item.AccountGroupName || 'Unnamed group'}}
                          </ng-template>
                        </ng-select>
                        <ng-container
                          *ngIf="(column.field+parentIndex.toString() + childindex.toString() !== inlineIdx);">
                          {{childrow.accountName}}
                        </ng-container>
                      </ng-container>
                      <!-- accountID End-->
                      <!-- taxRateID Start-->
                      <ng-container *ngIf="column.field == 'taxRateID'">
                        <ng-select id="dd-p{{parentIndex}}-ch{{childindex}}-c{{colInd}}" class="taxRateID"
                          *ngIf="column.field+parentIndex.toString() + childindex.toString() === inlineIdx;"
                          appAutofocus (keydown.Tab)="onTabIndex(parentIndex,childindex,column.field);"
                          [items]="lsttaxRate" bindLabel="taxName" placeholder="{{'SharedModule.Select Tax Rate' | translate}}"
                          bindValue="mstrTaxRateID" [clearable]="false" [(ngModel)]="childrow[column.field]"
                          (change)="onCellValueChanged(row,column.field,parentIndex,childindex,'c')">
                        </ng-select>
                        <ng-container
                          *ngIf="column.field+parentIndex.toString() + childindex.toString() !== inlineIdx;">
                          {{childrow.taxrateName}}
                        </ng-container>
                      </ng-container>
                      <!-- taxRateID End-->
                      <!-- taxAmount & amount Start
                      <ng-container
                        *ngIf="environment.name == 'GroupTest' && (column.field == 'taxAmount' || column.field == 'amount' || column.field == 'exclusiveAmount')">
                        {{ braceFormatter(childrow[column.field]) }}
                      </ng-container>
                      taxAmount & amount End-->
                      <!-- taxAmount Start-->
                      <ng-container *ngIf="column.field == 'taxAmount'">
                        <input id="txt-p{{parentIndex}}-ch{{childindex}}-c{{colInd}}"
                          *ngIf="column.field+parentIndex.toString() + childindex.toString() === inlineIdx;"
                          appAutofocus
                          (keydown.Tab)="onTabIndex(parentIndex,childindex,column.field);onCellValueChanged(row,column.field,parentIndex,childindex,'c');"
                          type="text" class="form-control text-right" negdecimal2 maxlength="15" onpaste="return false;"
                          autocomplete="off"
                          (focusout)="onCellValueChanged(row,column.field,parentIndex,childindex,'c')"
                          [(ngModel)]="childrow[column.field]" />
                        <ng-container
                          *ngIf="column.field+parentIndex.toString() + childindex.toString() !== inlineIdx;">
                          {{ braceFormatter(childrow[column.field]) }}
                        </ng-container>
                      </ng-container>
                      <!-- taxAmount End-->
                      <!-- amount Start-->
                      <ng-container *ngIf="(column.field == 'amount' || column.field == 'exclusiveAmount')">
                        {{ braceFormatter(childrow[column.field]) }}
                      </ng-container>
                      <!-- amount End-->
                      <!-- delete Start-->
                      <ng-container *ngIf="transTypeID==8 && copyTransID!=0 ?  (!childrow.IsInvoiceCopyItem && column.field == 'delete'  && row.delete) : (transTypeID==4 && copyTransID!=null) ? (!childrow.IsBillCopyItem && column.field == 'delete'  && row.delete) : (column.field == 'delete' && row.delete)">
                        <i id="btn-p{{parentIndex}}-ch{{childindex}}-c{{colInd}}"
                          *ngIf="(row.lstChild.length - 1) != childindex" class="fa fa-trash" aria-hidden="true"
                          (click)="onCellValueClick(column.field,parentIndex,childindex)"></i>
                      </ng-container>
                      <!-- delete End-->
                  </td>
                  <!-- Non custom fields End-->
                  <!-- Custom fields Start-->
                  <td id="td-p{{parentIndex}}-ch{{childindex}}-c{{colInd}}" [ngStyle]="column.css ? column.css:''"
                    *ngIf="column.isCustom == true && column.field != 'drag'"
                    (click)="inlineEdit(parentIndex,childindex,'c',column.field)">
                    <ng-container *ngIf="column.type == 'text'">
                      <input id="txt-p{{parentIndex}}-ch{{childindex}}-c{{colInd}}"
                        *ngIf="column.field+parentIndex.toString() + childindex.toString() === inlineIdx;" type="text"
                        class="form-control" maxlength="50" appAutofocus
                        (keydown.Tab)="onTabIndex(parentIndex,childindex,column.field);"
                        [(ngModel)]="childrow[column.field]" />
                      <ng-container *ngIf="column.field+parentIndex.toString() + childindex.toString() !== inlineIdx;">
                        {{childrow[column.field]}}
                      </ng-container>
                    </ng-container>
                    <ng-container *ngIf="column.type == 'list'">
                      <ng-select id="dd-p{{parentIndex}}-ch{{childindex}}-c{{colInd}}" class="CustomField"
                        *ngIf="column.field+parentIndex.toString() + childindex.toString() === inlineIdx;"
                        [items]="column.lstValues" bindLabel="CustomValueText" bindValue="CustomValueID" appAutofocus
                        (keydown.Tab)="onTabIndex(parentIndex,childindex,column.field);"
                        [(ngModel)]="childrow[column.field]"
                        (change)="onSetText(column,childrow[column.field],parentIndex,childindex);">
                      </ng-select>
                      <!-- <div id="div_p{{parentIndex}}_ch{{childindex}}"
                                                [ngStyle]="{'display': column.field+parentIndex.toString() + childindex.toString() !== inlineIdx ? '' : 'none' }">
                                            </div> -->
                      <ng-container *ngIf="column.field+parentIndex.toString() + childindex.toString() !== inlineIdx;">
                        {{childrow['txt'+ column.field]}}
                      </ng-container>
                    </ng-container>
                  </td>
                  <!-- Custom fields End-->
                </ng-container>
              </ng-container>
            </tr>
          </ng-container>
        </ng-container>
      </tbody>
    </table>
  </div>
</div>
<div class="px-3">
  <div class="row d-flex justify-content-between">
    <div class="col-md-4 pr-0" *ngIf="transTypeID != 20">
                   <div
                *ngIf="total > 0 && !!remainingAllocatedDepositWithTax && remainingAllocatedDepositWithTax > 0 && !isDeposit && ((statusTypeID < 4 ) || !statusTypeID ) && !isPartiallypaid">
                       <div *ngIf="remainingAllocatedDepositWithTax > 0.04">
                           <a href="javascript:void(0);" class="t-link p-0 m-0" (click)="OnallocateDepositClick(deposit)">
                               {{'SharedModule.Allocate Deposit Amount' | translate}}   ({{currencyCode}} {{braceFormatter(remainingAllocatedDepositWithTax,'0.00')}}) </a>
                           <div class="text-sm text-grey">
                               ({{'SharedModule.Include of tax, if any' | translate}})
                           </div>
                       </div>
            </div>
      <div class="btn-group" dropdown 
        *ngIf='isGridEditable && typeName != "Overpayment" && typeName !="PInvoice" && !isOnlyGridCustomCellEdit && isAddCnDnFromInvoiceBill'>
          <button id="btnAddNewLine" *userCanEdit="[true]" (click)="addTable(1,0,'p')" class="btn btn-info btn-normal"
                  type="button">
              <span class="c-icon material-icons-outlined text-warning m-0 mr-1"> add </span>
              {{'Common.Add New Lines' | translate}}
          </button>
        <button id="btnAddNewLine-1" *userCanEdit="[true]" type="button" dropdownToggle
          class="btn btn-info dropdown-toggle dropdown-toggle-split">
          <span class="caret"></span>
          <span class="sr-only">Split button!</span>
        </button>
        <ul *dropdownMenu class="dropdown-menu" role="menu">
          <span *ngFor="let n of addNumberOfRows">
            <li role="menuitem">
                <a id="btnAddNewLine-{{n}}" *userCanEdit="[true]" class="dropdown-item" (click)="addTable(n,0,'p')">
                    {{'Common.Add' | translate}} {{ n }}
                </a>
            </li>
            <li class="divider dropdown-divider"></li>
          </span>
        </ul>
      </div>
    </div>
    <div class="col-md-3">

    </div>
    <div *ngIf="transTypeID == 21" class="col-md-5">
      <hr class="mt-1 mb-1">
      <div class="d-flex justify-content-between align-items-start">
        <div>
            <div class="header-h-24">{{'SharedModule.Total Quantity' | translate}}</div>
        </div>
        <div id="lblTotalQuantity" class="header-h-24 text-right">
          {{ totalQuantity | number : '1.2-10' }}
        </div>
      </div>
      <hr class="mt-1 mb-1">
    </div>
    <div *ngIf="transTypeID != 21 && transTypeID != 20" class="col-md-5">
      <div class="d-flex justify-content-between align-items-start">
        <div>
            <div class="pt-1 pb-1">
                {{'SharedModule.Sub Total' | translate}}
                <ng-container *ngIf="braceFormatter(discount) !!">
                    ({{'SharedModule.include a discount of' | translate}}
                    <b id="lblDiscount">{{ braceFormatter(discount) }} </b> )
                </ng-container>
            </div>
        </div>
        <div>
          <div id="lblSubTotal" class="pt-1 pb-1">
            {{ braceFormatter(subTotal?subTotal:0,'0.00') }}
          </div>
        </div>
      </div>
      <ng-container>
        <div *ngFor="let totaltaxamount1 of LinetaxtotalAmountsAreList">
          <accordion class="custom-accordion ac-view">
            <accordion-group #group1 [isOpen]="false" panelClass="customClass">
              <div accordion-heading class="t-link" *ngIf="totaltaxamount1.totalTaxAmount!='0'">
                <div class="d-flex justify-content-between  align-items-start">
                  <div class="">
                    <i class="pull-left fa fa-angle-down rotate-icon"
                      [ngClass]="{'fa-angle-down': group1?.isOpen, 'fa-angle-right': !group1?.isOpen}"></i>
                    <strong>
                        {{totaltaxamount1.taxType | translate}}
                        {{ IsNewViewScreen && PageMode == 'view' && taxTypeName == 'Tax Inclusive'? ' (Includes GST)' : ''}}
                    </strong>
                  </div>
                  <div>
                    <div>
                      <strong> {{ braceFormatter(totaltaxamount1.totalTaxAmount) }}</strong>
                    </div>
                  </div>
                </div>
              </div>
              <div *ngFor="let totaltaxamount of totaltaxamount1.totallinetaxAmountsList" class="sub-collapse">
                <accordion-group #group2 [isOpen]="false" panelClass="customClass">
                  <div accordion-heading class="t-link">
                    <div class="d-flex justify-content-between  align-items-start text-success">
                      <div class="">
                        <i *ngIf='totaltaxamount.taxCompoundAmountList.length>1'
                          class="pull-left fa fa-angle-down rotate-icon"
                          [ngClass]="{'fa-angle-down': group2?.isOpen, 'fa-angle-right': !group2?.isOpen}"></i>
                        {{totaltaxamount.taxType}}
                      </div>
                      <div>
                        <div class="">
                          {{ braceFormatter(totaltaxamount.linetaxAmount) }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div *ngFor="let amountsare of totaltaxamount.taxCompoundAmountList">
                    <div class="d-flex justify-content-between  align-items-start sub-collapse-item">
                      <div>
                        {{amountsare.taxType}} {{amountsare.taxRate}}%
                      </div>
                      <div>
                        <div>
                          {{ braceFormatter(amountsare.taxCompoundAmt) }}
                        </div>
                      </div>
                    </div>
                  </div>
                </accordion-group>
              </div>
            </accordion-group>
          </accordion>
        </div>
      </ng-container>
      <ng-container
        *ngIf="taxTypeName == 'Tax Exclusive' && PageMode !='view' && HidetaxAdjustment==false && transTypeID != 29 ">
        <div class="d-flex justify-content-between align-items-start">
          <div>
              <div class="pt-1 pb-1">{{'SharedModule.includes Adjustments to Tax' | translate}}</div>
          </div>
          <div>
            <div class="pt-1 pb-1">
              <input id="txtTaxAdjustment" class="form-control text-right" type="text" onpaste="return false;"
                [(ngModel)]="taxAdjustment" [readonly]="!isGridEditable" (blur)="onTaxCalculation('Adjustment')"
                autocomplete="off" placeholder="0.00" negdecimal2 maxlength="15" [disabled]="isOnlyGridCustomCellEdit">
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="taxAdjustment!==0 && PageMode =='view' && taxAdjustment != null ">
        <div class="row align-items-start">
          <div class="col-md-8">
              <div class="pt-1 pb-1"> {{'SharedModule.includes Adjustment to Tax' | translate}}</div>
          </div>
          <div class="col-md-4">
            <div id="lblTaxAdjustment" class="pt-1 pb-1 text-right">
              {{ numberFormatToFixed(taxAdjustment) }}
            </div>
          </div>
        </div>
      </ng-container>
      <hr class="mt-1 mb-1">
      <div *ngIf="!(PageMode == 'view' && IsNewViewScreen && taxTypeName == 'Tax Inclusive')" class="d-flex justify-content-between align-items-start">
        <div>
            <div class="header-h-24">{{'Common.Total' | translate}}</div>
        </div>
        <div class="header-h-24 text-right">
          <button id="btnCurrencyExchangeRate" type="button" triggers="mouseenter:mouseleave"
            [popover]="popTemplateHtml" popoverTitle="Currency Exchange Rate"
            *ngIf="(currencyTooltip != '' && currencyTooltip != null)" class="btn">
            <i class="fa fa-info-circle"></i></button>
          <ng-template #popTemplateHtml>
            <div [innerHTML]="currencyTooltip"></div>
          </ng-template>
          {{ currencyCode }}
          <span id="lblTotal">
            {{ braceFormatter(total?total:0,'0.00') }}
          </span>
        </div>
      </div>
      <hr class="mt-1 mb-1">
      <!--  <hr class="mt-1 mb-1"> -->
      <div *ngFor="let credit of CreditData; let ind = index;" class="d-flex justify-content-between align-items-start">
        <div calss="text-muted">
          <ng-container *ngIf="(credit.TransactionTypeID===4 && credit.SpendTypeID===0)">
              <a id="btnPayment-{{ind}}" href="javascript:void(0);" class="t-link"
                 [routerLink]="['/purchase/creditnotes/creditnoteview']"
                 [queryParams]="{id:encodebtoa(credit.TransactionID +','+ credit.TransactionTypeID)}">
                  {{'SharedModule.TransactionCredits.' + credit.Desc | translate}} <br />
                  {{credit.PaymentDate| date : 'dd MMM yyy'}}
              </a>
          </ng-container>
          <ng-container *ngIf="(credit.TransactionTypeID===8 && credit.ReceiveTypeID===0)">
              <a id="btnPayment-{{ind}}" href="javascript:void(0);" class="t-link"
                 [routerLink]="['/sales/creditnotes/creditnoteview']"
                 [queryParams]="{id:encodebtoa(credit.TransactionID +','+ credit.TransactionTypeID)}">
                  {{'SharedModule.TransactionCredits.' + credit.Desc | translate}} <br />
                  {{credit.PaymentDate| date : 'dd MMM yyy'}}
              </a>
          </ng-container>

          <ng-container *ngIf="(credit.TransactionTypeID === 2)">
              <a id="btnPayment-{{ind}}" href="javascript:void(0);" class="t-link"
                 [routerLink]="['/purchase/bills/viewbill']"
                 [queryParams]="{id:encodebtoa(credit.TransactionID +','+ credit.TransactionTypeID +','+ contact.contactID)}">
                  {{'SharedModule.TransactionCredits.' + credit.Desc | translate}} <br />
                  {{credit.PaymentDate| date : 'dd MMM yyy'}}
              </a>
          </ng-container>
          <ng-container *ngIf="(credit.TransactionTypeID === 6)">
              <a id="btnPayment-{{ind}}" href="javascript:void(0);" class="t-link"
                 [routerLink]="['/sales/invoice/viewinvoice']"
                 [queryParams]="{id:encodebtoa(credit.TransactionID +','+ credit.TransactionTypeID +','+ contact.contactID)}">
                  {{'SharedModule.TransactionCredits.' + credit.Desc | translate}} <br />
                  {{credit.PaymentDate| date : 'dd MMM yyy'}}
              </a>
          </ng-container>

          <ng-container *ngIf="(credit.MappingTransactionTypeID === 2)">
              <a id="btnPayment-{{ind}}" href="javascript:void(0);" class="t-link"
                 [routerLink]="['/purchase/bills/viewbill']"
                 [queryParams]="{id:encodebtoa(credit.MappingTransactionID +','+ credit.MappingTransactionTypeID +','+ contact.contactID)}">
                  {{'SharedModule.TransactionCredits.' + credit.Desc | translate}} <br />
                  {{credit.PaymentDate| date : 'dd MMM yyy'}}
              </a>
          </ng-container>
          <ng-container *ngIf="(credit.MappingTransactionTypeID === 6)">
              <a id="btnPayment-{{ind}}" href="javascript:void(0);" class="t-link"
                 [routerLink]="['/sales/invoice/viewinvoice']"
                 [queryParams]="{id:encodebtoa(credit.MappingTransactionID +','+ credit.MappingTransactionTypeID +','+ contact.contactID)}">
                  {{'SharedModule.TransactionCredits.' + credit.Desc | translate}} <br />
                  {{credit.PaymentDate| date : 'dd MMM yyy'}}
              </a>
          </ng-container>

          <ng-container *ngIf="(credit.MappingTransactionTypeID === 30)">
            <a id="btnPayment-{{ind}}" href="javascript:void(0);" class="t-link"
              [routerLink]="['/sales/debitnote/viewdebitnote']"
              [queryParams]="{id:encodebtoa(credit.MappingTransactionID +','+ credit.MappingTransactionTypeID +','+ contact.contactID)}">
              {{credit.Desc}} <br />
              {{credit.PaymentDate| date : 'dd MMM yyy'}}
            </a>
          </ng-container>

          <ng-container *ngIf="(credit.MappingTransactionTypeID === 31)">
            <a id="btnPayment-{{ind}}" href="javascript:void(0);" class="t-link"
              [routerLink]="['/purchase/debitnote/viewdebitnote']"
              [queryParams]="{id:encodebtoa(credit.MappingTransactionID +','+ credit.MappingTransactionTypeID +','+ contact.contactID)}">
              {{credit.Desc}} <br />
              {{credit.PaymentDate| date : 'dd MMM yyy'}}
            </a>
          </ng-container>

          <ng-container *ngIf="(credit.MappingTransactionTypeID===11)
                                        ||
                                        (credit.MappingTransactionTypeID===10)">
              <a id="btnPayment-{{ind}}" href="javascript:void(0);" class="t-link" (click)="clickRefund(credit)">
                  {{'SharedModule.TransactionCredits.' + credit.Desc | translate}} <br />
                  {{credit.PaymentDate| date : 'dd MMM yyy'}}
              </a>
          </ng-container>

          <ng-container
            *ngIf=" (credit.TransactionTypeID===11 && (credit.ReceiveTypeID===1 || credit.ReceiveTypeID===2 || credit.ReceiveTypeID===3))
                                ||
                                (credit.TransactionTypeID===10 && (credit.SpendTypeID===1 || credit.SpendTypeID===2 || credit.SpendTypeID===3))">
              <a id="btnPayment-{{ind}}" href="javascript:void(0);" class="t-link" (click)="clickRefund(credit)">
                  {{'SharedModule.TransactionCredits.' + credit.Desc | translate}} <br />
                  {{credit.PaymentDate| date : 'dd MMM yyy'}}
              </a>
          </ng-container>
        </div>
        <div class="header-h-20 text-right" style="margin: 8px 3px;">
          <span id="lblPayment-{{ind}}">
            {{ numberFormatToFixed(credit.PaidAmount? credit.PaidAmount:0) }}
          </span>
          <i id="btnRemovePayment-{{ind}}"
            *ngIf="((transTypeID == 4 && PCNDelete==true) || (transTypeID == 8 && SCNDelete==true) || (transTypeID == 10 && SMDelete==true )|| (transTypeID == 11 && RMDelete==true)) && PageMode == 'view'"
            class="fa fa-trash" style="font-size: 16px;color: red;cursor: pointer;" (click)="removeCredits(credit)"></i>
        </div>
      </div>
      <hr class="mt-1 mb-1">

      <ng-container
        *ngIf="((transTypeID == 4 || transTypeID == 8) && CreditData.length > 0) || (statusTypeID > 2 && PageMode =='view' && transTypeID != 1 && transTypeID != 5 && transTypeID != 29 && typeName != 'DirectPayment')">
        <div class="d-flex justify-content-between align-items-start">
            <div>
                <div *ngIf="transTypeID != 4 && transTypeID != 8" class="header-h-24">{{'Common.Amount Due' | translate}}</div>
                <div *ngIf="(transTypeID == 4 || transTypeID == 8) && (TotalCreditAmount == total)" class="header-h-24">
                    {{'SharedModule.Total Credit' | translate}}</div>
                <div *ngIf="(transTypeID == 4 || transTypeID == 8) && TotalCreditAmount > 0 && TotalCreditAmount != total"
                     class="header-h-24">
                    <a href="javascript:void(0);" class="t-link p-0 m-0" (click)="OnallocateCNClick($event)">
                        {{'SharedModule.Allocate Remaining Credit' | translate}}</a>
                </div>
                <div *ngIf="(transTypeID == 4 || transTypeID == 8) && (TotalCreditAmount == 0)" class="header-h-24">
                    {{'SharedModule.Remaining Credit' | translate}}</div>
            </div>
          <div>
            <div class="header-h-24 text-right">
              <button id="btnCurrencyExchangeRate" type="button" triggers="mouseenter:mouseleave"
            [popover]="LivepopTemplateHtml" popoverTitle="Currency Exchange Rate"
            *ngIf="(LivecurrencyTooltip != '' && LivecurrencyTooltip != null)" class="btn">
            <i class="fa fa-info-circle"></i></button>
              <ng-template #LivepopTemplateHtml>
                <div [innerHTML]="LivecurrencyTooltip"></div>
              </ng-template>
              {{currencyCode}}
              <span id="lblAmountDue">
                {{ numberFormatToFixed(AmountDue? AmountDue:0) }}
              </span>
            </div>
          </div>
        </div>
        <hr class="mt-1 mb-1">
        <hr class="mt-1 mb-1">
      </ng-container>
    </div>
  </div>
</div>
<app-additem *ngIf="isGridEditable" (toParentAfterItemSave)="returnAddItem($event)"></app-additem>
<app-addtextdesc (toParentAfterAdd)="returnTextDescription($event)"></app-addtextdesc>
<app-itemhistory></app-itemhistory>
<app-unitpricefrompricelist (toReturnUnitPrice)="returnUnitPrice($event)"></app-unitpricefrompricelist>
<app-createbatch (emitOnSubmitValue)="emitOnSubmitBatchTracking($event)"
  (emitAfterBatchorSerialSaved)="emitAfterBatchorSerialSaved($event)"></app-createbatch>
<app-assignbatch (emitOnInvoiceSumbit)="emitOnInvoiceSumbit($event)" (emitOnDebitNoteSumbit)="emitOnDebitNoteSumbit($event)"></app-assignbatch>
<app-createserial (emitOnSubmitValue)="emitOnSubmitSerialTracking($event)"
  (emitAfterBatchorSerialSaved)="emitAfterBatchorSerialSaved($event)"></app-createserial>
<app-assignserial (emitOnInvoiceSumbit)="emitOnInvoiceSumbit($event)"
  (emitOnDOSerialSumbit)="emitOnDOSerialSumbit($event)"
  (emitAfterBatchorSerialSaved)="emitAfterBatchorSerialSaved($event)" (emitOnDebitNoteSumbit)="emitOnDebitNoteSumbit($event)"></app-assignserial>
