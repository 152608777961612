<div class="w-100" style="display: flex; justify-content: center;">
    <div class="card" style="min-width: 50%; max-width:fit-content">
        <div class="row ">
            <div class="col-md-12 m-1 d-flex justify-content-center">
                <h5 class="titleheader align-content-center"><strong> {{OrgName}}</strong></h5>
            </div>
            <div class="col-md-12 m-1 d-flex justify-content-center">
                <h6 class="titleheader  align-content-center">{{reportTitle | translate}}</h6>
            </div>
            <div class="col-md-12 m-1 d-flex justify-content-center">
                <h6 class="titleheader  align-content-center">{{dateSelected}}</h6>
            </div>


        </div>

        <div id="report-container" class="overflow-auto"
             [ngClass]="{'cashFlow_Layout': reportTitle == 'Cash Flow Statement' || reportTitle == 'Profit and Loss' }">
            <mat-table *ngIf="title != 'BalanceSheet' && title != 'Profit & Loss' && totalCount != 0  && !loading  && reportTitle!='Sales Order Tracking Report' && reportTitle!='Invoice/Sales Debit Note Payment Collection Report' && reportTitle!='Bills/Purchase Debit Note Payment Collection Report'"  [dataSource]="dataSource" class="" matSort
                       [style.width]='(columns?.length * (reportTitle == "Inventory Aging Report"?150:115) + getExtralength())  + "px"' style='min-width:100%;' (matSortChange)="onSortData($event)">

                <ng-container *ngFor="let column of columns; let i = index; trackBy: trackByIndex"
                              matColumnDef="{{ column.field }}">

                    <mat-header-cell *matHeaderCellDef mat-sort-header
                                     [class]="column.align +  (column.RowLevel > 0 && (column.field == 'Heading' || column.field =='AccountName')? ' padleft-'+column.RowLevel:'')
            + ' ' + (column.title?.replaceAll(' ', '').toLowerCase() == 'accountname' ? (reportTitle == 'Balance Sheet' ? '300':'AccountNameDefaultWidth')  : '')
            + ' ' + (column.title?.replaceAll(' ', '').toLowerCase() == 'organization' ? 'OrgNameDefaultWidth' : '')
            + ' ' + (column.align == 'tr-right' ? 'sortable-column' : '')
            + ' ' + (reportTitle == 'Inventory Aging Report' && reportTitle != 'Invoice/Sales Debit Note Payment Collection Report' && reportTitle!='Bills/Purchase Debit Note Payment Collection Report' && column.title == 'Total'? 'fw-bolder':'')"
                                     [disabled]="column.sort == false">
                        <span><strong>{{ column.title | translate }}</strong></span>
                        <!--<span *ngIf="reportTitle == 'Inventory Aging Report' && column.RptType != 'dynamic'"><strong > {{ column.title | translate }} </strong></span>
                        <span *ngIf="reportTitle == 'Inventory Aging Report' && column.RptType == 'dynamic'"><strong> {{ column.title | translate }} </strong><br />Quantity<br />Purchase cost<br />Weighted Avg</span>-->
                    </mat-header-cell>
                    <!--  -->
                    <mat-cell [class]="column.align +  (row['RowLevel'] > 0 && (column.field == 'Heading' || column.field =='AccountName')? ' padleft-'+row['RowLevel']:'') 
            + ' ' + (column.title?.replaceAll(' ', '').toLowerCase() == 'accountname'  ? (reportTitle == 'Balance Sheet' ? '300':'AccountNameDefaultWidth')  : '')
            + ' ' + (column.title?.replaceAll(' ', '').toLowerCase() == 'organization' ? 'OrgNameDefaultWidth' : '')"
                              [matTooltip]="row[column.field]" [matTooltipDisabled]="!(row[column.field]?.length >= 240 && column.field != 'linkDetail')"
                              *matCellDef="let row">
                        <span *ngIf="!hyperLinkColumnName.includes(column.field)" [style.color]="!row.color?'inherit':row.color"
                              [ngClass]="{'bold': rowTypeList.includes(row.RowType?.toLowerCase()) || rowTypeList.includes(row.rowType?.toLowerCase()) ||  row?.AccountCode == 'Total' }">

                            <span *ngIf="column.isHyperLink ">
                                <a *ngIf="row.RowType == '' || row.rowType == ''" [routerLink]="navigaterouterLink(row,column)"
                                   [queryParams]="navigatequeryParams(row,column)" (click)="navigate(row,column)">
                                    {{
                  row[column.field]?.length >= 240
                  ? row[column.field]?.substring(0,240) + '...' :
                  row[column.field]
                                    }}
                                </a>

                                <span *ngIf="row.RowType != '' && row.rowType != ''">
                                    {{
 row[column.field]?.length >= 240
                  ? row[column.field]?.substring(0,240) + '...' :
                  row[column.field]
                                    }}
                                </span>
                            </span>
                            <span *ngIf="!column.isHyperLink && reportTitle != 'Inventory Aging Report'"> 
                                {{ row[column.field]?.length >= 240 ? row[column.field]?.substring(0,240) + '...' : row[column.field] }}
                            </span>
                            <span *ngIf="!column.isHyperLink && reportTitle == 'Inventory Aging Report' ">
                                <span *ngIf="column.RptType != 'dynamic'">
                                    <a [ngClass]="column.align">{{ row[column.field]?.length >= 240 ? row[column.field]?.substring(0,240) + '...' : row[column.field] }}</a>
                                </span>
                                <span *ngIf="column.RptType == 'dynamic' && column.field != 'Total'" class="pt-1 pb-1">
                                    <a [ngClass]="column.align" style="color: #6495ED">{{ row[column.field+'Quantity']}}</a>
                                    <a [ngClass]="column.align" style="color: #CD5C5C">{{ row[column.field+'TotalCost']}}</a>
                                    <a [ngClass]="column.align" style="color: #20B2AA ">{{ row[column.field+'WeightedAverage']}}</a>
                                </span>

                                <span *ngIf="column.RptType == 'dynamic' && column.field == 'Total'" class="pt-1 pb-1">
                                    <strong> <a [ngClass]="column.align" style="color: #6495ED">{{ row[column.field+'Quantity']}}</a></strong>
                                    <strong> <a [ngClass]="column.align" style="color: #CD5C5C ">{{ row[column.field+'Cost']}}</a></strong>
                                    <strong>  <a [ngClass]="column.align" style="color: #20B2AA ">{{ row[column.field+'Cost']}}</a></strong>
                                </span>

                            </span> 
                        </span>

                        <span *ngIf="hyperLinkColumnName.includes(column.field) && !rowTypeList.includes(row.rowType ? row.rowType?.toLowerCase():row.RowType?.toLowerCase() ) "
                              class="text-info" style="color:#0f9ca9 ;cursor: pointer;">

                            <a class="fa fa-eye"
                               *ngIf="(( column.field == 'linkDetailReport' ) || ( row.LinkDetail && row.LinkDetail != '') || ( row.linkDetail && row.linkDetail != '') || ( row.linkDetailReport && row.linkDetailReport != '')) || showEyeIcon(row.orgName)"
                               [queryParams]="navigatequeryParams(row,column)" [routerLink]="navigaterouterLink(row,column)" (click)="navigate(row,column)"></a>

                        </span>
                        <!-- class='fa fa-link' -->

                    </mat-cell>

                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns;sticky:true"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>

                <!-- Group header -->
                <ng-container matColumnDef="groupHeader">
                    <mat-cell colspan="999" *matCellDef="let group">
                        <div class="col-md-6 float-left">
                            <strong [style.color]="group.color">{{group[groupByColumns[group.level-1]]}}</strong>
                        </div>
                        <div class="col-md-6 text-right">
                            <strong [style.color]="group.color">{{group["info"]}}</strong>
                        </div>
                    </mat-cell>
                </ng-container>

                <mat-row *matRowDef="let row; columns: ['groupHeader']; when: isGroup;"> </mat-row>
            </mat-table>

            <ng-container *ngIf="(title == 'BalanceSheet' || title =='Profit & Loss') && !loading">
                <div  [style.width]='(columns?.length * 170 + getExtralength())  + "px"' style='min-width:100%;'>
                    <button class="btn btn-light btn-sm ml-2" type="button" (click)="expandCollapseAll(true)"> {{'ReportsModule.Expand All' | translate}}</button>
                    <button class="btn btn-light btn-sm" type="button" (click)="expandCollapseAll(false)">{{'ReportsModule.Collapse All' | translate}}</button>
                </div>
                <mat-table [dataSource]="dataSource" class="mat-elevation-z8 bspl"
                [style.width]='(columns?.length * 170 + getExtralength())  + "px"' style='min-width:100%;'>
                    <ng-container [matColumnDef]="column.field" *ngFor="let column of columns; let i = index">
                        <mat-header-cell *matHeaderCellDef [class]="column.align">
                            <span> {{column.title | translate}}   </span>
                        </mat-header-cell>
                        <mat-cell *matCellDef="let data" [class]="column.align"
                                  [ngClass]="{'AccountNameDefaultWidth300':column.field == 'AccountCode'}">
                            <button mat-icon-button *ngIf="column.field == 'AccountCode'" class="groupExpCollapse"
                                    [style.visibility]="!data.expandable ? 'hidden' : ''" [style.marginLeft.px]="data.level * 24"
                                    (click)="treeControl.toggle(data)">
                                <mat-icon class="mat-icon-rtl-mirror icon-display">
                                    {{treeControl.isExpanded(data) ? 'expand_more' : 'chevron_right'}}
                                </mat-icon>
                            </button>

                            <span *ngIf="column.isHyperLink && data.data.RowType == ''">
                                <a [routerLink]="navigaterouterLink(data.data,column)" [queryParams]="navigatequeryParams(data.data,column)"
                                   (click)="navigate(data.data,column)">
                                    {{
                              data.data[column.field]?.length >= 240
                              ? data.data[column.field]?.substring(0,240) + '...' :
                              data.data[column.field]
                                    }}
                                </a>
                            </span>
                            <span *ngIf="column.isHyperLink && data.data.RowType == 'Header'">
                                {{treeControl.isExpanded(data) ? '' : data.data[column.field]}}
                            </span>
                            <span *ngIf="!(column.isHyperLink && data.data.RowType == '' ) && data.data.RowType != 'Header' ">
                                {{data.data[column.field]}}
                            </span>
                            <span *ngIf="(!column.isHyperLink && data.data.RowType == 'Header' )">
                                {{data.data[column.field]}}
                            </span>

                        </mat-cell>
                    </ng-container>

                    <!-- <ng-container [matColumnDef]="column" *ngFor="let column of columns; let i = index">
                            <th mat-header-cell *matHeaderCellDef> {{column.title}} </th>
                            <td mat-cell *matCellDef="let data">    {{data.data[column.field]}} </td>
                          </ng-container> -->

                    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumns;"
                             [ngClass]="{'bold ': row.data?.IsTotalRow || row.data?.IsFormula || row.data?.IsNode}"></mat-row>
                </mat-table>
            </ng-container>
            <ng-container *ngIf="reportTitle=='Sales Order Tracking Report' && totalCount != 0  && !loading">
                <mat-table [dataSource]="dataSource" class="mat-elevation-z8" (matSortChange)="onsalesorderSortData($event)" matSort>
                    <ng-container [matColumnDef]="column.field" *ngFor="let column of columns">
                        <mat-header-cell mat-sort-header [class]="column.align + ' ' + (column.align == 'tr-right' ? 'sortable-column' : '')" *matHeaderCellDef>{{ column.title | translate}}</mat-header-cell>
                        <mat-cell [class]="column.align" *matCellDef="let emp">{{ emp[column.field] }}</mat-cell>
                    </ng-container>
                    <mat-header-row *matHeaderRowDef="displayedColumns;sticky:true"></mat-header-row>
                    <mat-row style="background-color: #f1f1f1;" *matRowDef="let emprow; columns: displayedColumns"></mat-row>
                    <mat-row style="background-color: white;" *matRowDef="let emprow; columns: ['childJson'];when:ischild"></mat-row>
                    <ng-container matColumnDef="childJson">
                        <mat-cell colspan="8" *matCellDef="let child">
                            <table mat-table [dataSource]="child.json" *ngIf="child?.json?.length > 0">
                                <ng-container matColumnDef="{{innerColumn.field}}" *ngFor="let innerColumn of innerColumns">
                                    <mat-header-cell *matHeaderCellDef [class]="innerColumn.align + ' ' + (innerColumn.align == 'tr-right' ? 'sortable-column' : '')"> {{innerColumn.title}} </mat-header-cell>
                                    <mat-cell *matCellDef="let element" [class]="innerColumn.align"> {{element[innerColumn.field]}} </mat-cell>
                                </ng-container>
                                <mat-header-row style="min-height: 20px;height: 30px;" *matHeaderRowDef="displayedinnercolumns;sticky:true"></mat-header-row>
                                <mat-row style="background-color: white;" *matRowDef="let rowData; columns: displayedinnercolumns"></mat-row>
                            </table>
                        </mat-cell>
                    </ng-container>
                    <!-- Group header -->
                    <ng-container matColumnDef="groupHeader">
                        <mat-cell colspan="999" *matCellDef="let group">
                            <div class="col-md-6 float-left">
                                <strong [style.color]="group.color">{{group[groupByColumns[group.level-1]]}}</strong>
                            </div>
                            <div class="col-md-6 text-right">
                                <strong [style.color]="group.color">{{group["info"]}}</strong>
                            </div>
                        </mat-cell>
                    </ng-container>

                    <mat-row style="background-color: white;" *matRowDef="let row; columns: ['groupHeader']; when: isGroup;"> </mat-row>




                </mat-table>
            </ng-container>
            <ng-container *ngIf="(reportTitle == 'Invoice/Sales Debit Note Payment Collection Report' || reportTitle=='Bills/Purchase Debit Note Payment Collection Report') && totalCount != 0 && !loading" >
                <table  mat-table [dataSource]="dataSource" class="mat-elevation-z8 paymentcol-tbl" matSort (matSortChange)="onSortData($event)" [style.width]='(columns?.length * 100 + getExtralength())  + "px"' style='min-width:100%;' >
                    <ng-container *ngFor="let column of columns; let i = index; trackBy: trackByIndex" matColumnDef="{{ column.field }}">
                        <th mat-header-cell mat-sort-header *matHeaderCellDef [class]="column.align + ' ' + (column.align == 'tr-right' ? 'sortable-column' : '')" [ngClass]="{'v-line': column.field == lastTransactioncolumn}"> <span><strong>{{column.title}}</strong></span> </th>
                        <td mat-cell *matCellDef="let row" style="text-align: center;" [ngClass]="{'v-line': column.field == lastTransactioncolumn}">
                            <span *ngIf="!column.isHyperLink && (reportTitle == 'Invoice/Sales Debit Note Payment Collection Report' || reportTitle=='Bills/Purchase Debit Note Payment Collection Report') " 
                            [ngClass]="{'bold': rowTypeList.includes(row.RowType?.toLowerCase())}">
                                <span *ngIf="column.ColumnType != 'dynamic'" class="vertical-border"  style="word-wrap: break-word;">
                                    <a [ngClass]="column.align">{{ row[column.field]?.length >= 240 ? row[column.field]?.substring(0,240) + '...' : row[column.field] }}</a>
                                </span>
                                <span *ngIf="column.ColumnType == 'dynamic'" class="pt-1 pb-1" style="word-wrap: break-word;">
                                    <ng-container *ngFor="let item of row['paymentdetails'];let i = index">
                                        <a [ngClass]="column.align" style="line-height: 1.5rem;white-space: nowrap;">
                                            {{ item[column.field] }}</a><br>
                                    </ng-container>
                                </span>
                            </span>

                            <span *ngIf="hyperLinkColumnName.includes(column.field) && !rowTypeList.includes(row.rowType ? row.rowType?.toLowerCase():row.RowType?.toLowerCase() ) "
                                  class="text-info" style="color:#0f9ca9 ;cursor: pointer;">

                                <a class="fa fa-eye"
                                   *ngIf="(( column.field == 'linkDetailReport' ) || ( row.LinkDetail && row.LinkDetail != '') || ( row.linkDetail && row.linkDetail != '') || ( row.linkDetailReport && row.linkDetailReport != '')) || showEyeIcon(row.orgName)"
                                   [queryParams]="navigatequeryParams(row,column)" [routerLink]="navigaterouterLink(row,column)" (click)="navigate(row,column)"></a>

                            </span>
                        </td>
                    </ng-container>
                      <ng-container matColumnDef="header-row-first-group">
                        <th
                          mat-header-cell
                          *matHeaderCellDef
                          style="text-align: center;"
                          [attr.colspan]="firstGroupColspan"
                          class="v-line"
                        >
                        <strong>{{reportTitle == 'Invoice/Sales Debit Note Payment Collection Report' ? 'Invoice (INV) /Debit Note (DN)' : 'Bills (B) / Debit Note (DN)'}}</strong> 
                        </th>
                      </ng-container>
                    
                      <!-- Header row second group -->
                      <ng-container matColumnDef="header-row-second-group">
                        <th mat-header-cell *matHeaderCellDef style="text-align: center;" [attr.colspan]="secondGroupColspan">
                            <strong>Payment Collection</strong> 
                        </th>
                      </ng-container>
                    
                      <tr
                        mat-header-row
                        *matHeaderRowDef="['header-row-first-group', 'header-row-second-group']"
                      ></tr>
                      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                      <ng-container matColumnDef="groupHeader">
                        <td mat-cell colspan="999" *matCellDef="let group">
                            <div class="col-md-6 float-left">
                                <strong [style.color]="group.color">{{group[groupByColumns[group.level-1]]}}</strong>
                            </div>
                            <div class="col-md-6 text-right">
                                <strong [style.color]="group.color">{{group["info"]}}</strong>
                            </div>
                        </td>
                    </ng-container>
    
                    <tr mat-row *matRowDef="let row; columns: ['groupHeader']; when: isGroup;"> </tr>
                </table>
            </ng-container>

            <div *ngIf="loading" class="d-grid gap-3">
                <div class="p-15 bg-light border" style="display: flex; justify-content: center; align-items: center;">
                    <mat-progress-spinner color="primary" mode="indeterminate">
                    </mat-progress-spinner>
                </div>
            </div>

            <div *ngIf="totalCount === 0 && !loading" class="d-grid gap-3">
                <div class="p-5 bg-light border text-center">
                    <i class="fa fa-table"></i>
                    <br> {{'ReportsModule.Nothing to show here' | translate}}
                </div>
            </div>

        </div>
    </div>
</div>
<div class="fix-footer"
     *ngIf="(isFooterRequired?.IsPaginationRequired || isFooterRequired?.IsExportRequired || isFooterRequired?.IsSaveAsDraftRequired || isFooterRequired?.IsEditLayoutRequired) && totalCount != 0">

    <div class="row">

        <div class="col-md-6 d-flex">

            <button type="button" class="btn btn-info" (click)="openEditLayout()"
                    *ngIf="isFooterRequired.IsEditLayoutRequired">
                <span>{{'ReportsModule.Edit layout' | translate}}</span>
            </button>


            <mat-paginator [pageSizeOptions]="[100, 200, 500]" showFirstLastButtons [length]="totalCount"
                           *ngIf="isFooterRequired.IsPaginationRequired" (page)="changePagination($event)"
                           aria-label="Select page of periodic elements">
            </mat-paginator>
        </div>
        <div class="col-md-6 text-right right-50">
            <div class="btn-group mr-1" dropdown *ngIf="isFooterRequired.IsExportRequired" [dropup]="true">
                <button id="btnExport" dropdownToggle type="button" class="btn btn-info dropdown-toggle">
                    <span>{{'Common.Export' | translate}}</span>
                </button>
                <ul *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu">
                    <li role="menuitem">
                        <a class="dropdown-item" id="btnExcelExport" (click)="exportExcelFile()">Excel</a>
                    </li>
                    <li role="menuitem">
                        <a class="dropdown-item" id="btnPDFExport" (click)="exportPDF()">PDF</a>
                    </li>
                </ul>
            </div>

            <div class="btn-group" dropdown *ngIf="isFooterRequired.IsSaveAsDraftRequired" [dropup]="true">
                <button id="btnSaveAs" dropdownToggle *userCanEdit=[true] type="button" class="btn btn-light dropdown-toggle">
                    <span>{{'ReportsModule.Save As' | translate}}</span>
                </button>
                <ul *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu">
                    <li role="menuitem">
                        <a id="btnSaveAsDraft" class="dropdown-item" (click)="saveAsPublish(saveAsTypeDetail.Draft)">
                            <span *ngIf="isDraft">
                                {{'Common.Update' | translate}}
                            </span>{{'Common.Draft' | translate}}
                        </a>
                    </li>
                    <li role="menuitem">
                        <a class="dropdown-item" (click)="saveAsPublish(saveAsTypeDetail.Published)">{{'Common.Publish' | translate}}</a>
                    </li>
                </ul>
            </div>

        </div>

        <div class="col-md-2"> </div>
    </div>
</div>
