<div bsModal #modalAssignBatch="bs-modal" class="modal fade" tabindex="-1" role="dialog"
  aria-labelledby="modalDisposeLabel" aria-hidden="true" [config]="{ backdrop: 'static' }">
  <div class="modal-dialog modal-xl modal-dialog-scrollable1" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title font-weight-bold">Tracking Method - {{trackingMethod}}</h4>
        <button type="button" class="close" aria-label="Close" (click)="closeModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form class="form-horizontal needs-validation">
          <div class="">
            <div class="row-eq-height">
              <div class="col track-box-success">
                <div class="bd-highlight text-center  p-1">
                  <div class="header-4 text-grey">{{batchItemDetails.itemName}}</div>
                  <div class="header-h-18 ">Item Code:{{batchItemDetails.itemCode}}</div>

                </div>
              </div>
              <div class="col track-box-warning">
                <div class="bd-highlight text-center p-1">
                  <div class="header-4 text-grey">{{_quantityLabel }} Quantity</div>
                  <div class="header-h-18">{{_requiredQuantity | number : '1.2-10'}}</div>
                </div>
              </div>
              <div class="col track-box-warning">
                <div class="bd-highlight text-center g p-1">
                  <div class="header-4 text-grey">Assigned Quantity</div>
                  <div class="header-h-18">{{assignedQty | number : '1.2-10'}}</div>
                </div>
              </div>
              <!-- <div class="col track-box-warning">
                              <div class="bd-highlight text-center g p-1">
                                <div class="header-4 text-grey">Total Quantity</div>
                                <div class="header-h-18">{{batchItemDetails.totalQuantity}}</div>
                              </div>
                              </div> -->
              <div class="col track-box-warning">
                <div class="bd-highlight text-center g p-1">
                  <div class="header-4 text-grey">Unassigned Quantity</div>
                  <div class="header-h-18">{{_requiredQuantity-assignedQty | number : '1.2-10'}}</div>
                  <!-- <div class="header-h-18">{{unAssignedQty}}</div> assignedQty -->
                </div>
              </div>
            </div>
          </div>

          <div class="row mt-4">
            <!-- <div class="col-md-5">
              <div class="ml-1">
                <button class="btn btn-primary" id="btnNewBatch" type="button" (click)="addRows(1)">
                  <span class="c-icon material-icons-outlined text-warning m-0">add</span>
                  &nbsp;Add New Line
                </button>
              </div>
            </div> -->
            <div class="col-md-12 pull-right">
              <div class="d-flex justify-content-end">
                <div class="col-md-3 pr-0">
                  <div class="form-group search">
                    <span class="material-icons-outlined icon-search">search</span>
                    <input type="text" id="search-box" class="form-control" [(ngModel)]="searchText"
                      [ngModelOptions]="{standalone: true}" (input)="onQuickFilterChanged()" placeholder="Search"
                      xssDirective autocomplete="off" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="">
            <div class="custom-ag-grid-4 mt-2 mb-0 ag-grid-modal">
              <ag-grid-angular style="width: 100%; height: 100%;" class="ag-theme-material" [modules]="modules"
                [singleClickEdit]="true" [rowData]="batchMasterData" [gridOptions]="batchItemsGridOptions"
                [columnDefs]="itemscolumnDefs" [frameworkComponents]="frameworkComponents"
                (cellValueChanged)="onCellValueChanged($event)" (cellClicked)="onCellClicked($event)" [pagination]="true"
                (sortChanged)="onSortChanged()">
              </ag-grid-angular>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer d-block" *ngIf="isEnabledActionButtons">
        <div class="row mb-3">
          <div class="col-md-5">
            <div class="ml-1">
              <button class="btn btn-primary" id="btnNewBatch" type="button" (click)="addRows(1)">
                <span class="c-icon material-icons-outlined text-warning m-0">add</span>
                &nbsp;Add New Line
              </button>
            </div>
          </div>
          <div class="col-md-7 sec-btn d-flex justify-content-end">
            <button class="btn btn-success btn-normal" *userCanEdit="[true]" (click)="onSubmit()">Save</button>
            <button class="btn btn-danger btn-normal" type="button" (click)="closeModal()">
              Cancel
            </button>
          </div>
        </div>
      </div>
      <!-- <div class="modal-footer">
        <div class="mb-3">
          <div class="col sec-btn">
            <button class="btn btn-success btn-normal" (click)="onSubmit()">Save</button>
            <button class="btn btn-danger btn-normal" type="button" (click)="closeModal()">
              Cancel
            </button>
          </div>
        </div>
      </div> -->
    </div>
  </div>
