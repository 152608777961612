import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { CdkDragDrop, moveItemInArray, transferArrayItem, CdkDrag, CdkDropList, } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-column-re-order',
  templateUrl: './column-re-order.component.html',
  styleUrls: ['./column-re-order.component.scss']
})
export class ColumnReOrderComponent implements OnInit {

  @Input() columns: any;
  @Input() reporttitle: string;
  @Output() hidemodal = new EventEmitter();
  @ViewChild('showmodal') public showModal: ModalDirective;
  transactioncolumns: any[] = [];
  paymentcolumns: any[] = [];

  activetab = 1;
  constructor() { }

  ngOnInit(): void {
    console.log(this.reporttitle)
    this.partitioncolumns();
   }

  ngAfterViewInit() {
    this.showModal.show();
    this.showModal.onHide.subscribe((reason: string | any) => {
      this.hidemodal.emit(this.columns);
    })
  }

  drop(event: CdkDragDrop<string[]>) {
    let columnOrder = this.columns.map(res => res.displayOrder).sort((a, b) => a - b);
    moveItemInArray(this.columns, event.previousIndex, event.currentIndex);
    this.columns.forEach((val, index) => {
      val.displayOrder = columnOrder[index];
    });
    this.partitioncolumns();
  }

  drop1(event: CdkDragDrop<string[]>) {
    let columnOrder = this.paymentcolumns.map(res => res.displayOrder).sort((a, b) => a - b);
    moveItemInArray(this.paymentcolumns, event.previousIndex, event.currentIndex);
    this.paymentcolumns.forEach((val, index) => {
      val.displayOrder = columnOrder[index];
    });
    this.columns.forEach((val, index) => {
      this.paymentcolumns.forEach((e) => {
        if(val.field == e.field){
          val.displayOrder = e.displayOrder;
        }
      });
    });
    this.columns.sort((a,b)=>a.displayOrder - b.displayOrder);
    this.partitioncolumns();
  }
  
  partitioncolumns(){
    this.paymentcolumns = [];
    this.transactioncolumns = [];
    this.columns.forEach(element => {
      if(element.ColumnType == 'dynamic'){
        this.paymentcolumns.push(element);
      }else{
        this.transactioncolumns.push(element);
      }
  
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.columns) {
      this.columns = this.columns.sort((a, b) => a.displayOrder - b.displayOrder);
    }
  }

  CloseModel() {
    this.hidemodal.emit(false);
    this.columns = false;
    this.showModal.hide();
  }

  applyRorder() {
    this.showModal.hide();
    // this.hidemodal.emit(this.columns);
  }

}
