import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { environment } from '../../../../../environments/environment';
import { AlertMessage } from '../../../../utilities/_helpers/alert.message';
import { HttpServices } from '../../../../utilities/_services/http.service';
import { AutoCompleteList, filter, filterResult } from './model/filter.model';
import { PageServices } from '../../../../utilities/_services/page.service';
import * as dayjs from "dayjs";
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { LocalCacheServices } from '../../../../utilities/_services/acclocalcache.service';

@Component({
  selector: 'app-report-filter',
  templateUrl: './report-filter.component.html',
  styleUrls: ['./report-filter.component.scss']
})
export class ReportFilterComponent implements OnInit {

  @Input() title: string = 'Filter Settings';
  @Input() columns: any = [];
  @Input() showmodal: boolean = false;
  @Input() pageID: string = '';
  @Input() filterlist: Array<filterResult> = [];
  @Input() isCustomField: boolean = false;
  @Input() colSelected: any = [];

  @Output() hidemodal = new EventEmitter<boolean>();
  @Output() filterResultList = new EventEmitter<Array<filterResult>>();
  @Output() columnsSelected = new EventEmitter<any>();

  @ViewChild('showmodal') public showemailmodal: ModalDirective;
  @ViewChild('reportFilterForm', { static: false }) mySelectForm: NgForm;

  dateValue;
  validate = false;
  loading: boolean = false;
  index = 0;
  autoCompleteList: Array<AutoCompleteList> = [];
  filterDataText: any;
  toDate;
  fromDate;
  columnsFilter: any = [];
  filterResult: any = [];
  isCustomFilter: boolean = false;
  orgSettings: any;
  currentUser: any;
  constructor(
    private alertMessage: AlertMessage,
    private http: HttpServices,
    private datePipe: DatePipe, private page: PageServices, private translate: TranslateService, public local: LocalCacheServices) {
  }

  ngOnInit(): void {
    this.isCustomFilter = this.isCustomField;
    let emptyColumnsFilter = [{ id: this.index, reportType: null, fieldID: 0, field: null, Options: [], condition: null, text: null }];
    this.columnsFilter = Object.assign([], emptyColumnsFilter);
    this.index++
    this.orgSettings = JSON.parse(this.local.getlocalStorage(this.local.localCurrentOrganization));
    this.currentUser = JSON.parse(this.local.getlocalStorage(this.local.localCurrentUserKey));
  }

  ngOnChanges() {
    if (this.isCustomField && this.showmodal) {
      let IsCustomField = this.columns.some(x => x.ReportType == 'Custom Fields');
      if (!IsCustomField) {
        this.LoadCustomField();
      }
      else {
        this.LoadFilterValue();
      }
    }
    else {
      this.LoadFilterValue();
    }
  }

  onSubmit(reportFilterForm: NgForm) {
    this.mySelectForm.form.markAllAsTouched();
    this.validate = true;
    if (reportFilterForm.invalid) {
      return
    }
    this.filterResult = [];
    let result = [];
    let index = 0;

    let columns = this.columns;
    let colSelected = this.colSelected;
    let newcustomfiels = [];
    this.columnsFilter.forEach((item) => {
      if (item.reportType == 'CF' && !(colSelected.some(x => x.ReportType == 'Custom Fields' && x.field == item.field.field))) {
        let selectedcol = columns.findIndex(x => x.ReportType == 'Custom Fields' && x.field == item.field.field);
        columns[selectedcol].selected = true;
        newcustomfiels.push(columns[selectedcol]);
      }
      if (item.reportType == 'ICF' && !(colSelected.some(x => x.RptType == 'ICF' && x.field == item.field.field))) {
        let selectedcol = columns.findIndex(x => x.RptType == 'ICF' && x.field == item.field.field);
        columns[selectedcol].selected = true;
        newcustomfiels.push(columns[selectedcol]);
      }
      index = 0;
      let autovalue: string = '';
      let filter: filterResult;
      let values: any = null;
      let textvalue: any;
      if (item.textvalue == null && item?.autovalue?.length > 0 &&
        (item.field.type.toLowerCase() == 'auto' || item.field.type.toLowerCase() == 'a')) {
        let len = item.autovalue.length;
        item.autovalue.forEach(item => {
          index++;
          autovalue += '\'' + item.fieldName.replaceAll("'", "''") + '\'' + (index == (len) ? '' : ',');
        });
        textvalue = autovalue;
        values = item.autovalue;
      }
      else if (item.field.type.toLowerCase() == 'number' || item.field.type.toLowerCase() == 'amount' || item.field.type.toLowerCase() == 'n') {
        item.textvalue = item.textvalue.replace(',', '');
        textvalue = item.textvalue;
        values = item.textvalue;
      }
      else if (item.field.type.toLowerCase() == 'date' || item.field.type.toLowerCase() == 'd') {
        if (item.condition == 'Between') {
          textvalue = item?.textvalue
          values = { fromDate: item.fromDate, toDate: item.toDate };
          if (item.textvalue == undefined) {
            let fromDate = this.datePipe.transform(new Date(item.fromDate), "yyyy/MM/dd").toString();
            let toDate = this.datePipe.transform(new Date(item.toDate), "yyyy/MM/dd").toString();
            textvalue = "'" + fromDate + "' AND '" + toDate + "' ";
          }
        }
        else {
          textvalue = new Date(item?.dateSelect.year, Number(item?.dateSelect.month) - 1, item?.dateSelect.day)
          if (textvalue == 'Invalid Date') {
            textvalue = item?.dateSelect;
          }
          values = item?.dateSelect;
        }
      }
      else {
        textvalue = item?.textvalue
        values = item?.textvalue;
      }


      filter = {
        title: item.field.title,
        reportType: item.reportType,
        fieldID: item.fieldID,
        field: item.field.field,
        condition: item.condition,
        text: item.field.type.toLowerCase() != 'date' ? textvalue.replace("','", "',N'") : (item.condition != "Between" ? moment(textvalue).format('YYYY/MM/DD') : textvalue),
        datatype: item.field.type,
        values: values,

      };
      result.push(filter);
    });

    let checkDuplicate = result.filter((item, i, arr) => arr.findIndex(t => t.title === item.title && t.condition === item.condition && (t.text === item.text || JSON.stringify(t.values) === JSON.stringify(item.values))) === i);

    if (this.columnsFilter.length != checkDuplicate.length) {
      this.alertMessage.errorTitleNotifier(this.translate.instant("ReportsModule.Remove duplicate line item"), "Information", 0);
      return;
    }

    let filterCondition;
    this.filterDataText = Object.assign([], result);
    this.filterDataText.forEach(item => {
      if (item.datatype.toLowerCase() == 'number' || item.datatype.toLowerCase() == 'amount' || item.datatype.toLowerCase() == 'n') {
        filterCondition = new filter().numberFilterCondition;
      }
      else if (item.datatype.toLowerCase() == 'text' || item.datatype.toLowerCase() == 't') {
        filterCondition = new filter().textFilterCondition;
      }
      else if (item.datatype.toLowerCase() == 'auto' || item.datatype.toLowerCase() == 'a') {
        filterCondition = new filter().autoFilterCondition;
      }
      else if (item.datatype.toLowerCase() == 'date' || item.datatype.toLowerCase() == 'd') {
        if (this.page.pageDetails.pageID == 59 || this.page.pageDetails.pageID == 169 || this.page.pageDetails.pageID == 166 || this.page.pageDetails.pageID == 4912) {
          filterCondition = new filter().dateFilterCondition.filter(x => x.field != "Between");
        } else {
          filterCondition = new filter().dateFilterCondition;
        }
      }
      const conditionName = filterCondition.filter(x => x.value == item.condition)[0].field;
      item.filtertxt = item.title + ' ' + conditionName + ' ';
      const itemText = item.text.replace("',N'", "','");
      if (item.datatype.toLowerCase() == 'date') {
        if (item.condition.toLowerCase() == 'between') {
          item.filtertxt += (itemText ?? '').split(' AND ').map(e => moment(e).format('DD/MM/YYYY')).join(' and ')
        } else {
          item.filtertxt += moment(itemText).format('DD/MM/YYYY')
        }
      } else {
        item.filtertxt += itemText;
      }
    });
    //displayed Columns in Report header
    if (newcustomfiels.length > 0) {
      newcustomfiels.forEach(item => {
        colSelected.push(item);
      });
      let displayedColumns = colSelected.map((res) => res.field);
      let col =
      {
        selectedColumns: colSelected,
        displayedColumns: displayedColumns
      }
      this.columnsSelected.emit(col);
    }
    //Final Result filter list
    this.filterResult = result;
    this.filterResultList.emit(this.filterResult);
    this.showemailmodal.hide();
  }

  CloseModel() {
    this.hidemodal.emit(false);
    this.showemailmodal.hide();
  }

  loadFilterOption(item, index) {
    this.columnsFilter[index].textvalue = null;
    this.columnsFilter[index].condition = null;
    this.columnsFilter[index].pattern = '';
    this.columnsFilter[index].autovalue = '';
    this.columnsFilter[index].reportType = item.field?.RptType;

    if (item?.field?.fieldID != undefined) {
      this.columnsFilter[index].fieldID = item?.field?.fieldID;
    }
    if (item.field.type.toLowerCase() == 'number' || item.field.type.toLowerCase() == 'amount' || item.field.type.toLowerCase() == 'n') {
      this.columnsFilter[index].Options = new filter().numberFilterCondition;
      this.columnsFilter[index].pattern = '^[0-9,-]+\.?[0-9]*$';
    }
    else if (item.field.type.toLowerCase() == 'text' || item.field.type.toLowerCase() == 't') {
      this.columnsFilter[index].Options = new filter().textFilterCondition;
    }
    else if (item.field.type.toLowerCase() == 'auto' || item.field.type.toLowerCase() == 'a') {
      this.columnsFilter[index].Options = new filter().autoFilterCondition;
    }
    else if (item.field.type.toLowerCase() == 'date' || item.field.type.toLowerCase() == 'd') {
      if (this.page.pageDetails.pageID == 59 || this.page.pageDetails.pageID == 169 || this.page.pageDetails.pageID == 166 || this.page.pageDetails.pageID == 4912) {
        this.columnsFilter[index].Options = new filter().dateFilterCondition.filter(x => x.field != "Between");
      } else {
        this.columnsFilter[index].Options = new filter().dateFilterCondition;
      }
    }
    if (item.field.type.toLowerCase() == 'auto' || item.field.type.toLowerCase() == 'a') {
      this.loadAutoComplete(index, item.field.autoCompleteName, item)
    }

  }

  addFilter() {
    this.mySelectForm.form.markAsUntouched();
    this.validate = false;
    let emptyColumnsFilter = { id: this.index, reportType: null, fieldID: 0, field: null, Options: [], condition: null, text: null };
    if (this.columnsFilter.length < 5) {
      this.columnsFilter.push(emptyColumnsFilter);
      this.index++;
    }
    else
      this.alertMessage.errorTitleNotifier(this.translate.instant("ReportsModule.Filter rows maximum 5 allowed"), "Information", 0);
  }

  removeFilter(index) {
    if (this.columnsFilter.length == 1) {
      this.alertMessage.errorTitleNotifier(this.translate.instant("ReportsModule.Filter rows minimum 1 allowed"), "Information", 0);
      return;
    }
    this.mySelectForm.form.markAsUntouched();
    this.validate = false;
    this.columnsFilter.splice(index, 1);
  }

  async loadAutoComplete(index: number, filterName: string, item: any) {
    let transId: any = '';
    if (this.autoCompleteList.length == 0 || !this.autoCompleteList.some(x => x.listid == filterName && x.field == item.field)) {
      if (filterName == 'CustomField' || filterName == 'ItemCustomField') {
        transId = item.fieldID;
      }
      let result: any = await this.http.getserviceawait(environment.reportsApiUrl + '/FilterReports/GetFilterReport?filterName=' + filterName + '&pageID=' + this.page.pageDetails.pageID + '&transId=' + transId);
      this.columnsFilter[index].auto = result.autoComplete;
      let autoCompleteList: AutoCompleteList = {
        listid: filterName,
        field: item.field.field,
        autoCompleteList: result.autoComplete
      };
      this.autoCompleteList.push(autoCompleteList)
    }
    else {
      this.columnsFilter[index].auto = this.autoCompleteList.filter(x => x.listid == filterName && x.field == item.field)[0].autoCompleteList
    }
  }
  reset() {
    this.mySelectForm.form.markAsUntouched();
    this.validate = false;
    this.index = 0;
    this.columnsFilter = [{ id: this.index, reportType: null, fieldID: 0, field: null, Options: [], condition: null, text: null }];
  }

  resetApply() {
    this.mySelectForm.form.markAsUntouched();
    this.validate = false;
    if (this.filterlist.length != 0) {
      this.columnsFilter = [{ id: this.index, reportType: null, fieldID: 0, field: null, Options: [], condition: null, text: null }];
      this.index = 0;
      this.filterResultList.emit([]);
      this.showemailmodal.hide();
    }
    else {
      this.index = 0;
      this.columnsFilter = [{ id: this.index, reportType: null, fieldID: 0, field: null, Options: [], condition: null, text: null }];
      this.CloseModel()
    }
  }

  SelectChange(event, idx) {
    let autovalue = this.columnsFilter[idx].autovalue as any[];
    if (autovalue.length > 50) {
      this.columnsFilter[idx].autovalue = autovalue.splice(0, 50)
      this.alertMessage.warningNotifier(this.translate.instant("ReportsModule.Only can select 50 items only!"), 1);
    }
  }

  DateRangechange(item) {
    if (item.fromDate == null && item.toDate == null) return;
    if (item.fromDate != null && item.toDate != null) {
      let fromDate = this.datePipe
        .transform(new Date(item.fromDate), "yyyy/MM/dd")
        .toString();
      let toDate = this.datePipe
        .transform(new Date(item.toDate), "yyyy/MM/dd")
        .toString();

      let columnsFilter = []
      this.columnsFilter.forEach(element => {
        if (element.id == item.id) {
          element.textvalue = "'" + fromDate + "' AND '" + toDate + "' ";
        }
        columnsFilter.push(element);
      });
      this.columnsFilter = columnsFilter;
    }
  }

  setDate(date, item) {
    let columnsFilter = []
    this.columnsFilter.forEach(element => {
      if (element.id == item.id) {
        element.textvalue = this.datePipe.transform(new Date(date), "yyyy-MM-dd").toString();;
      }
      columnsFilter.push(element);
    });
  }

  isNAN(val) {
    return isNaN(val);
  }

  async LoadCustomField() {
    this.http.getservice(environment.reportsApiUrl + "/FilterReports/GetCustomFieldReport/").subscribe({
      next: (result: any) => {
        if (result.filterlist.length > 0) {
          result.filterlist.forEach(item => {
            item.displayOrder = this.columns.length + 1;
            this.columns.push(item);
          });
          this.isCustomField = false;
          this.LoadFilterValue();
        }
        else {
          this.LoadFilterValue();
        }
      }
    });
  }

  LoadFilterValue() {
    if (this.showmodal) {
      this.columns = this.columns.sort((a, b) => a.displayOrder - b.displayOrder);
      this.showemailmodal.show();
      this.columns = this.columns.filter(x => x.IsFilterColumn);
      if (this.filterlist.length > 0) {
        this.index = 0;
        this.columnsFilter = [];
        this.filterlist.forEach(item => {

          let field = this.columns.filter(x => x.field == item.field && x.fieldID == item.fieldID && x.RptType == item.reportType)[0];
          this.columnsFilter.push({ id: this.index, reportType: item.reportType, fieldID: item.fieldID, field: field, Options: [], condition: item.condition, text: null });

          if (item.datatype.toLowerCase() == 'auto' || item.datatype.toLowerCase() == 'a') { // Load Auto Complete
            this.loadAutoComplete(this.index, field.autoCompleteName, item)
          }

          if (item.datatype.toLowerCase() == 'number' || item.datatype.toLowerCase() == 'amount' || item.datatype.toLowerCase() == 'n') {
            this.columnsFilter[this.index].Options = new filter().numberFilterCondition;
            this.columnsFilter[this.index].pattern = '^[0-9,-]+\.?[0-9]*$';
            this.columnsFilter[this.index].textvalue = item.text;
          }
          else if (item.datatype.toLowerCase() == 'text' || item.datatype.toLowerCase() == 't') {
            this.columnsFilter[this.index].Options = new filter().textFilterCondition;
            this.columnsFilter[this.index].textvalue = item.text;
          }
          else if (item.datatype.toLowerCase() == 'auto' || item.datatype.toLowerCase() == 'a') {
            this.columnsFilter[this.index].Options = new filter().autoFilterCondition;
            this.columnsFilter[this.index].autovalue = item.values;
          }
          else if (item.datatype.toLowerCase() == 'date' || item.datatype.toLowerCase() == 'd') {
            if (this.page.pageDetails.pageID == 59 || this.page.pageDetails.pageID == 169 || this.page.pageDetails.pageID == 166 || this.page.pageDetails.pageID == 4912) {
              this.columnsFilter[this.index].Options = new filter().dateFilterCondition.filter(x => x.field != "Between");
            } else {
              this.columnsFilter[this.index].Options = new filter().dateFilterCondition;
            }
            if (item.condition == 'Between') {
              this.columnsFilter[this.index].fromDate = this.datePipe.transform(new Date(item.values.fromDate), "yyyy/MM/dd");
              this.columnsFilter[this.index].toDate = this.datePipe.transform(new Date(item.values.toDate), "yyyy/MM/dd");
            }
            else
              this.columnsFilter[this.index].dateSelect = item.values;
          }
          this.index++;
        });
      }
      // this.isCustomField = true;
    }
  }
}

