<div bsModal #showTCmodal="bs-modal" class="modal fade" tabindex="-1" role="dialog"     aria-labelledby="showDeliverymodalLabel" aria-hidden="true" [config]="{ backdrop: 'static' }">
  <div class="modal-dialog  modal-xl" role="document">
    <form class="form-horizontal needs-validation" novalidate="novalidate"  [formGroup]="tcForm" >
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">{{'Common.Terms & Conditions' | translate}}</h4>
          <button type="button" class="close " (click)="hideTCmodal(false)" >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body">

          <div class="form-group row m-1">
              <label class="col-md-2 col-form-label" for="subject">{{'PurchaseModule.PurchaseOrder.Title' | translate}}<span class="text-danger">*</span></label>
              <div class="col-md-10">
                <input type="text" formControlName="TermsTitle" class="form-control" maxlength="500" xssDirective/>
                <div *ngIf="submitted && f.TermsTitle.errors" class="invalid-feedback">
                  <div *ngIf="f.TermsTitle.errors.required">{{'PurchaseModule.PurchaseOrder.Please provide a Title' | translate}}</div>
                </div>
                <div *ngIf="submitted && titleExists" class="invalid-feedback">
                  {{'PurchaseModule.PurchaseOrder.Title already exists' | translate}}
                </div>
              </div>
          </div>


          <div class="">
            <quill-editor formControlName="TermCon" class="w-100" [preserveWhitespace]="true" >
              <div quill-editor-toolbar>
                <span class="ql-formats">
                 
                  <select class="ql-size" [title]="'Size'">
                    <option value="6pt"></option>
                    <option value="8pt"></option>
                    <option value="9pt"></option>
                    <option value="10pt"></option>
                    <option value="11pt"></option>
                    <option value="12pt"></option>
                    <option value="13pt"></option>
                    <option value="14pt"></option>
                    <option value="16pt"></option>
                    <option value="18pt"></option>
                    <option value="24pt"></option>
                    <option value="30pt"></option>
                    <option value="36pt"></option>
                    <option value="48pt"></option>
                  </select>
                </span>
                <span class="ql-formats">
                  <button class="ql-bold"></button>
                  <button class="ql-italic"></button>
                  <button class="ql-underline"></button>
                  <button class="ql-strike"></button>
                </span>
                <span class="ql-formats">
                  <select class="ql-color"></select>
                  <select class="ql-background"></select>
                </span>
                <span class="ql-formats">
                  <button class="ql-list" value="ordered"></button>
                  <button class="ql-list" value="bullet"></button>
                  <select class="ql-align">
                    <option selected></option>
                    <option value="center"></option>
                    <option value="right"></option>
                    <option value="justify"></option>
                  </select>
                </span>

                <span class="ql-formats">

                  <div class="dropdown" dropdown>
                    <button class="dropdown-toggle" id="dropdownMenu4" type="button" dropdownToggle
                      aria-haspopup="true" aria-expanded="false">{{'SharedModule.Insert Placeholders' | translate}}</button>
                    <div *dropdownMenu class="dropdown-menu dropdown-menu-right ddm-placeholder-size" style="margin: 0px;">
                      <div class="d-flex">
                        <div *ngFor="let phdetail of phdetails;let idx=index">
                          <h6 class="dropdown-header text-muted h6" *ngIf="phdetail.header">
                            {{phdetail.field}}</h6>
                          <div *ngFor="let subMenulist of phdetail.submenu;">
                            <a class="dropdown-item ddi-placeholder-size" id="ddmInsertPlaceholders_subMenulist.code"
                              (click)="changeCustom($event,subMenulist.code)">{{subMenulist.field}}</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </span>
              </div>
            </quill-editor>
          </div>


        </div>
        <div class="modal-footer">
          <button class="btn btn-primary btn-normal mr-3" type="button" (click)="onSubmitTermsCondition()" *userCanEdit="[true]">
            {{'Common.Save' | translate}}
          </button>
          <button class="btn btn-light" type="button" (click)="hideTCmodal(false)">{{'Common.Cancel' | translate}}</button>
        </div>
      </div>
    </form>
    <!-- /.modal-content-->
  </div>
  <!-- /.modal-dialog-->
</div>
