import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AlertMessage } from '../../utilities/_helpers/alert.message';
import { AuthenticationService } from '../../utilities/_services/authentication.service';
import Swal from 'sweetalert2';
import { HttpParams } from '@angular/common/http';
import { AllCommunityModules, GridOptions, Module } from '@ag-grid-community/all-modules';
import { HttpServices } from '../../utilities/_services/http.service';
import { environment } from '../../../environments/environment';
import * as moment from "moment";
import { resolve } from '@angular/compiler-cli/src/ngtsc/file_system';
import { promise } from 'protractor';
import { PrintService } from '../template/services/print.service';
import { GroupNames } from '../template/models/enumlist';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';
import { HostService } from '../../utilities/_services/host.serices';
import { EinvoiceServices } from '../../utilities/_services/einvoice.service';

@Component({
  selector: 'app-onlineinvoice',
  templateUrl: './onlineinvoice.component.html',
  styleUrls: ['./onlineinvoice.component.scss']
})
export class OnlineinvoiceComponent implements OnInit {
  @ViewChild('pdfViewerOnDemand') pdfViewerOnDemand;
  @ViewChild('showPrint') public showPrint: ModalDirective;
  key: any;
  resObj: any;
  statusMessage = '';
  userId: any;
  orgId: any;
  custCode: any;
  replyTo: any;
  transId: any;
  transTypeId: any;
  columnDefs: any;
  public gridOptions: GridOptions;
  taxAmountsAreList: any = [];
  OnlinePaymentList: any = [];
  subTotal = '';
  orgCurrencyCode = '';
  rowData: any;
  modules: Module[] = AllCommunityModules;
  displayContactAddress = '';
  displayOrgAddress = '';
  TaxTypeID: any = 3;
  CurrencyCode: any = 0;
  total;
  TaxType: any;
  invStatusId: any;
  invStatusDesc: string = "";
  ContactName: any;
  invNo: any;
  invDate: any;
  invExpDate: any;
  selectedTransactions: any = [];
  showCurrencyInfo = false;
  showToCurrencyCode = '';
  showPerUnit = '';
  pdfhtml: any;
  transactionData: any;
  totalTaxAmount;
  domLayout: string = "autoHeight";
  totalwithTaxAmount;
  templateType:string;
  isDigiSME = false;
  CentOrgID: any;
  CentralUserID : any;
  LicenseTypeID: any;
  Orgimg: string = "";
  EinvoiceResponse: any;
  longID: any;
  UUID: any;
  QRcode:any=''
  loadingEInvoice:boolean=false;
  @ViewChild("RejectPopup") RejectPopup: ModalDirective;
  ContactID =0;
  EnabledEinvoiceRequest:boolean=false;
  constructor(private route: ActivatedRoute, private authService: AuthenticationService, private router: Router,
    private alertMessage: AlertMessage, private http: HttpServices, public printService: PrintService,private hostService: HostService,private einvoiceService : EinvoiceServices) {
    this.route.queryParams.subscribe(params => {
      this.key = params.key;
    });
    this.isDigiSME = this.hostService.getDigisme();
  }

  numberFormatToFixed(val: Number) {
    const formatter = new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    let toFixed: Number = val ? val : 0;
    return formatter.format(Number(toFixed))
  }

  getContactAddressHTML() {
    return this.displayContactAddress;
  }

  getOrgAddressHTML() {
    return this.displayOrgAddress;
  }

  async ngOnInit() {
    // this.transId = 104796;
    // this.transTypeId = 6;
    // this.userId = 1;
    // this.orgId = 1;
    // this.custCode = 100;
    // this.replyTo = '';
    // this.createColumnDefs().then(()=>{
    //   this.getInvDetailsByID().then(()=>{});
    // });
    await this.authService.VerifyKey(this.key).then((res) => this.resObj = res);
    this.statusMessage = this.resObj.StatusMessage;
    this.EnabledEinvoiceRequest=this.resObj.ResponseData?.EnabledEinvoiceRequest
    if (this.resObj.isSuccess == false) {
      Swal.fire({
        title: "",
        text: this.resObj.StatusMessage,
        icon: 'warning',
        showCancelButton: false,
        confirmButtonColor: '#d33',
        confirmButtonText: 'Ok'
      }).then((result) => {
        if (result.isConfirmed) {
          // this.router.navigateByUrl("/login");
        }
      })
      if (this.resObj.StatusMessage == 'Invoice link has been expired!') {
        this.transId = this.resObj.ResponseData.TransID;
        this.transTypeId = this.resObj.ResponseData.TransTypeID;
        this.userId = this.resObj.ResponseData.UserID;
        this.orgId = this.resObj.ResponseData.OrgID;
        this.custCode = this.resObj.ResponseData.CustCode;
        this.replyTo = this.resObj.ResponseData.ReplyToEmail;
        // this.createColumnDefs().then(() => {
        //   this.getInvDetailsByID().then(() => { });
        // });
      }
    }
    else {
      if (this.resObj.ResponseData.Type == "OnlineInvoice") {
        this.transId = this.resObj.ResponseData.TransID;
        this.transTypeId = this.resObj.ResponseData.TransTypeID;
        this.userId = this.resObj.ResponseData.UserID;
        this.orgId = this.resObj.ResponseData.OrgID;
        this.custCode = this.resObj.ResponseData.CustCode;
        this.replyTo = this.resObj.ResponseData.ReplyToEmail;
        this.CentOrgID = this.resObj.ResponseData.CentOrgID;
        this.CentralUserID = this.resObj.ResponseData.CentralUserID;
        this.LicenseTypeID = this.resObj.ResponseData.LicenseTypeID;
        this.createColumnDefs().then(() => {
          this.getInvDetailsByID().then(() => { });
        });
      }
      else {
        Swal.fire({
          title: "",
          text: "Invalid link",
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#d33',
          confirmButtonText: 'Ok'
        }).then((result) => {
          if (result.isConfirmed) {
            // this.router.navigateByUrl("/login");
          }
        })
      }
    }
  }

  loadgridoptions() {
    this.gridOptions = {};
    this.gridOptions = <GridOptions>{
      columnDefs: this.columnDefs,
      defaultColDef: {
        flex: 1
      },

      onGridReady: (params: any) => {
        this.gridOptions.api.sizeColumnsToFit();
      },
      onGridSizeChanged: () => {
        this.gridOptions.api.sizeColumnsToFit();
      },
      rowHeight: 48
    };

  }

  currencyFormatter(params: any) {
    return params.value == null || params.value == '' ? '' : parseFloat(params.value).toFixed(params.colDef.precision);
  }

  // this.columnDefs = [
  //   {
  //     headerName: "Description",
  //     field: "InvDescription",
  //     width: 700,
  //     cellRenderer: (params) => {
  //       if (params.data.ProductCategory != null && params.data.ProductCategory != '') {
  //         return '<div >' + params.value + '</div><div class="badge badge-pill badge-success text-wrap">' + params.data.ProductCategory + '</div>';
  //       }
  //       else {
  //         return params.value;
  //       }
  //     },
  //   },
  //   {
  //     headerName: "Quantity",
  //     field: "Quantity",
  //     type: 'rightAligned',
  //     width: 100,
  //     precision: 2,
  //     valueFormatter: this.currencyFormatter,
  //     cellRenderer: (params) => {
  //       if (params.data.UnitType != null && params.data.UnitType != '') {
  //         return '<div >' + params.value + '</div><div class="badge badge-pill badge-success text-wrap">' + params.data.UnitType + '</div>';
  //       }
  //       else {
  //         return params.value;
  //       }
  //     },
  //   },
  //   {
  //     headerName: "Unit Price",
  //     field: "UnitPrice",
  //     type: 'rightAligned',
  //     width: 100,
  //     precision: 2,
  //     valueFormatter: this.currencyFormatter,
  //   },
  //   {
  //     headerName: "Amount",
  //     field: "Amount",
  //     width: 100,
  //     type: 'rightAligned',
  //     precision: 2,
  //     valueFormatter: this.currencyFormatter,
  //   },
  //   {
  //     headerName: "InvDetID",
  //     field: "InvoiceTransDetailID",
  //     type: 'rightAligned',
  //     hide: true
  //   }
  // ];
  createColumnDefs() {
    return new Promise(resolve => {
      this.columnDefs = [];

      this.columnDefs = [
        {
          field: 'drag',
          title: "",
          type: 'action',
          isVisible: false,
          isEditable: false,
          isCustom: false,
          isMandatory: false,
          css: {
            'width': '20px',
            'text-align': 'center'
          }
        },
        {
          field: 'ItemID',
          title: "Items",
          type: 'list',
          isVisible: false,
          isEditable: false,
          isCustom: false,
          isMandatory: false,
          css: {
            'width': '150px',
            'max-width': '150px',
            'text-align': 'left',
          }
        },

        {
          field: 'ItemDescription',
          title: "Description",
          type: 'text',
          isVisible: true,
          isEditable: true,
          isCustom: false,
          isMandatory: false,
          css: {
            'width': '230px',
            'text-align': 'left'
          }
        },
        {
          field: 'Quantity',
          title: "Qty",
          type: 'numeric',
          align: 'right',
          isVisible: true,
          isEditable: true,
          isCustom: false,
          isMandatory: false,
          css: {
            'width': '100px',
            'text-align': 'right'
          }
        },
        {
          field: 'UnitPrice',
          title: "Unit price",
          type: 'numeric',
          align: 'right',
          isVisible: true,
          isEditable: false,
          isCustom: false,
          isMandatory: false,
          css: {
            'width': '100px',
            'text-align': 'right'
          }
        },
        {
          field: 'TaxRateName',
          title: "Tax Rate",
          type: 'text',
          isVisible: true,
          isEditable: false,
          isCustom: false,
          isMandatory: false,
          css: {
            'width': '130px',
            'max-width': '130px',
            'text-align': 'left',
          }
        },
        {
          field: 'TaxAmount',
          title: "Tax Amount",
          align: 'right',
          type: 'numeric',
          isVisible: true,
          isEditable: false,
          isCustom: false,
          isMandatory: false,
          css: {
            'width': '80px',
            'text-align': 'right'
          }
        },
        {
          field: 'Amount',
          title: "Amount",
          type: 'numeric',
          align: 'right',
          isVisible: true,
          isEditable: false,
          isCustom: false,
          isMandatory: false,
          css: {
            'width': '100px',
            'text-align': 'right'
          }
        },
        {
          field: 'delete',
          title: "",
          type: 'action',
          align: 'right',
          isVisible: false,
          isEditable: false,
          isCustom: false,
          isMandatory: false,
          css: {
            'width': '20px',
            'text-align': 'center'
          }
        },
      ];
      resolve('');
    });
  }

  getOrganisationDetail(orgData) {
    return new Promise(resolve => {
      this.orgCurrencyCode = orgData.CurrencyCode;
      this.displayOrgAddress = orgData.LegalName + '<br/>';
      if (orgData.PostalAttention != '') {
        this.displayOrgAddress += orgData.PostalAttention + '<br/>'
      }
      if (orgData.postalAddress != '') {
        this.displayOrgAddress += orgData.PostalAddress + '<br/>';
      }
      if (orgData.postalCity != '') {
        this.displayOrgAddress += orgData.PostalCity + '<br/>';
      }
      if (orgData.PostalState != '' && orgData.PostalZipCode != '') {
        this.displayOrgAddress += orgData.PostalState + ' - ' + orgData.PostalZipCode;
      } else if (orgData.PostalState == '' && orgData.PostalZipCode != '') {
        this.displayOrgAddress += orgData.PostalZipCode;
      } else if (orgData.PostalState != '' && orgData.PostalZipCode == '') {
        this.displayOrgAddress += orgData.PostalState;
      }
      resolve('');
    });
  }

  getContactDetail(data) {

    return new Promise(resolve => {
      let attention, address, city, state, zipCode;
      attention = data.split('#')[0];
      address = data.split('#')[1];
      city = data.split('#')[2];
      state = data.split('#')[3];
      zipCode = data.split('#')[4];
      this.orgCurrencyCode = data[0].CurrencyCode;

      if (attention != '') {
        this.displayContactAddress += attention + '<br/>'
      }
      if (address != '') {
        this.displayContactAddress += address + '<br/>';
      }
      if (city != '') {
        this.displayContactAddress += city + '<br/>';
      }
      if (state != '' && zipCode != '') {
        this.displayContactAddress += state + ' - ' + zipCode;
      } else if ((state == '') && (zipCode != '')) {
        this.displayContactAddress += zipCode;
      } else if ((state != '') && (zipCode == '')) {
        this.displayContactAddress += state;
      }
      resolve('');
    });
  }

  setExchangeRate(exdata) {
    return new Promise(resolve => {
      let lstEexchangeRate: any = [];
      lstEexchangeRate = exdata;
      let data = lstEexchangeRate.filter(x => x.IsSelected == true);
      if (data.length > 0) {
        this.showToCurrencyCode = "1 " + this.orgCurrencyCode + " = " + data[0].Unitsper + " " + data[0].ToCurrencyCode + "";
        this.showPerUnit = data[0].PerUnit;
      }
      resolve('');
    });
  }

  print() {
    // this.printService.printOnlineQuotePDFForm(GroupNames.Invoice,this.transId, this.orgId,this.custCode,this.pdfViewerOnDemand);
    this.pdfhtml = "<div class='spinner-border text-primary'></div>";
    this.printService.printOnlineInvoicePDFForm(GroupNames.Invoice, this.transId, this.orgId, this.custCode, this.pdfViewerOnDemand,this.templateType);
    this.showPrint.show();
  }

  getInvDetailsByID() {
    return new Promise(resolve => {
      let params = new HttpParams();
      params = params.set('transId', this.transId.toString());
      params = params.set('transTypeId', this.transTypeId.toString());
      params = params.set('userId', this.userId);
      params = params.set('orgId', this.orgId);
      params = params.set('custCode', this.custCode);
      this.http.postservice(environment.salesApiUrl + '/SalesInvoice/GetOnlineInvoiceDetailsById', params).subscribe({
        next: (data) => {
          this.transactionData = data['StatusMessage'];
          let Invdata = data['ResponseData'];
          this.EinvoiceResponse=data['ResponseData'].EInvoiceResponse
          this.UUID=data['ResponseData'].UUID
          this.longID=data['ResponseData'].longID
          if(this.UUID && this.longID && this.EnabledEinvoiceRequest){
            this.getEinvoiceQR();
          }
          this.getContactDetail(Invdata.ContactAddress).then(() => {
            this.getContactAddressHTML();
          });
          this.getOrganisationDetail(Invdata.OrgDetail[0]).then(() => {
            this.getOrgAddressHTML();
          });

          if (this.orgCurrencyCode !== Invdata.CurrencyCode) {
            this.setExchangeRate(Invdata.UsedCurrencyTypes).then(() => {
              this.showCurrencyInfo = true;
            })
          }

          // this.quotedata = data[0];
          let OrgDetail = Invdata.OrgDetail[0];
          const tempItem = Invdata.InvoiceItemTable;
          this.Orgimg = OrgDetail.OrgLogo!= "" ? OrgDetail.OrgLogo : '';
          this.CurrencyCode = Invdata.CurrencyCode;
          this.subTotal = this.numberFormatToFixed(Invdata.TotalAmount);
          this.total = Invdata.AmountDue;
          this.TaxTypeID = Invdata.TaxTypeID;
          this.TaxType = Invdata.TaxType;
          this.invStatusId = Invdata.InvoiceStatusTypeID;
          this.invStatusDesc = Invdata.Status;
          this.ContactName = Invdata.ContactName;
          this.invNo = Invdata.InvoiceNO;
          this.invDate = moment(Invdata.InvoiceDate).format('DD MMM YYYY');
          this.invExpDate = moment(Invdata.InvoiceDueDate).format('DD MMM YYYY');
          this.totalTaxAmount = this.numberFormatToFixed(Invdata.TotalTaxRateSubTotal);
          this.totalwithTaxAmount = this.numberFormatToFixed(Invdata.TotalAmount);
          this.templateType = Invdata.TemplateType;
           this.ContactID=Invdata.ContactID
          this.selectedTransactions.push(
            {
              TransID: this.transId,
              ContactID: Invdata.ContactID,
              ContactName: Invdata.ContactName,
              TransactionTypeID: this.transTypeId,
              InvStatus: Invdata.InvoiceStatusTypeID,
              Amount: Invdata.TotalAmount
            });
          let allrowTotal: number = 0;
          let invItem: any = [];
          if (tempItem !== null) {
            tempItem.forEach(rw => {
              allrowTotal = allrowTotal + Number(rw.UnitPrice) * Number(rw.Quantity);
              let rowFeild = {
                ItemDescription: rw.InvDescription ? rw.InvDescription : "",
                Quantity: (rw.Quantity == 0) ? "" : rw.Quantity,
                UnitPrice: (rw.UnitPrice == 0) ? "" : rw.UnitPrice,
                Amount: rw.Amount,
                InvoiceTransDetailID: rw.InvoiceTransDetailID,
                UnitType: rw.UnitType ? rw.UnitType : '',
                ProductCategory: rw.ProductCategory ? rw.ProductCategory : "",
                TaxRateName: rw.TaxRateName,
                TaxAmount: rw.TaxAmount,
                ItemID: rw.InvItemID,
                ItemGroupID: rw.ItemGroupID,
                IsGroup: rw.IsGroup,
              }
              invItem.push(rowFeild);
              invItem.sort((x, y) => x.id - y.id);
            });
            let TempRowResult = invItem.filter(o => !Object.keys(o).every(k => !o[k])); // find empty row
            this.rowData = TempRowResult;
            this.loadgridoptions();

            // this.subTotal = this.numberFormatToFixed((allrowTotal * 100) / 100);
            this.taxAmountsAreList = Invdata.TotalTaxComponents;
            this.OnlinePaymentList = Invdata.OnlinePayment;
          }
        }

      });
      resolve('');
    });

  }

  braceFormatter(params) {
    let num;
    if (params > 0)
      num = Number(params).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    else if (Number(params) == 0) {
      num = "";
    }
    else {
      num = "(" + Number(params).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,').replace("-", "") + ")";
    }
    return num;
  }

  checkoutUrl: any;

  LoadPaymentURL(PaymentUrl: string, PaymentServiceId: number,keyId:number) {
    let onlineStripePaymentInfo: any = {};
    if (PaymentServiceId === 3) //stripe call
    {
        onlineStripePaymentInfo = {
        "KeyId": keyId,
        "Key": this.key,
        "TransactionData": this.transactionData
      }

      this.http.postservice(environment.reconcilationApiUrl + '/reconcile/StripePayment/CreateCheckoutSession', onlineStripePaymentInfo).subscribe({
        next: (data) => {
          if (data["isSuccess"] == false) {
            this.alertMessage.errorNotifier(data["statusMessage"], 0);
          } else {
            window.location.href = data['url'];
          }
        },
        error: error => {
          console.log(error);
        }
      });
    }
    else if (PaymentServiceId === 2) //paypal call
    {
      onlineStripePaymentInfo = {
        "Key": this.key,
        "transactionData": this.transactionData
      }

      this.http.postservice(environment.reconcilationApiUrl + '/reconcile/PaypalPayment/CreateCheckoutSession', onlineStripePaymentInfo).subscribe({
        next: (data) => {
          window.location.href = data['url'];
        },
        error: error => {
        }
      });
    }
  }
  RejectReason:any=''
  RejectEinvoice: boolean = false;
  async EAction(action) {
    if (action == "Send") {
        this.loadingEInvoice = true;
        await this.einvoiceService.OnlineSubmitDocument(this.transId.toString(), this.transTypeId, '',this.userId,this.orgId,this.custCode).then((data: any) => {
          if(!data['success'] && data['status']=='Validation'){
            let val={
              OrgID:  this.resObj.ResponseData.OrgID,
              UserID:  this.resObj.ResponseData.UserID,
              TransTypeID:  0,
              TransID:  this.ContactID,
              CustCode:  this.resObj.ResponseData.CustCode
            }
            const link = this.router.serializeUrl(this.router.createUrlTree(['/links/requestdata'],  { queryParams: { id: btoa(JSON.stringify({ResponseData:val})) } }));
            window.open(link, '_blank');
          }
            else if (data['success']) {
                this.EinvoiceResponse = data['status']
            }
            this.loadingEInvoice = false;
        });
    }
    else if (action == "Reject") {
      if (!this.RejectReason) {
        this.RejectEinvoice = true;
        return;
      }
      this.RejectEinvoice = false
      this.RejectPopup.hide();
      this.loadingEInvoice = true;
      await this.einvoiceService.RejectEinvoice(this.transId.toString(), this.transTypeId, this.RejectReason,this.userId,this.orgId,this.custCode).then((data: any) => {
        if (data['data'] == "Error") {
          let val = JSON.parse(data['message']);
          this.alertMessage.errorNotifier(val.error.details[0].message, 0)
        }
        else {
          this.alertMessage.successNotifier("E-Invoice Rejected Successfully", 0)
        }
           this.RejectReason = "";
          this.loadingEInvoice = false;
      });
  }
}

getEinvoiceQR(){
  let params = new HttpParams();
  params = params.set('uuid',this.UUID);
  params = params.set('longID',this.longID);
  params = params.set('CustomerCode',this.custCode);
  this.http.postservice(environment.templateApiUrl + '/templates/GetOnlineEinvoiceQrCode', params).subscribe({
    next: (data: any) => {
         this.QRcode="data:image/png;base64," + data.responseData
    }
  });
}
}
