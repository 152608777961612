<div bsModal #showtextmodal="bs-modal" class="modal fade" [config]="{ backdrop: 'static' }" tabindex="-1" role="dialog"
    aria-labelledby="showtextmodal" aria-hidden="true">
    <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
            <div class="modal-header pb-0">                   
                 <button id="btnHideDescModal-1" type="button" class="close" (click)="hideDescmodal()">
                        <span aria-hidden="true">×</span>
                    </button> 
            </div>
            <div class="modal-body">
                <div class="col-md-12">
                    <quill-editor [(ngModel)]="htmlText" class="w-100" [preserveWhitespace]="true">
                        <div quill-editor-toolbar>
                            <span class="ql-formats">
                                <select class="ql-size" [title]="'Size'">
                                  <option value="6pt"></option>
                                  <option value="8pt"></option>
                                  <option value="9pt"></option>
                                  <option value="10pt"></option>
                                  <option value="11pt"></option>
                                  <option value="12pt"></option>
                                  <option value="13pt"></option>
                                  <option value="14pt"></option>
                                  <option value="16pt"></option>
                                  <option value="18pt"></option>
                                  <option value="24pt"></option>
                                  <option value="30pt"></option>
                                  <option value="36pt"></option>
                                  <option value="48pt"></option>
                                </select>
                              </span>
                              <span class="ql-formats">
                                <button class="ql-bold"></button>
                                <button class="ql-italic"></button>
                                <button class="ql-underline"></button>
                                <button class="ql-strike"></button>
                              </span>
                              <span class="ql-formats">
                                <select class="ql-color"></select>
                                <select class="ql-background"></select>
                              </span>
                              <span class="ql-formats">
                                <button class="ql-list" value="ordered"></button>
                                <button class="ql-list" value="bullet"></button>
                                <select class="ql-align">
                                  <option selected></option>
                                  <option value="center"></option>
                                  <option value="right"></option>
                                  <option value="justify"></option>
                                </select>
                              </span>                         
                        </div>
                    </quill-editor>
                </div>
            </div>
            <div class="modal-footer">
                <button id="btnAddDescription" class="btn btn-primary" type="button" (click)="addDescription()" *ngIf="mode!='TV'">Add Description</button>               
                <button id="btnHideDescModal-2" class="btn btn-danger" type="button" (click)="hideDescmodal()">Cancel</button>
            </div>
        </div>
    </div>
</div>