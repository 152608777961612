import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { LocalCacheServices } from '../../../utilities/_services/acclocalcache.service';
import { PageServices } from '../../../utilities/_services/page.service';

@Injectable({
  providedIn: 'root'
})
export class ReportDataPassingService {

  public params: any;
  curentUser: any;
  TicketID: BehaviorSubject<number> = new BehaviorSubject(0);
  public linkDetails = {
    field: "linkDetail",
    title: "",
    align: "tr-left",
    sort: false,
    sticky: true,
    isExport: false
  };

  constructor(
    private router: Router,
    private local: LocalCacheServices,
    private page: PageServices,
  ) {
    this.curentUser = JSON.parse(this.local.getlocalStorage(this.local.localCurrentUserKey));
  }

  navigate(linkData) {
    linkData = JSON.parse(linkData);
    let URL = linkData.RedirectUrl;
    let queryParams;
    switch (linkData.TransTypeId) {
      case 2: { // Need to commet the transactions Type
        let billtransID = linkData.TransId + "," + linkData.TransTypeId + "," + linkData.ContactOrStatusId;
        queryParams = { queryParams: { id: btoa(billtransID) } };
        break;
      }
      case 3: {//sales/creditnotes/creditnoteview
        let billtransID = linkData.TransId + "," + linkData.TransTypeId;
        queryParams = { queryParams: { id: btoa(billtransID) } };
        break;
      }
      case 4: {//purchase/creditnotes/creditnoteview
        let billtransID = linkData.TransId + "," + linkData.TransTypeId
        queryParams = { queryParams: { id: btoa(billtransID) } };
        break;
      }
      case 6: { //sales/invoice/viewinvoice
        let InvoicetransID = linkData.TransId + "," + linkData.TransTypeId + "," + linkData.ContactOrStatusId;
        queryParams = { queryParams: { id: btoa(InvoicetransID) } };
        break;
      }
      case 8: { //sales/creditnotes/creditnoteview
        let billtransID = linkData.TransId + "," + linkData.TransTypeId;
        queryParams = { queryParams: { id: btoa(billtransID) } };
        break;
      }
      case 9: { ///transactions/viewtransaction
        queryParams = {
          queryParams: {
            account: btoa(linkData.AccountId), tranid: btoa(linkData.TransId), trantypeid: btoa(linkData.TransTypeId)
          }
        }
        break;
      }
      case 10: { ///transactions/viewtransaction
        queryParams = {
          queryParams: {
            account: btoa(linkData.AccountId), tranid: btoa(linkData.TransId), trantypeid: btoa(linkData.TransTypeId)
          }
        }
        break;
      }
      case 11: { ///transactions/viewtransaction
        queryParams = {
          queryParams: {
            account: btoa(linkData.AccountId), tranid: btoa(linkData.TransId), trantypeid: btoa(linkData.TransTypeId)
          }
        }
        break;
      }
      case 20: { //purchase/viewreceive
        let ReceiveID = `${linkData.ROCopySourceID},${linkData.TransId},${linkData.Status},${linkData.ROCopySourceTypeID}`;
        queryParams = { queryParams: { id: btoa(ReceiveID) } };
        break;
      }
      case 25: { ///transactions/viewtransaction
        queryParams = {
          queryParams: {
            account: btoa(linkData.AccountId), tranid: btoa(linkData.TransId), trantypeid: btoa(linkData.TransTypeId)
          }
        }
        break;
      }
      case 12: { ///inventory/newitem
        let itemId = linkData.TransId;
        queryParams = { queryParams: { id: btoa(itemId) } };
        break;
      }
      case 21: { ///sales/delivery/deliveryview
        let DOID = linkData.TransId;
        queryParams = { queryParams: { id: btoa(DOID) } };
        break;
      }
      case 14: { //accounting/journalentries/viewjournalentries
        queryParams = {
          queryParams: {
            id: btoa(linkData.TransId)
          }
        }
        break;
      }
      case 101: { // bankaccounts/accounttransactions
        queryParams = { queryParams: { id: btoa(linkData.AccountId) } };
        break;
      }
      case 30: { //sales/debitnote/viewdebitnote
        let debitnoteID = linkData.TransId + "," + linkData.TransTypeId + "," + linkData.ContactOrStatusId;
        queryParams = { queryParams: { id: btoa(debitnoteID) } };
        break;
      }
      case 31: { //purchase/debitnote/viewdebitnote
        let debitnoteID = linkData.TransId + "," + linkData.TransTypeId + "," + linkData.ContactOrStatusId;
        queryParams = { queryParams: { id: btoa(debitnoteID) } };
        break;
      }
    }
    this.router.navigate([URL], queryParams);
  }

  navigateToReports(data, column, fieldData?: any, fromDate?: any, toDate?: any) {
    let obj: any = {};
    let orgDetails = JSON.parse(this.local.getlocalStorage(this.local.localCurrentOrganization));
    let orgFinanceStart = new Date(orgDetails.financeStart);
    let orgFinanceEND = new Date(orgDetails.financeEnd);
    let pageid = 89;
    if (this.page.pageDetails.pageID == 211) {
      pageid = 226;
    } else if (this.page.pageDetails.pageID == 168) {
      pageid = 169;
    } else if (this.page.pageDetails.pageID == 160) {
      pageid = 166
    } else if (this.page.pageDetails.pageID == 259) {
      pageid = 224
    } else if (this.page.pageDetails.pageID == 57 && data.AccountName == 'Current Year Earnings') {
      pageid = 56
    }
    else if (this.page.pageDetails.pageID == 90) {
      let filterlist: any = [];
      if (fieldData.filterlist != "[]") {
        filterlist = JSON.parse(fieldData.filterlist);
        if (filterlist[0].field != 'account' && filterlist[0].field != 'accountCode' && filterlist[0].reportType == "RC") {
          fieldData.filterlist = [];
        }
        else {
          obj.filterlist = JSON.parse(fieldData.filterlist);
        }
      }
    }
    let menu = this.curentUser.menuList.filter(res => res.pageID == pageid);
    if (menu.length > 0) {
      if (this.page.pageDetails.pageID == 89 && data.SystemCode == '498') {
        pageid = 5004;
        obj.fromDate = fieldData.fromDate;
        obj.toDate = fieldData.toDate;
        obj.groupby = 'AccountName';
        obj.IsDetail = true;
        obj.CAAccountID = data.CAAccountID ? data.CAAccountID : data.caAccountID ? data.caAccountID : 0;
        let params = { pageID: pageid, filterData: JSON.stringify(obj) };
        this.params = params;
        this.router.navigate([menu[0].url],{ queryParams: params});
      }
      else if (column.field == 'linkDetailReport') {
        obj.fromDate = fieldData.fromDate;
        obj.toDate = fieldData.toDate;
      }
      else if (pageid == 226) {
        obj.fromDate = column.stdt;
        obj.toDate = column.eddt;
        obj.accounts = data.Accounts;
      } else if (pageid == 169) {
        data.contact = data.contact.replaceAll("'", "''");  //If the contact name having single quotes
        obj.params = {};
        obj.fromDate = fieldData.fromDate;
        obj.params.grouping = fieldData.grouping;
        obj.grouping = fieldData.grouping;
        obj.interval = fieldData.interval;
        obj.org = fieldData.org;
        obj.filterlist = JSON.parse(fieldData.filterlist);
        obj.contact = data?.contact;
        obj.contactGroup = data?.contactGroup;
        obj.reportTitle = 'Aged Receivables Detail';
        console.log(obj);
      } else if (pageid == 166) {
        data.contact = data.contact.replaceAll("'", "''"); //If the contact name having single quotes
        obj.params = {};
        obj.periods = {};
        obj.fromDate = fieldData.fromDate;
        obj.params.grouping = fieldData.grouping;
        obj.grouping = fieldData.grouping;
        obj.interval = fieldData.interval;
        obj.org = fieldData.org;
        let filterlist: any = [];
        if (fieldData.filterlist.length > 0) {
          filterlist = JSON.parse(fieldData.filterlist);
          if (filterlist[0].field == 'documentType') {
            obj.filterlist = [{ condition: "Is", datatype: "auto", field: "contact", fieldID: 0, filtertxt: "Contact Name Is " + "'" + data.contact + "'", reportType: "RC", text: "'" + data.contact + "'", title: "'" + data.contact + "'", "values": [{ "fieldName": "'" + data.contact + "'" }] }]
          }
          else {
            obj.filterlist = JSON.parse(fieldData.filterlist);
          }
        }
        obj.contact = data?.contact;
        obj.contactGroup = data?.contactGroup;
        obj.periods.ageType = fieldData.ageType;
        obj.periods.duration = fieldData.duration;
        obj.periods.interval = fieldData.interval;
      }
      else if (pageid == 224) {
        this.router.navigate(['/accounting/fixedassets/viewasset'], { queryParams: { id: btoa(data.INT.toString()) } });
        return;
      }
      else if (column.field == 'Debit' || column.field == 'Credit') {

        obj.fromDate = new Date(orgFinanceStart);
        obj.toDate = new Date(fieldData.fromDate);
        obj.filterlist = JSON.parse(fieldData.filterlist);
        obj.fromDate.setYear(obj.toDate.getFullYear());
        if (obj.fromDate > obj.toDate) {
          obj.fromDate.setYear(obj.toDate.getFullYear() - 1);
        }
      }
      else if (pageid == 89 && (column.field != 'Debit' || column.field != 'Credit') && fieldData != undefined && column.field.replace(/ /g, '').trim().length != 9 &&
        (this.page.pageDetails.pageID != 57 && this.page.pageDetails.pageID != 90) && this.page.pageDetails.pageID != 56) {
        if (this.page.pageDetails.pageID == 56 || this.page.pageDetails.pageID == 53) { // Form the P&L Report Date and Reports(Publish)

          obj.fromDate = new Date(fromDate);
          obj.toDate = new Date(toDate);
        }
        else {
          obj.fromDate = new Date(orgFinanceStart);
          obj.toDate = new Date(fieldData.fromDate);
        }
        if (typeof fieldData.customfields != 'undefined') {
          obj.customfields = fieldData.customfields.length > 0 ? JSON.parse(fieldData.customfields) : [];
        }
        obj.filterlist = JSON.parse(fieldData.filterlist);
        obj.fromDate.setYear(obj.toDate.getFullYear());
        if (obj.fromDate > obj.toDate) {
          obj.fromDate.setYear(obj.toDate.getFullYear() - 1);
        }
      }
      else {
        let dateField = column.field.replace(/ /g, '');
        if (dateField.trim() == 'YTD') {


          obj.toDate = new Date(toDate);
          let fromdatecal = new Date(obj.toDate.getFullYear(), orgFinanceStart.getMonth(), orgFinanceStart.getDate());
          if (fromdatecal > obj.toDate) {
            fromdatecal = new Date(obj.toDate.getFullYear() - 1, orgFinanceStart.getMonth(), orgFinanceStart.getDate());
          }
          obj.fromDate = fromdatecal;
        }

        if (dateField.trim().length == 7 && new Date(column.field).toString() != 'Invalid Date') {
          obj.fromDate = new Date(column.field);
          obj.toDate = new Date(obj.fromDate.getFullYear(), obj.fromDate.getMonth() + 1, 0);

        } else if (dateField.trim().length == 9 && new Date(dateField).toString() != 'Invalid Date') {
          obj.toDate = new Date(column.field);
          if (fromDate == undefined || fromDate == null) {
            obj.fromDate = new Date(obj.toDate.getFullYear(), orgFinanceStart.getMonth(), orgFinanceStart.getDate());
          }
          else {
            let todatemonth = obj.toDate.getMonth();
            let orgmonth = orgFinanceEND.getMonth();
            let todateyear = obj.toDate.getFullYear();
            let fromdateyear = fromDate.getFullYear();
            let yeardiff = todateyear - fromdateyear;
            if (todatemonth == 0 || todatemonth == 1 || todatemonth == 2 || todatemonth == 3 || todatemonth == 4 ||
              todatemonth == 5 || todatemonth == 6 || todatemonth == 7 || todatemonth == 8 || todatemonth == 9 ||
              todatemonth == 10) {
              if (obj.toDate.getMonth() < orgFinanceStart.getMonth() && obj.toDate.getFullYear() == orgFinanceStart.getFullYear()) {
                yeardiff = 1; // Same year and selected date is less than the org start date.
              }
              else if (obj.toDate.getDate() == orgFinanceEND.getDate() && obj.toDate.getMonth() == orgFinanceEND.getMonth()) {
                yeardiff = 1;//same date and same month
              }
              else if (obj.toDate.getDate() < orgFinanceStart.getDate() && obj.toDate.getMonth() == orgFinanceEND.getMonth()) {
                yeardiff = 1; // Same month and less than the org start date
              }
              else if (obj.toDate.getMonth() < orgFinanceStart.getMonth() && obj.toDate.getFullYear() == orgFinanceEND.getFullYear()) {
                yeardiff = 1; // Same year and selected date is less than the org start date.
              }
            }
            if (yeardiff < 0) {
              yeardiff = 0;
            }
            obj.fromDate = new Date(obj.toDate.getFullYear() - yeardiff, fromDate.getMonth(), fromDate.getDate());
          }
        } else if (dateField.trim().length == 4) {
          obj.toDate = new Date(column.field, orgFinanceEND.getMonth(), orgFinanceEND.getDate());
          if (orgFinanceEND.getMonth() == 11 && orgFinanceEND.getDate() == 31) {
            obj.fromDate = new Date(column.field, orgFinanceStart.getMonth(), orgFinanceStart.getDate());
          } else {
            obj.fromDate = new Date(obj.toDate.getFullYear() - 1, orgFinanceStart.getMonth(), orgFinanceStart.getDate());
          }
        }
        else {
          if (dateField.trim().length > 9) {
            let dateSplit = column.field.split('-');
            if (dateSplit.length == 2) {
              let monthEndDate: any = new Date(dateSplit[1]);
              if (dateSplit[1].replace(/ /g, '').trim().length == 9)
                obj.toDate = monthEndDate;
              else
                obj.toDate = new Date(monthEndDate.getFullYear(), monthEndDate.getMonth() + 1, 0);

              if (dateSplit[0].trim().length == 6) {
                obj.fromDate = new Date(dateSplit[0] + ' ' + obj.toDate.getFullYear());
              } else if (dateSplit[0].trim().length < 6) {
                obj.fromDate = new Date('01 ' + dateSplit[0].trim() + ' ' + obj.toDate.getFullYear());
              } else {
                obj.fromDate = new Date(dateSplit[0]);
              }
            }
          }
        }
      }

      if (!obj.fromDate) {
        if (fromDate) {
          obj.fromDate = new Date(fromDate);
        }
        else {
          obj.fromDate = new Date();
        }
      }
      if (!obj.toDate) {
        if (toDate) {
          obj.toDate = new Date(toDate);
        }
        else {
          obj.toDate = new Date();
        }
      }
      if (fieldData != undefined && (this.page.pageDetails.pageID == 56 || this.page.pageDetails.pageID == 57)) {
        if (this.page.pageDetails.pageID == 56) {
          if (fromDate) {
            obj.fromDate = new Date(fromDate);
          }
          else {
            obj.fromDate = new Date();
          }
          if (toDate) {
            obj.toDate = new Date(toDate);
          }
          else {
            obj.toDate = new Date();
          }
          if (typeof fieldData.customfields != 'undefined') {
            obj.customfields = fieldData.customfields.length > 0 ? JSON.parse(fieldData.customfields) : [];
          }
        }
        obj.filterlist = JSON.parse(fieldData.filterlist);

      }

      obj.CAAccountID = data.CAAccountID ? data.CAAccountID : data.caAccountID ? data.caAccountID : 0;
      let params = { pageID: pageid, filterData: JSON.stringify(obj) };
      this.params = params;
      this.router.navigate([menu[0].url]);
    }

  }

  navigatToURL(URL: string) {
    this.router.navigate([URL])
  }

  navigatequeryParams(data, column, fieldData?: any, fromDate?: any, toDate?: any) {
    let obj: any = {};
    let orgDetails = this.curentUser["organizations"].filter((currentorg) => currentorg.orgID == this.curentUser.orgID)[0];
    let orgFinanceStart = new Date(orgDetails.orgFinanceStart);
    let orgFinanceEND = new Date(orgDetails.orgFinanceEND);
    let pageid = 89;
    if (this.page.pageDetails.pageID == 211) {
      pageid = 226;
    } else if (this.page.pageDetails.pageID == 168) {
      pageid = 169;
    } else if (this.page.pageDetails.pageID == 160) {
      pageid = 166;
    } else if (this.page.pageDetails.pageID == 259 || this.page.pageDetails.pageID == 84) {
      pageid = 224;
    } else if (this.page.pageDetails.pageID == 5003) {
      pageid = 5004;
    }

    let params: any = '';
    let menu = this.curentUser.menuList.filter(res => res.pageID == pageid);
    if (menu.length > 0) {
      if (this.page.pageDetails.pageID == 89 && data.SystemCode == '498') {
        pageid = 5004;
        obj.fromDate = fieldData.fromDate;
        obj.toDate = fieldData.toDate;
        obj.groupby = 'AccountName';
        obj.IsDetail = true;
        obj.CAAccountID = data.CAAccountID ? data.CAAccountID : data.caAccountID ? data.caAccountID : 0;
        params = { pageID: btoa(pageid.toString()), filterData: btoa(JSON.stringify(obj)) };
      }
      else if (pageid == 224) {
        params = { id: btoa(data.INT.toString()) };
      }
      else if ((data.linkDetail && data.linkDetail != '-') || (data.LinkDetail && data.LinkDetail != '-')) {
        let jsonObject = JSON.parse(data.linkDetail || data.LinkDetail);
        switch (jsonObject.TransTypeId) {
          case 2: { // Need to commet the transactions Type
            let billtransID = jsonObject.TransId + "," + jsonObject.TransTypeId + "," + jsonObject.ContactOrStatusId;
            params = { id: btoa(billtransID) };
            break;
          }
          case 3: {//sales/creditnotes/creditnoteview
            let billtransID = jsonObject.TransId + "," + jsonObject.TransTypeId;
            params = { id: btoa(billtransID) };
            break;
          }
          case 4: {//purchase/creditnotes/creditnoteview
            let billtransID = jsonObject.TransId + "," + jsonObject.TransTypeId
            params = { id: btoa(billtransID) };
            break;
          }
          case 6: { //sales/invoice/viewinvoice
            let InvoicetransID = jsonObject.TransId + "," + jsonObject.TransTypeId + "," + jsonObject.ContactOrStatusId;
            params = { id: btoa(InvoicetransID) };
            break;
          }
          case 8: { //sales/creditnotes/creditnoteview
            let billtransID = jsonObject.TransId + "," + jsonObject.TransTypeId;
            params = { id: btoa(billtransID) };
            break;
          }
          case 9: { ///transactions/viewtransaction
            params = { account: btoa(jsonObject.AccountId), tranid: btoa(jsonObject.TransId), trantypeid: btoa(jsonObject.TransTypeId) }
            break;
          }
          case 10: { ///transactions/viewtransaction
            params = { account: btoa(jsonObject.AccountId), tranid: btoa(jsonObject.TransId), trantypeid: btoa(jsonObject.TransTypeId) }
            break;
          }
          case 11: { ///transactions/viewtransaction
            params = { account: btoa(jsonObject.AccountId), tranid: btoa(jsonObject.TransId), trantypeid: btoa(jsonObject.TransTypeId) }
            break;
          }
          case 20: { //purchase/viewreceive
            let ReceiveID = `${jsonObject.ROCopySourceID},${jsonObject.TransId},${jsonObject.Status},${jsonObject.ROCopySourceTypeID}`;
            params = { id: btoa(ReceiveID) };
            break;
          }
          case 25: { ///transactions/viewtransaction
            params = { account: btoa(jsonObject.AccountId), tranid: btoa(jsonObject.TransId), trantypeid: btoa(jsonObject.TransTypeId) }
            break;
          }
          case 12: { ///inventory/newitem
            let itemId = jsonObject.TransId;
            params = { id: btoa(itemId) };
            break;
          }
          case 21: { ///sales/delivery/deliveryview
            let DOID = jsonObject.TransId;
            params = { id: btoa(DOID) };
            break;
          }
          case 14: { //accounting/journalentries/viewjournalentries
            params = { id: btoa(jsonObject.TransId) };
            break;
          }
          case 101: { // bankaccounts/accounttransactions
            params = { id: btoa(jsonObject.AccountId) };
            break;
          }
          case 30: { //sales/debitnote/viewdebitnote
            let DebitNoteID = jsonObject.TransId + "," + jsonObject.TransTypeId + "," + jsonObject.ContactOrStatusId;
            params = { id: btoa(DebitNoteID) };
            break;
          }
        }
      } else {
        if (column.field == 'linkDetailReport') {
          obj.fromDate = fieldData.fromDate;
          obj.toDate = fieldData.toDate;
        }
        else if (pageid == 5004) {
          obj.fromDate = fieldData.fromDate;
          obj.toDate = fieldData.toDate;
          obj.groupby = fieldData.groupby;
          obj.IsDetail = true;
          if (data.IsBank == true) {
            let filterlist = [
              {
                title: "AccountName",
                reportType: "RC",
                fieldID: 0,
                field: "AccountName",
                condition: "Is",
                text: "'" + (fieldData.groupby == "AccountName" ? data.CurrencyCode : data.AccountName) + "'",
                datatype: "auto",
                values: [
                  { fieldName: (fieldData.groupby == "AccountName" ? data.CurrencyCode : data.AccountName) }
                ],
                filtertxt: "AccountName Is '" + (fieldData.groupby == "AccountName" ? data.CurrencyCode : data.AccountName) + "'"
              }
            ]
            obj.filterlist = filterlist;
          }
          else {
            let filterlist = [
              {
                title: "AccountName",
                reportType: "RC",
                fieldID: 0,
                field: "AccountName",
                condition: "Is",
                text: "'" + data.AccountName + "'",
                datatype: "auto",
                values: [
                  { fieldName: data.AccountName }
                ],
                filtertxt: "AccountName Is '" + data.AccountName + "'"
              },
              {
                title: "CurrencyCode",
                reportType: "RC",
                fieldID: 0,
                field: "CurrencyCode",
                condition: "Is",
                text: "'" + data.CurrencyCode + "'",
                datatype: "auto",
                values: [
                  { fieldName: data.CurrencyCode }
                ],
                filtertxt: "CurrencyCode Is '" + data.CurrencyCode + "'"
              }
            ]
            obj.filterlist = filterlist;
          }
        }
        else if (pageid == 226) {
          obj.fromDate = column.stdt;
          obj.toDate = column.eddt;
          obj.accounts = data.Accounts;
        } else if (pageid == 169) {
          obj.fromDate = column.stdt;
          obj.toDate = column.eddt;
          obj.accounts = data.Accounts;
        } else if (pageid == 166) {
          obj.fromDate = column.stdt;
          obj.toDate = column.eddt;
          obj.accounts = data.Accounts;
        } else if (column.field == 'Debit' || column.field == 'Credit') {
          obj.fromDate = new Date(orgFinanceStart);
          obj.toDate = new Date(fieldData.fromDate);
          obj.fromDate.setYear(obj.toDate.getFullYear());
          if (obj.fromDate > obj.toDate) {
            obj.fromDate.setYear(obj.toDate.getFullYear() - 1);
          }
        }
        else {
          let dateField = column.field.replace(/ /g, '');
          if (dateField.trim().length == 7 && new Date(column.field).toString() != 'Invalid Date') {
            obj.fromDate = new Date(column.field);
            obj.toDate = new Date(obj.fromDate.getFullYear(), obj.fromDate.getMonth() + 1, 0);

          } else if (dateField.trim().length == 9 && new Date(dateField).toString() != 'Invalid Date') {
            obj.toDate = new Date(column.field);
            obj.fromDate = new Date(obj.toDate.getFullYear(), orgFinanceStart.getMonth(), orgFinanceStart.getDate());
          } else if (dateField.trim().length == 4) {
            obj.toDate = new Date(column.field, orgFinanceEND.getMonth(), orgFinanceEND.getDate());
            if (orgFinanceEND.getMonth() == 11 && orgFinanceEND.getDate() == 31) {
              obj.fromDate = new Date(column.field, orgFinanceStart.getMonth(), orgFinanceStart.getDate());
            } else {
              obj.fromDate = new Date(obj.toDate.getFullYear() - 1, orgFinanceStart.getMonth(), orgFinanceStart.getDate());
            }
          }
          else {
            if (dateField.trim().length > 9) {
              let dateSplit = column.field.split('-');
              if (dateSplit.length == 2) {
                obj.toDate = new Date(dateSplit[1]);
                if (dateSplit[0].trim().length == 6) {
                  obj.fromDate = new Date(dateSplit[0] + ' ' + obj.toDate.getFullYear());
                } else if (dateSplit[0].trim().length < 6) {
                  obj.fromDate = new Date('01 ' + dateSplit[0].trim() + ' ' + obj.toDate.getFullYear());
                } else {
                  obj.fromDate = new Date(dateSplit[0]);
                }
              }
            }
          }
        }

        if (!obj.fromDate) {
          if (fromDate) {
            obj.fromDate = new Date(fromDate);
          }
          else {
            obj.fromDate = new Date();
          }
        }
        if (!obj.toDate) {
          if (toDate) {
            obj.toDate = new Date(toDate);
          }
          else {
            obj.toDate = new Date();
          }
        }

        obj.CAAccountID = data.CAAccountID ? data.CAAccountID : data.caAccountID ? data.caAccountID : 0;
        params = { pageID: btoa(pageid.toString()), filterData: btoa(JSON.stringify(obj)) };
      }
    }
    return params;
  }

  navigaterouterLink(data, column, fieldData?: any) {
    if ((data.linkDetail && data.linkDetail != '-') || (data.LinkDetail && data.LinkDetail != '-')) {
      let linkData = JSON.parse(data.linkDetail || data.LinkDetail);
      if (linkData && linkData.RedirectUrl) {
        let relativeUrl = linkData.RedirectUrl;
        let fullUrl = this.router.serializeUrl(this.router.createUrlTree([relativeUrl]));
        return fullUrl;
      }
    }
    let pageid = 89;
    if (this.page.pageDetails.pageID == 211) {
      pageid = 226;
    } else if (this.page.pageDetails.pageID == 168) {
      pageid = 169;
    } else if (this.page.pageDetails.pageID == 160) {
      pageid = 166;
    } else if (this.page.pageDetails.pageID == 259 || this.page.pageDetails.pageID == 84) {
      pageid = 224;
    } else if (this.page.pageDetails.pageID == 5003) {
      pageid = 5004;
    }
    let url = '';
    let menu = this.curentUser.menuList.filter(res => res.pageID == pageid);
    if (menu.length > 0) {
      url = menu[0].url;
    }
    return url;
  }

}
