<div bsModal #showmodal="bs-modal" class="modal fade" [config]="{ backdrop: 'static' }" tabindex="-1" role="dialog"
    aria-labelledby="showmodal" aria-hidden="true">
    <div class="modal-dialog modal-xl" role="document">

        <form class="form-horizontal" [formGroup]="emailtemplateForm" novalidate="novalidate" (ngSubmit)="onSubmit()">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">{{sendTitle}}</h4>
                    <button type="button" class="close" (click)="CloseModel()">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="col-md-12">
                        <div class="">
                            <quill-editor formControlName="body" class="w-100" [preserveWhitespace]="true">
                                <div quill-editor-toolbar>
                                    <span class="ql-formats">
                                        <select class="ql-font">
                                            <option selected></option>
                                            <option value="serif"></option>
                                            <option value="monospace"></option>
                                        </select>                                  
                                    </span>
                                    <span class="ql-formats">
                                        <select class="ql-size">
                                            <option value="6pt">6pt</option>
                                            <option value="8pt" selected>8pt</option>
                                            <option value="9pt">9pt</option>
                                            <option value="10pt">10pt</option>
                                            <option value="11pt">11pt</option>
                                            <option value="12pt">12pt</option>
                                            <option value="13pt">13pt</option>
                                            <option value="14pt">14pt</option>
                                            <option value="16pt">16pt</option>
                                            <option value="18pt">18pt</option>
                                            <option value="24pt">24pt</option>
                                            <option value="30pt">30pt</option>
                                            <option value="36pt">36pt</option>
                                            <option value="48pt">48pt</option>
                                        </select>
                                      </span>  
                                    <span class="ql-formats">
                                        <button class="ql-bold"></button>
                                        <button class="ql-italic"></button>
                                        <button class="ql-underline"></button>
                                        <button class="ql-strike"></button>
                                    </span>
                                    <span class="ql-formats">
                                        <select class="ql-color"></select>
                                        <select class="ql-background"></select>
                                    </span>
                                    <span class="ql-formats">
                                        <button class="ql-list" value="ordered"></button>
                                        <button class="ql-list" value="bullet"></button>
                                        <select class="ql-align">
                                            <option selected></option>
                                            <option value="center"></option>
                                            <option value="right"></option>
                                            <option value="justify"></option>
                                        </select>
                                    </span>
                                    <span class="ql-formats">
                                        <button class="ql-link"></button>
                                        <button class="ql-image"></button>
                                    </span>
                                    <span class="ql-formats">

                                        <div class="dropdown" dropdown>
                                            <button class="dropdown-toggle" id="dropdownMenu4" type="button"
                                                dropdownToggle aria-haspopup="true" aria-expanded="false">Insert
                                                Placeholders</button>
                                            <div *dropdownMenu class="dropdown-menu dropdown-menu-right ddm-placeholder-size"
                                                style="margin: 0px;">
                                                <div class="d-flex">
                                                    <div *ngFor="let phdetail of phdetails;let idx=index">
                                                        <h6 class="dropdown-header text-muted h6"
                                                            *ngIf="phdetail.header">
                                                            {{phdetail.field}}</h6>
                                                        <div *ngFor="let subMenulist of phdetail.submenu;">
                                                            <a class="dropdown-item ddi-placeholder-size"
                                                                id="ddmInsertPlaceholders_subMenulist.code"
                                                                (click)="changeCustom($event,subMenulist.code)">{{subMenulist.field}}</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </span>
                                </div>
                            </quill-editor>
                        </div>
                    </div>
                </div>

                <div class="col-md-12" *ngIf="submitted && text==null">
                    <div class="form-group row" style="text-align: center;">
                        <small class="col-md-11 col-form-label" style="color: #f86c6b;font-size:80%"> <i
                                class="cil-warning"></i> Please provide a
                            Template Content </small>
                    </div>
                    <div class="form-group row" *ngIf="Placeholder!=''">
                        <small class="col-md-11 col-form-label" style="color: #f86c6b;font-size:80%"> <span
                                [innerHTML]="Placeholder"></span> </small>
                    </div>
                </div>
                <div class="modal-footer">
                    <button [disabled]="loading" class="btn btn-info">
                        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                        Save
                    </button>
                    <button class="btn btn-danger" type="button" (click)="CloseModel()">Cancel</button>
                </div>
            </div>
        </form>
    </div>
</div>