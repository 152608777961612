<div bsModal #showDeliverymodal="bs-modal" class="modal fade" tabindex="-1" role="dialog"  [ngStyle]="{'display':showDeliverymodal}"
    aria-labelledby="showDeliverymodalLabel" aria-hidden="true" [config]="{ backdrop: 'static' }">
  <div class="modal-dialog  modal-dialog-scrollable" role="document">
    <form class="form-horizontal needs-validation" novalidate="novalidate" [formGroup]="deliveryAddressForm" >   
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Delivery Address</h4>
          <button type="button" class="close " (click)="hidedeliverymodal()" >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row pb-2">
            <div class="col-md-12">

              <div class="form-group row">
                <div class="col-md-3 pr-0">
                  <label class="form-col-form-label">Address Label</label>
                </div>
                <div class="col-md-8 pr-0">
                  <input class="form-control" formControlName="addressLabel" placeholder="e.g. Depost, Office... "
                    id="text-input" type="text" [ngClass]="{ 'is-invalid': submitted && f.addressLabel.errors && isLabel}"
                    xssDirective>
                  <div *ngIf="submitted && f.addressLabel.errors" class="invalid-feedback">
                    <div *ngIf="f.addressLabel.errors.required">Address label cannot be empty</div>
                  </div>
                  <div class="formError" *ngIf="alreadyAddressLableExist">
                    <span style="color: red; font-size: 12px;">Address Label already exists in our database</span>
                  </div>
                </div>
              </div>

              <div class="form-group row">
                <div class="col-md-3 pr-0">
                  <label class="form-col-form-label">Address </label>
                </div>
                <div class="col-md-8 pr-0">
                  <input class="form-control" formControlName="addressAttention" placeholder="Attention" id="text-input"
                    type="text" xssDirective>
                    <ng-select   [items]="addressdetails"  *ngIf="currentUser.orgCountryID == 196"  [searchFn]="AddressSearchFn"  [clearable]="true" formControlName="zipCode"  (search)="getaddressdetails($event)"                          
                    placeholder="Postal / Zip Code" bindLabel="postal" bindValue="postal" (clear)="onClear()" >
                    <ng-template ng-option-tmp let-item="item"  let-index="index">
                    <div (click)="onSelectAddress(item)">
                      {{item.address}}
                    </div>
                  </ng-template>
              </ng-select> 
              <input class="form-control"  *ngIf="currentUser.orgCountryID != 196" formControlName="zipCode" placeholder="Postal / Zip Code" id="text-input"
                    type="text" xssDirective>
                  <textarea id="textarea-notes" formControlName="streetAddress" [maxLength]="textareaLength" rows="4" maxlength="300"
                    class="form-control mt-2" placeholder="Street Addreess or PO box" xssDirective></textarea>
                  <input class="form-control" formControlName="city" placeholder="Town / City" id="text-input"
                    type="text" xssDirective>
                </div>

              </div>

              <div class="form-group row">
                <div class="col-md-3 pr-0">
                  <label class="form-col-form-label"> </label>
                </div>
                <div class="col-md-8 pr-0">
                  <input class="form-control" formControlName="region" placeholder="State / Region" id="text-input"
                    type="text" xssDirective>
                </div>            
              </div>

              <div class="form-group row">
                <div class="col-md-3 pr-0">
                  <label class="form-col-form-label"> </label>
                </div>
                <div class="col-md-8 pr-0">
                  <ng-select [multiple]="false" formControlName="fcCountry" [items]="lstCountries"
                    placeholder="Select Country" bindValue="countryID" bindLabel="countryName"></ng-select>
                </div>

              </div>

              <div class="form-group row">
                <div class="col-md-3 pr-0">
                  <label class="form-col-form-label"> Telephone</label>
                </div>
                <div class="col-md-2 pr-0">
                  <input class="form-control" formControlName="telePhoneCountryCode" placeholder="Country.."
                    id="text-input" type="text" maxlength="5" phonecodenumber xssDirective>
                </div>

                <div class="col-md-3 pr-0">
                  <input class="form-control" formControlName="areaCode" placeholder="Area.." id="text-input"
                    type="text" maxlength="5" phonecodenumber xssDirective>
                </div>

                <div class="col-md-3 pr-0">
                  <input class="form-control" formControlName="telePhone" placeholder="Number.." id="text-input"
                    type="text" maxlength="15" phonenumber xssDirective>
                </div>

              </div>

              <div class="form-group row">
                <div class="col-md-3 pr-0">
                  <label class="form-col-form-label">Instruction</label>
                </div>
                <div class="col-md-8 pr-0">
                  <textarea class="form-control" formControlName="instruction" id="textarea-input" rows="4"
                    placeholder="Instruction.." xssDirective></textarea>
                </div>
              </div>

              <div class="form-group row">
                <label class="col-md-3 pr-0" for="primaryAddress">
                </label>
                <div class="col-md-8 col-form-label">
                  <div class="custom-control-mid custom-checkbox">
                    <input type="checkbox" class="custom-control-input" id="chkPrimary"
                      formControlName="isPrimaryAddress">
                    <label class="custom-control-label" for="chkPrimary">Set for Primary</label>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button [disabled]="loadingDelivery" class="btn btn-primary btn-normal mr-3" *userCanEdit="[true]" (click)="onSubmitDeliveryAddress()">
            <span *ngIf="loadingDelivery" class="spinner-border spinner-border-sm mr-1"></span>
            Save
          </button>
          <button class="btn btn-light" type="button" (click)="hidedeliverymodal()">Cancel</button>
        </div>
      </div>   
    </form>
    <!-- /.modal-content-->
  </div>
  <!-- /.modal-dialog-->
</div>
