import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { environment } from '../../../../environments/environment';
import * as moment from 'moment';
import { AllCommunityModules, ColDef, GridOptions, Module } from '@ag-grid-community/all-modules';
import { DatePipe } from '@angular/common';
import { HttpServices } from '../../../utilities/_services/http.service';
import { AlertMessage } from '../../../utilities/_helpers/alert.message';
import { ModalDirective } from 'ngx-bootstrap/modal';
import Swal from 'sweetalert2';
import { MasterServices } from '../../../utilities/_services/master.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { LocalCacheServices } from '../../../utilities/_services/acclocalcache.service';
import { AgdatepickerComponent } from '../gridComponent/agdatepicker.component';
import { WarehouselistComponent } from '../warehouselist/warehouselist.component';
import { AlphanumericEditor } from '../gridComponent/alphanumeric-editor.component';
import { TranslateService } from '@ngx-translate/core';

type SerialDetail = {
  SerialID: any;
  SerialNo: any;
  ItemID: number;
  ManufacturedDate: string;
  ExpiryDate: string;
  Quantity: number;
  IsLocked: any;
  TransactionTypeId: number; //Receive Order,
  ROTransDetailId: number;
  WarehouseID: any;
}[];

@Component({
  selector: 'app-createserial',
  templateUrl: './createserial.component.html',
  styleUrls: ['./createserial.component.scss']
})
export class CreateserialComponent implements OnInit {
  @ViewChild('modalCreateSerial')
  public modalCreateSerial: ModalDirective;
  @Output() emitOnSubmitValue = new EventEmitter<any>();
  @Output() emitAfterBatchorSerialSaved = new EventEmitter<any>();
  trackingMethod: string = '';
  serialItemDetails: any = [];
  // serialMasterGridOptions: GridOptions;
  serialDetailGridOptions: GridOptions;
  modules: Module[] = AllCommunityModules;
  // serialMasterColumnDefs: ColDef[] = [];
  serialDetailColumnDefs: ColDef[] = [];
  // serialMasterRowData: any[] = [];
  serialDetailRowData: any[] = [];
  _requiredQuantity: number = 0;
  _POQuantity: number = 0;
  paginationPageSize: any;
  serialMasterframeworkComponents = {
    WarehouseComp: WarehouselistComponent,
    alphanumeric: AlphanumericEditor,
    datePicker: AgdatepickerComponent,
  }
  itemID: number = 0;
  _mode: string = 'View';
  _transactionTypeId: number = 0;
  _transactionDetailId: number = 0;
  _quantityLabel: string = 'Invoice Order';
  _requiredorReceivedQtyLabel: string = 'Required Quantity';
  _transactionType: any = [
    { transactionTypeId: 20, transactionTypeName: 'Receive Order' },
    { transactionTypeId: 2, transactionTypeName: 'Bills' },
    { transactionTypeId: 8, transactionTypeName: 'Sales Credit Notes' },
    { transactionTypeId: 4, transactionTypeName: 'Purchase Credit Notes' },
    { transactionTypeId: 31, transactionTypeName: 'Purchase Debit Note' }
  ];
  lstPagination: any = [];
  settingsForm: FormGroup;
  serialDetailForm: FormGroup;
  submitted: boolean = false;
  qtyCount: number = 0;
  serialID: number = 0;
  selectedRowCount: number = 0;
  totalGridRows: number = 0;
  updated: boolean = false;
  selectedRows: any;
  searchText: string;
  unAssignedQty: number = 0;
  assignedQty: number = 0;
  defaultWareHouse: number = 0;
  isEnabledActionButtons: boolean = true;
  minDate: { year: number; month: number; day: number };
  maxDate: { year: number; month: number; day: number };
  orgSettings: any;
  isWarehouseEnabled: any
  lstWarehouse: any = [];
  tracktype:string='';
  agGridHearderColumnDefTemplateString: string = '<div class="ag-cell-label-container" role="presentation">' +
    '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
    '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
    '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
    '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
    '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
    '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
    '    <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>' +
    '    &nbsp <span class="text-danger"> * </span>' +
    '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
    '  </div>' +
    '</div>';

  constructor(private http: HttpServices,
    private datepipe: DatePipe,
    private master: MasterServices,
    private fb: FormBuilder,
    private local: LocalCacheServices,
    private alertMessage: AlertMessage,
    private translate: TranslateService) {
    this.paginationPageSize = this.master.paginationPageSize;
    this.lstPagination = this.master.getPagination();
    this.translate.onDefaultLangChange.subscribe(async() => {
      this.serialDetailGridOptions.api.refreshHeader();
      this.trackingMethod = this.translate.instant("InventoryModule.Item.Serial Number");
    });
    this.translate.onLangChange.subscribe(async() => {
      this.serialDetailGridOptions.api.refreshHeader();
      this.trackingMethod = this.translate.instant("InventoryModule.Item.Serial Number");
    });
    this.trackingMethod = this.translate.instant("InventoryModule.Item.Serial Number");
    this.initFormGroup();
  }

  // initSerialMasterAgGrid() {
  //   this.serialMasterGridOptions = <GridOptions>{
  //     domLayout: "autoHeight",
  //     sortable: false,
  //     filter: false,
  //     wrapText: true,
  //     autoHeight: true,
  //     stopEditingWhenCellsLoseFocus: true,
  //     singleClickEdit: true,
  //     onGridReady: (params: any) => {
  //       this.serialMasterGridOptions.api.sizeColumnsToFit();
  //       // const allColumnIds: string[] = [];
  //       // this.serialMasterGridOptions.columnApi!.getAllColumns()!.forEach((column) => {
  //       //   allColumnIds.push(column.getId());
  //       // });
  //       // this.serialMasterGridOptions.columnApi!.autoSizeColumns(allColumnIds, false);
  //     },
  //     onGridSizeChanged: () => {
  //       this.serialMasterGridOptions.api.sizeColumnsToFit();
  //     },
  //   }
  //   this.serialMasterColumnDefs = [
  //     {
  //       headerName: "Starting S/No.",
  //       field: "FSN",
  //       wrapText: true,
  //       editable: true,
  //       cellEditor: 'alphanumeric',
  //       // width: 300,
  //       // cellRenderer: (params: any) => {
  //       //   return params.data.FSN;
  //       // },
  //     },
  //     {

  //       headerName: "Ending S/No.",
  //       field: "TSN",
  //       unSortIcon: true,
  //       editable: true,
  //       // width: 300,
  //       cellEditor: 'alphanumeric',
  //       // cellRenderer: (params: any) => {
  //       //   return params.data.TSN;
  //       // }
  //     },
  //     {
  //       headerName: "Manufactured Date",
  //       field: "manufacturedDate",
  //       wrapText: true,
  //       editable: true,
  //       cellEditor: 'datePicker',
  //       // width: 250,
  //       valueFormatter: this.dateFormatter,
  //     },
  //     {
  //       headerName: "Expiry Date",
  //       field: "expiryDate",
  //       wrapText: true,
  //       editable: true,
  //       cellEditor: 'datePicker',
  //       // width: 250,
  //       valueFormatter: this.dateFormatter,
  //     },
  //     {
  //       headerName: "Qty",
  //       field: "quantity",
  //       wrapText: true,
  //       editable: false,
  //       // width: 100,
  //     },
  //     {
  //       headerName: "",
  //       field: "Delete",
  //       editable: false,
  //       // width: 50,
  //       cellRenderer: (params) => {
  //         return '<i class="fa fa-trash" data-action="delete"></i>';
  //       },
  //     },
  //   ]
  // }

  dateFilterParams = {
    comparator: function (filterLocalDateAtMidnight, cellValue) {
      var dateAsString = cellValue;
      if (dateAsString == null) return -1;
      var date = new Date(dateAsString.substring(0, 10));
      var cellDate = new Date(date.getFullYear(), date.getMonth(), date.getDate());
      if (cellDate < filterLocalDateAtMidnight) {
        return -1;
      } else if (cellDate > filterLocalDateAtMidnight) {
        return 1;
      } else {
        return 0;
      }
    },
    browserDatePicker: true,
    minValidYear: 2000,
    closeOnApply: true,
    inRangeInclusive: true,
    suppressAndOrCondition: true,
    buttons: ['reset', 'apply']
  };

  initSerialDetailAgGrid() {
    this.serialDetailGridOptions = <GridOptions>{
      domLayout: "autoHeight",
      sortable: false,
      filter: false,
      wrapText: true,
      autoHeight: true,
      pagination: true,
      rowHeight: 40,
      onGridReady: (params: any) => {
        this.serialDetailGridOptions.api.sizeColumnsToFit();
      },
      onGridSizeChanged: () => {
        this.serialDetailGridOptions.api.sizeColumnsToFit();
      },
    }
    this.serialDetailColumnDefs = [     
      {
        headerName: "Warehouse",
        field: "warehouseID",
        sortable: true,
        filter: true,
        wrapText: true,
        editable: true,
        autoHeight: true,
        cellEditor: 'WarehouseComp',
        hide: !this.isWarehouseEnabled,
        cellEditorParams: {
          lstWarehouse: []
        },
        cellStyle: {
          'overflow-y': 'auto!important',
          'overflow': 'visible'
        },
        headerValueGetter:(params)=>this.translate.instant("InventoryModule.Item.Warehouse"),
        cellRenderer: (params: any) => {
          return this.lookupValue(this.lstWarehouse, params);
        },
        headerComponentParams: {
          template: this.agGridHearderColumnDefTemplateString
        }
      },
      {
        headerName: "Serial No.",
        field: "serialNo",
        wrapText: true,
        autoHeight: true,
        filter: true,
        sortable: true,
        editable: true,
        cellEditor: 'alphanumeric',
        headerValueGetter:(params)=>this.translate.instant("InventoryModule.Item.Serial No"),
        cellRenderer: (params) => {
          return '<a class="t-link">' + params.data.serialNo + '</a>';
        }, headerComponentParams: {
          template: this.agGridHearderColumnDefTemplateString
        }
      },
      {
        headerName: "Manufactured Date",
        field: "manufacturedDate",
        unSortIcon: true,
        editable: true,
        cellEditor: 'datePicker',
        autoHeight: true,
        sortable: true,
        filter: "agDateColumnFilter",
        filterParams: this.dateFilterParams,
        valueFormatter: this.dateFormatter,
        headerValueGetter:(params)=>this.translate.instant("InventoryModule.Item.Manufactured Date")
      },
      {
        headerName: "Expiry Date",
        field: "expiryDate",
        wrapText: true,
        editable: true,
        cellEditor: 'datePicker',
        autoHeight: true,
        sortable: true,
        filter: "agDateColumnFilter",
        filterParams: this.dateFilterParams,
        valueFormatter: this.dateFormatter,
        headerValueGetter:(params)=>this.translate.instant("InventoryModule.Item.Expiry Date")
      },
      {
        headerName: "Qty",
        field: "quantity",
        wrapText: true,
        editable: false,
        autoHeight: true,
        filter: true,
        sortable: true,
        headerComponentParams: {
          template: this.agGridHearderColumnDefTemplateString
        },
        headerValueGetter:(params)=>this.translate.instant("Common.Qty")
      },
      {
        headerName: '',
        field: 'Delete',
        width: 50,
        maxWidth: 50,
        cellStyle: { textAlign: 'center' },       
        cellRenderer: (params) => {
          if (params.node.data.isLocked) {
            return `<div  tabindex="-1" unselectable="on" role="gridcell" aria-colindex="1"  class="ag-cell ag-cell-not-inline-editing ag-cell-auto-height" style="left: 0px;bottom:0px; pointer-events: none; opacity: 0.4;">
            <span class="c-icon material-icons-outlined m-0" style="font-size:20px">lock</span>
          </div>`;
          } else {
            return '<span class="close-x" data-action="delete">x</span>';
          }
        }
      },
    ];
  }

  ngOnInit(): void {
    // this.initSerialMasterAgGrid();
    this.orgSettings = JSON.parse(this.local.getlocalStorage(this.local.localCurrentOrganization));
    this.isWarehouseEnabled = this.orgSettings.enableWarehouse;
    this.initSerialDetailAgGrid();
  }

  get f() {
    return this.settingsForm.controls;
  }

  dateFormatter(params) {
    return params.value == "" ? params.value : (moment(params.value)).format('DD/MM/YYYY');
  }

  onQuickFilterChanged() {
    this.serialDetailGridOptions.api.setQuickFilter(this.searchText);
    this.totalGridRows = this.serialDetailGridOptions.api.getDisplayedRowCount();
    if (this.totalGridRows == 0) {
      this.serialDetailGridOptions.api.showNoRowsOverlay();
    }
    else {
      this.serialDetailGridOptions.api.hideOverlay();
    }
  }

  onDelete() {
    if (this._transactionTypeId == 20) { //Receive Order
      let assignedQty = this.assignedQty;
      let selectedRows: any[] = this.serialDetailGridOptions.api.getSelectedRows();
      selectedRows.filter(x => x.isLocked == false).forEach(row => {
        let index = this.serialDetailRowData.findIndex(x => x.serialNo == row.serialNo);
        this.serialDetailRowData.splice(index, 1);
        this.assignedQty -= 1;
        if (assignedQty <= this._requiredQuantity)
          this.unAssignedQty += 1;
      });
      this.serialDetailGridOptions.api.setRowData(this.serialDetailRowData);
      this.selectedRowCount = 0;
    }
    else if (this._transactionTypeId == 2) {//Bill
      let assignedQty = this.assignedQty;
      let selectedRows: any[] = this.serialDetailGridOptions.api.getSelectedRows();
      selectedRows.filter(x => x.isLocked == false).forEach(row => {
        let index = this.serialDetailRowData.findIndex(x => x.serialNo == row.serialNo);
        this.serialDetailRowData.splice(index, 1);
        this.assignedQty -= 1;
        if (assignedQty <= this._requiredQuantity)
          this.unAssignedQty += 1;
      });
      this.serialDetailGridOptions.api.setRowData(this.serialDetailRowData);
      this.selectedRowCount = 0;
    }
    else if (this._transactionTypeId == 31) {//Purchase Debit Note
      let assignedQty = this.assignedQty;
      let selectedRows: any[] = this.serialDetailGridOptions.api.getSelectedRows();
      selectedRows.filter(x => x.isLocked == false).forEach(row => {
        let index = this.serialDetailRowData.findIndex(x => x.serialNo == row.serialNo);
        this.serialDetailRowData.splice(index, 1);
        this.assignedQty -= 1;
        if (assignedQty <= this._requiredQuantity)
          this.unAssignedQty += 1;
      });
      this.serialDetailGridOptions.api.setRowData(this.serialDetailRowData);
      this.selectedRowCount = 0;
    }
  }

  onCellClicked(params: any) {
    const colId = params.column.getId();
    if (colId === "Delete") {
      let serialID = params.data.serialID;
      let serialNo = params.data.serialNo;
      if (!params.data.isLocked) {
        if (this._transactionTypeId == 2) {
          // if (serialID > 0) {//RO did not have a DB delete
          //   Swal.fire({
          //     title: 'Are you sure want to delete!',
          //     icon: 'question',
          //     showCancelButton: true,
          //     showConfirmButton: true,
          //     confirmButtonText: 'Delete',
          //     allowOutsideClick: false
          //   }).then((result) => {
          //     if (result.value) {
          //       this.onDeleteBillSerialItemDetails(serialID);
          //     }
          //   });
          // }
          // else {
          let index = this.serialDetailRowData.findIndex(x => x.serialNo == serialNo);
          this.serialDetailRowData.splice(index, 1);
          this.assignedQty -= 1;
          if (this.assignedQty <= this._requiredQuantity)
            this.unAssignedQty += 1;
          this.serialDetailGridOptions.api.setRowData(this.serialDetailRowData);
          this.selectedRowCount = 0;

        }
        else if (this._transactionTypeId == 8) {
          // if (serialID > 0) {//RO did not have a DB delete
          //   Swal.fire({
          //     title: 'Are you sure want to delete!',
          //     icon: 'question',
          //     showCancelButton: true,
          //     showConfirmButton: true,
          //     confirmButtonText: 'Delete',
          //     allowOutsideClick: false
          //   }).then((result) => {
          //     if (result.value) {
          //       this.onDeleteSCNSerialItemDetails(serialID);
          //     }
          //   });
          // }
          // else {
          let index = this.serialDetailRowData.findIndex(x => x.serialNo == serialNo);
          this.serialDetailRowData.splice(index, 1);
          this.assignedQty -= 1;
          if (this.assignedQty <= this._requiredQuantity)
            this.unAssignedQty += 1;
          this.serialDetailGridOptions.api.setRowData(this.serialDetailRowData);
          this.selectedRowCount = 0;
          //}
        }
        else if (this._transactionTypeId == 20) {
          let index = this.serialDetailRowData.findIndex(x => x.serialNo == serialNo);
          this.serialDetailRowData.splice(index, 1);
          this.assignedQty -= 1;
          if (this.assignedQty <= this._requiredQuantity)
            this.unAssignedQty += 1;
          this.serialDetailGridOptions.api.setRowData(this.serialDetailRowData);
          this.selectedRowCount = 0;
        } else if (this._transactionTypeId == 4) {
          let index = this.serialDetailRowData.findIndex(x => x.serialNo == serialNo);
          this.serialDetailRowData.splice(index, 1);
          this.assignedQty -= 1;
          if (this.assignedQty <= this._requiredQuantity)
            this.unAssignedQty += 1;
          this.serialDetailGridOptions.api.setRowData(this.serialDetailRowData);
          this.selectedRowCount = 0;
        }
        if (this._transactionTypeId == 31) {
          let index = this.serialDetailRowData.findIndex(x => x.serialNo == serialNo && x.TransactionTypeID==31);
          this.serialDetailRowData.splice(index, 1);
          this.assignedQty -= 1;
          if (this.assignedQty <= this._requiredQuantity)
            this.unAssignedQty += 1;
          this.serialDetailGridOptions.api.setRowData(this.serialDetailRowData);
          this.selectedRowCount = 0;

        }
      }
    }
  }

  onDeleteBillSerialItemDetails() {
    if(this.assignedQty==0){
      this.tracktype='- Untracked';
    }
    else if(this.assignedQty==this._requiredQuantity){
      this.tracktype='- Fully Tracked'
    }
    else if(this._requiredQuantity>this.assignedQty){
      this.tracktype='- Partial Tracked';
    }
    this.http.deleteservice(environment.purchaseApiUrl + `/Bills/DeleteBillSerialItems?ItemId=${this.itemID}&BillDetailId=${this._transactionDetailId}`).subscribe({
      next: (data: any) => {
        if (data.isSuccess === true) {
          let msg = this.translate.instant("Common.Saved successfully");
          this.alertMessage.successNotifier(msg, 0);
          this.emitAfterBatchorSerialSaved.emit({
            transactionTypeID: 2,
            transactionTypeName: 'Bill',
            track :this.tracktype
          }
          );
          this.closeModal();
        }
        else {
          let errorMessage = [];
          if (data.responseData.message.length > 0) {
            data.responseData.message.forEach(element => {
              errorMessage.push({ 'message': element });
            });
            this.alertMessage.errorNotifierList(errorMessage, 0);
          }
        }
      }
    });
  }

  onDeleteSCNSerialItemDetails() {
    this.http.deleteservice(environment.salesApiUrl + `/SalesCreditnotes/DeleteSerialItems?ItemId=${this.itemID}&SCNTransID=${this._transactionDetailId}`).subscribe({
      next: (data: any) => {
        if (data.isSuccess === true) {
          let msg = this.translate.instant("Common.Saved successfully");
          this.alertMessage.successNotifier(msg, 0);
          this.closeModal();
        }
        else {
          let errorMessage = [];
          if (data.responseData.message.length > 0) {
            data.responseData.message.forEach(element => {
              errorMessage.push({ 'message': element });
            });
            this.alertMessage.errorNotifierList(errorMessage, 0);
          }
        }
      }
    });
  }

  closeModal() {
    this.modalCreateSerial.hide();
  }


  async onSubmit() {
    if (this._transactionTypeId == 20) {//Receive Order
      // let isUIValidationSuccess = true;
      let isUIValidationSuccess = false;
      // if (this._mode == 'Edit' || this._mode == 'New')
      isUIValidationSuccess = this.onUIValidateonROSubmit();
      if (isUIValidationSuccess) {
        const serialDetailModel = this.serialDetailRowData.map(ele => ({
          "SerialID": (ele.serialID != null && ele.serialID != undefined) ? ele.serialID : 0,
          "SerialNo": ele.serialNo,
          "ItemID": this.itemID,
          "ManufacturedDate": ele.manufacturedDate == '' ? null : this.datepipe.transform(ele.manufacturedDate, 'yyyy-MM-dd'),
          "ExpiryDate": ele.expiryDate == '' ? null : this.datepipe.transform(ele.expiryDate, 'yyyy-MM-dd'),
          "Quantity": 1,
          "IsLocked": ele.isLocked,
          "TransactionTypeId": this._transactionTypeId, //Receive Order,
          "ROTransDetailId": this._transactionDetailId,
          "WarehouseID": this.isWarehouseEnabled ? ele.warehouseID : null
        })) ?? [];
        let isDBValidateResponse: boolean = await this.onDBValidate(serialDetailModel);
        if (isDBValidateResponse) {
          if (this._mode == "View") {
            let isDBSaved = await this.onSaveUpdateROSerialDetails(serialDetailModel);
            if (isDBSaved) {
              this.emitAfterBatchorSerialSaved.emit({
                transactionTypeID: 20,
                transactionTypeName: 'Receive Order',
                transactionDetailID: this._transactionDetailId,
                track :this.tracktype
              })
              this.modalCreateSerial.hide();
            } else {
              let msg = this.translate.instant("InventoryModule.Item.Cannot save the Serials created");
              this.alertMessage.errorNotifier(msg, 0);
            }
          }
          this.emitOnSubmitValue.emit(serialDetailModel);
          this.modalCreateSerial.hide();
        }
      }
    }
    else if (this._transactionTypeId == 2) {//Bill
      if (this.onUIValidateonBillSubmit()) {
        const serialDetailModel = this.serialDetailRowData.map(ele => ({
          "SerialID": (ele.serialID != null && ele.serialID != undefined) ? ele.serialID : 0,
          "SerialNo": ele.serialNo,
          "ItemID": this.itemID,
          "ManufacturedDate": ele.manufacturedDate == '' ? null : this.datepipe.transform(ele.manufacturedDate, 'yyyy-MM-dd'),
          "ExpiryDate": ele.expiryDate == '' ? null : this.datepipe.transform(ele.expiryDate, 'yyyy-MM-dd'),
          "Quantity": 1,
          "IsLocked": ele.isLocked,
          "TransactionTypeId": this._transactionTypeId, //Bill Order
          "BillDetailID": this._transactionDetailId,
          "BillTransID": this._transactionDetailId,
          "WarehouseID": this.isWarehouseEnabled ? ele.warehouseID : null
        })) ?? [];
        let isDBSaved = await this.onSaveUpdateBillSerialDetails(serialDetailModel);
        if (isDBSaved) {
          this.modalCreateSerial.hide();
          this.emitAfterBatchorSerialSaved.emit({
            transactionTypeID: 2,
            transactionTypeName: 'Bill',
            track :this.tracktype
          }
          );
        }
      }
    }
    else if (this._transactionTypeId == 8) {//SCN
      if (this.onUIValidateonBillSubmit()) {
        const serialDetailModel = this.serialDetailRowData.map(ele => ({
          "SerialID": (ele.serialID != null && ele.serialID != undefined) ? ele.serialID : 0,
          "SerialNo": ele.serialNo,
          "ItemID": this.itemID,
          "ManufacturedDate": ele.manufacturedDate == '' ? null : this.datepipe.transform(ele.manufacturedDate, 'yyyy-MM-dd'),
          "ExpiryDate": ele.expiryDate == '' ? null : this.datepipe.transform(ele.expiryDate, 'yyyy-MM-dd'),
          "Quantity": 1,
          "IsLocked": ele.isLocked,
          "TransactionTypeId": this._transactionTypeId, //SCN Order
          "SCNTransId": this._transactionDetailId,
          "SCNID": this._transactionDetailId,
          "WarehouseID": this.isWarehouseEnabled ? ele.warehouseID : null
        })) ?? [];
        let isDBSaved = await this.onSaveUpdateSCNSerialDetails(serialDetailModel);
        if (isDBSaved) {
          this.modalCreateSerial.hide();
          // this.emitAfterBatchorSerialSaved.emit('Bill');
        }
      }
    } else if (this._transactionTypeId == 4) {//VCN
      if (this.onUIValidateonVCNSubmit()) {
        const serialDetailModel = [];
        const TablData = this.serialDetailRowData;
        for (var i = 0; i < TablData.length; i++) {
          serialDetailModel.push({
            "SerialID": (TablData[i].serialID != null && TablData[i].serialID != undefined) ? TablData[i].serialID : 0,
            "SerialNo": TablData[i].serialNo,
            "ItemID": this.itemID,
            "ManufacturedDate": TablData[i].manufacturedDate == '' ? null : this.datepipe.transform(TablData[i].manufacturedDate, 'yyyy-MM-dd'),
            "ExpiryDate": TablData[i].expiryDate == '' ? null : this.datepipe.transform(TablData[i].expiryDate, 'yyyy-MM-dd'),
            "Quantity": 1,
            "IsLocked": TablData[i].isLocked,
            "TransactionTypeId": 4, //VCN
            "CNTransID": this._transactionDetailId,
            "WarehouseID": this.isWarehouseEnabled ? TablData[i].warehouseID : null
          });
        }
        let isDBSaved = await this.onSaveUpdateVCNSerialDetails(serialDetailModel);
        if (isDBSaved) {
          this.modalCreateSerial.hide();
          // this.emitAfterBatchorSerialSaved.emit('Bill');
        }
      }
    }
    else if (this._transactionTypeId == 31) {//Purchase Debit Note
      if (this.onUIValidateonVDebitNoteSubmit()) {
        const serialDetailModel = this.serialDetailRowData.map(ele => ({
          "SerialID": (ele.serialID != null && ele.serialID != undefined) ? ele.serialID : 0,
          "SerialNo": ele.serialNo,
          "ItemID": this.itemID,
          "ManufacturedDate": ele.manufacturedDate == '' ? null : this.datepipe.transform(ele.manufacturedDate, 'yyyy-MM-dd'),
          "ExpiryDate": ele.expiryDate == '' ? null : this.datepipe.transform(ele.expiryDate, 'yyyy-MM-dd'),
          "Quantity": 1,
          "IsLocked": ele.isLocked,
          "TransactionTypeId": ele.transactionTypeId, 
          "VDebitNoteDetailID": this._transactionDetailId,
          "VDebitNoteID": this._transactionDetailId,
          "WarehouseID": this.isWarehouseEnabled ? ele.warehouseID : null
        })) ?? [];
        let isDBSaved = await this.onSaveUpdateVDebitNoteSerialDetails(serialDetailModel);
        if (isDBSaved) {
          this.modalCreateSerial.hide();
          this.emitAfterBatchorSerialSaved.emit({
            transactionTypeID: 31,
            transactionTypeName: 'Purchase Debit Note',
            transactionDetailID: this._transactionDetailId,
            track :this.tracktype
          }
          );
        }
      }
    }
  }

  async onSaveUpdateROSerialDetails(serialDetailModel: SerialDetail) {
    let isSuccess = false;
    if(this.assignedQty==0){
      this.tracktype='- Untracked';
    }
    else if(this.assignedQty==this._requiredQuantity){
      this.tracktype='- Fully Tracked'
    }
    else if(this._requiredQuantity>this.assignedQty){
      this.tracktype='- Partial Tracked';
    }
    let APIResponse;
    if (serialDetailModel.length > 0) {
      APIResponse = await this.http.awaitPostservice(environment.purchaseApiUrl + '/ReceiveOrder/SaveUpdateROAdvanceTracking', {
        trackingType: 'Serial', detailsJson: JSON.stringify(serialDetailModel)
      });
    } else {
      APIResponse = await this.http.deleteservice(
        environment.purchaseApiUrl
        + `/ReceiveOrder/DeleteROBatchSerialDetails?ROTransDetailId=${this._transactionDetailId}&ItemID=${this.itemID}&TrackingType=${"Serial"}`
      ).toPromise();
    }
    if (APIResponse["isSuccess"] == false) {
      let responseData = JSON.parse(APIResponse["statusMessage"]);
      let errorMessage = [];
      responseData.forEach(element => {
        errorMessage.push({ 'message': element.Message });
      });
      let msg = this.translate.instant('InventoryModule.Item.Above Serial No(s) already exists');
      errorMessage.push({ 'message': msg });
      this.alertMessage.errorNotifierList(errorMessage, 0);
      isSuccess = false;
    } else {
      isSuccess = true;
      let msg = this.translate.instant("Common.Saved successfully");
      this.alertMessage.successNotifier(msg, 0);
    }
    return isSuccess
  }

  async onSaveUpdateVCNSerialDetails(serialDetailModel): Promise<boolean> {
    let isSuccess = false;
    let APIResponse = await this.http.awaitPostservice(environment.purchaseApiUrl + '/Creditnotes/SaveUpdateSerialDetails', serialDetailModel);
    if (APIResponse["isSuccess"] == false) {
      let responseData = JSON.parse(APIResponse["statusMessage"]);
      let errorMessage = [];
      responseData.forEach(element => {
        errorMessage.push({ 'message': element.Message });
      });
      let msg = this.translate.instant('InventoryModule.Item.Above Serial No(s) already exists');
      errorMessage.push({ 'message': msg });
      this.alertMessage.errorNotifierList(errorMessage, 0);
      isSuccess = false;
    } else {
      isSuccess = true;
      let msg = this.translate.instant("Common.Saved successfully");
      this.alertMessage.successNotifier(msg, 0);
    }
    return isSuccess
  }

  async onSaveUpdateBillSerialDetails(serialDetailModel): Promise<boolean> {
    let isSuccess = false;
    if(this.assignedQty==0){
      this.tracktype='- Untracked';
    }
    else if(this.assignedQty==this._requiredQuantity){
      this.tracktype='- Fully Tracked'
    }
    else if(this._requiredQuantity>this.assignedQty){
      this.tracktype='- Partial Tracked';
    }
    if (serialDetailModel.length > 0) {
      let APIResponse = await this.http.awaitPostservice(environment.purchaseApiUrl + '/Bills/SaveUpdateBillSerialDetails', serialDetailModel);
      if (APIResponse["isSuccess"] == false) {
        let responseData = JSON.parse(APIResponse["statusMessage"]);
        let errorMessage = [];
        responseData.forEach(element => {
          errorMessage.push({ 'message': element.Message });
        });
        let msg = this.translate.instant('InventoryModule.Item.Above Serial No(s) already exists');
        errorMessage.push({ 'message': msg });
        this.alertMessage.errorNotifierList(errorMessage, 0);
        isSuccess = false;
      } else {
        isSuccess = true;
        let msg = this.translate.instant("Common.Saved successfully");
        this.alertMessage.successNotifier(msg, 0);
      }
    }
    else {
      this.onDeleteBillSerialItemDetails()
    }
    return isSuccess
  }

  async onSaveUpdateSCNSerialDetails(serialDetailModel): Promise<boolean> {
    let isSuccess = false;
    if (serialDetailModel.length > 0) {
      let APIResponse = await this.http.awaitPostservice(environment.salesApiUrl + '/SalesCreditnotes/SaveUpdateSerialDetails', serialDetailModel);
      if (APIResponse["isSuccess"] == false) {
        let responseData = JSON.parse(APIResponse["statusMessage"]);
        let errorMessage = [];
        responseData.forEach(element => {
          errorMessage.push({ 'message': element.Message });
        });
        let msg = this.translate.instant('InventoryModule.Item.Above Serial No(s) already exists');
        errorMessage.push({ 'message': msg });
        this.alertMessage.errorNotifierList(errorMessage, 0);
        isSuccess = false;
      } else {
        isSuccess = true;
        let msg = this.translate.instant("Common.Saved successfully");
        this.alertMessage.successNotifier(msg, 0);
      }
    } else {
      this.onDeleteSCNSerialItemDetails();
    }
    return isSuccess
  }

  toFindDuplicates(arry) {
    const uniqueElements = new Set(arry);
    const filteredElements = arry.filter(item => {
      if (uniqueElements.has(item)) {
        uniqueElements.delete(item);
      } else {
        return item;
      }
    });
    return [...new Set(uniqueElements)]
  }

  async openModal(_itemID: number, _quantity: number, _POQuantity: number, _mode: string, _transactionDetailId: number, _transactionTypeId: number, _recedivedQuantity: number,_isPriceAdjustment? : boolean) {
    this.isEnabledActionButtons = true;
    this._mode = _mode;
    this._transactionTypeId = _transactionTypeId;
    this._transactionDetailId = _transactionDetailId;
    this._quantityLabel = this._transactionType.find(x => x.transactionTypeId == _transactionTypeId).transactionTypeName;
    this._POQuantity = _POQuantity;
    this._requiredQuantity = Number(_recedivedQuantity);
    this.lstWarehouse = await this.getWarehouseDetails();
    this.setNameCellEditorValues_Warehouse(this.lstWarehouse);
    this.assignedQty = 0;

    if (_transactionTypeId == 20) { //Serial from Receive Order
      if (this._mode == 'View') {  //View Receive Order
        // this.isEnabledActionButtons = false;
        // this._requiredorReceivedQtyLabel = 'Received Quantity';
        this._requiredQuantity = _quantity;
        await this.getAssignedSerialByRODetailId(_transactionDetailId, _itemID);
        this.modalCreateSerial.show();
      } else if (this._mode == 'Edit') { //Edit Receive Order
        this._requiredQuantity = _quantity;
        await this.getAssignedSerialByRODetailId(_transactionDetailId, _itemID);
        this.modalCreateSerial.show();
      } else if (this._mode == 'New') { //Create Receive Order
        this._requiredQuantity = Number(_quantity);
        this._POQuantity = Number(_POQuantity);
        this.unAssignedQty = Number(_quantity);
        this.assignedQty = Number(_quantity) - this.unAssignedQty;
        await this.getAssignedSerialByRODetailId(_transactionDetailId, _itemID);
        this.modalCreateSerial.show();
      }
    }
    else if (_transactionTypeId == 2) { //Serial from Bill
      this.isEnabledActionButtons = true;
      this._requiredorReceivedQtyLabel = 'Required Quantity';
      this._requiredQuantity = Number(_quantity);
      await this.getAssignedSerialByBillDetailId(_transactionDetailId, _itemID);
      this.modalCreateSerial.show();
    }
    else if (_transactionTypeId == 8) { //Serial from SCN
      this.isEnabledActionButtons = true;
      this._requiredorReceivedQtyLabel = 'Required Quantity';
      this._requiredQuantity = Number(_quantity);
      await this.getAssignedSerialBySCNDetailId(_transactionDetailId, _itemID);
      this.modalCreateSerial.show();
    }
    else if (_transactionTypeId == 4) { //Serial from VCN
      this.isEnabledActionButtons = true;
      this._requiredorReceivedQtyLabel = 'Required Quantity';
      this._requiredQuantity = Number(_quantity);
      this.lstWarehouse = await this.getWarehouseDetails();
      this.setNameCellEditorValues_Warehouse(this.lstWarehouse);
      await this.getAssignedSerialByCNTransId(_transactionDetailId, _itemID);
      this.modalCreateSerial.show();
    }
    else if (_transactionTypeId == 31) { //Serial from VDN
      if(_isPriceAdjustment == true){
        this.isEnabledActionButtons = false;
      }
      else{
        this.isEnabledActionButtons = true;
      }
      
      this._requiredorReceivedQtyLabel = 'Required Quantity';
      this._requiredQuantity = Number(_quantity);
      await this.getAssignedSerialByVDebitNoteDetailId(_transactionDetailId, _itemID);
      this.modalCreateSerial.show();
    }
    this.loadFromControls(this.defaultWareHouse);
  }

  async openTempAllocateSerialPopup(_itemID: number, _quantity: number, _POQuantity: number, _mode: string, _transactionDetailId: number, _transactionTypeId: number, _recedivedQuantity: number, tempData: any) {
    this.isEnabledActionButtons = true;
    this._mode = _mode;
    this._transactionTypeId = _transactionTypeId;
    this._transactionDetailId = _transactionDetailId;
    this._quantityLabel = this._transactionType.find(x => x.transactionTypeId == _transactionTypeId).transactionTypeName;
    this._POQuantity = _POQuantity;
    this._requiredQuantity = Number(_recedivedQuantity);
    if (_transactionTypeId == 20) { //Serial from Receive Order
      if (this._mode == 'Edit') { //Edit Receive Order
        this._requiredQuantity = _quantity;
        this.lstWarehouse = await this.getWarehouseDetails();
        this.setNameCellEditorValues_Warehouse(this.lstWarehouse);
        await this.getAssignedSerialByRODetailId(_transactionDetailId, _itemID);
        this.modalCreateSerial.show();
      } else if (this._mode == 'New') { //Create Receive Order
        this._requiredQuantity = Number(_quantity);
        this._POQuantity = Number(_POQuantity);
        // this.unAssignedQty = Number(_quantity);
        let sumOfQuantity = tempData.map(col => col.quantity).reduce((a, b) => {
          return a + b;
        });
        this.assignedQty = Number(sumOfQuantity);
        this.loadTempAllocationData(tempData);
      }
    }
  }

  async loadTempAllocationData(tempSerialAllocationData: any) {
    if (tempSerialAllocationData.length > 0) {
      this.serialDetailRowData = [];
      tempSerialAllocationData.forEach(ele => {
        let rowField = {
          serialID: ele.serialID,
          serialNo: ele.serialNo,
          manufacturedDate: ele.manufacturedDate == null ? "" : ele.manufacturedDate,
          expiryDate: ele.expiryDate == null ? "" : ele.expiryDate,
          quantity: ele.quantity,
          transactionTypeId: ele.transactionTypeId,
          isLocked: ele.isLocked,
          warehouseID: ele.warehouseID
        }
        this.serialDetailRowData.push(rowField);
        this.serialDetailGridOptions.api.addItems([rowField]);
      });
    }
  }

  async getAssignedSerialByRODetailId(ROTransDetailId: number, itemID: number) {
    this.itemID = itemID;
    let data: any = await this.http.getserviceawait(environment.purchaseApiUrl + `/ReceiveOrder/GetAssignedSerial?ROTransDetailId=${ROTransDetailId}&ItemId=${itemID}`);
    if (data.isSuccess && data.responseData != null) {
      this.serialItemDetails = [];
      this.serialDetailRowData = [];
      this.serialItemDetails = data.responseData;
      if (this.serialItemDetails['assignedQuantity']!)
        this.assignedQty = this.serialItemDetails['assignedQuantity'];
      if (this.serialItemDetails['unassignedQuantity']!)
        this.unAssignedQty = this.serialItemDetails['unassignedQuantity'];
      this.defaultWareHouse = this.serialItemDetails['warehouseID'];
      data?.responseData?.serialDetails?.forEach(ele => {
        let rowFeild = {
          serialID: ele.serialID,
          serialNo: ele.serialNo,
          manufacturedDate: ele.manufacturedDate == null ? "" : ele.manufacturedDate,
          expiryDate: ele.expiryDate == null ? "" : ele.expiryDate,
          quantity: ele.quantity,
          transactionTypeId: ele.transactionTypeId,
          isLocked: ele.isLocked,
          warehouseID: ele.warehouseID
        }
        this.serialDetailRowData.push(rowFeild);
        this.serialDetailGridOptions.api.addItems([rowFeild]);
      });
      // if (this._mode == 'View') {
      //   this.serialDetailGridOptions.columnApi.setColumnVisible("Delete", false);
      // } else {
      //   this.serialDetailGridOptions.columnApi.setColumnVisible("Delete", true);
      // }
    }
  }

  calculateAssingedUnAssignedQty() {

  }

  onShowSavedValues(Model) {
    this.serialDetailRowData = [];
    Model?.forEach(ele => {
      let rowFeild = {
        serialID: ele.serialID,
        serialNo: ele.serialNo,
        manufacturedDate: ele.manufacturedDate == null ? "" : ele.manufacturedDate,
        expiryDate: ele.expiryDate == null ? "" : ele.expiryDate,
        quantity: ele.quantity,
        transactionTypeId: ele.transactionTypeId
      }
      this.serialDetailRowData.push(rowFeild);
      this.serialDetailGridOptions.api.addItems([rowFeild]);
    });
  }

  async onDBValidate(serialDetailModel): Promise<boolean> {
    let errorList = [];
    let response = await this.http.awaitPostservice(environment.purchaseApiUrl + '/ReceiveOrder/OnValidateROSerialItem', serialDetailModel);
    if (response["isSuccess"] == false) {
      let responseMessage = JSON.parse(response["statusMessage"]);
      responseMessage.forEach(element => {
        errorList.push({ 'message': element.Message });
      });
      let msg = this.translate.instant('InventoryModule.Item.Above Serial No(s) already exists');
      errorList.push({ 'message': msg });
      this.alertMessage.errorNotifierList(errorList, 0);
      this.submitted = false;
    }
    if (errorList.length > 0) {
      return false;
    } else {
      return true;
    }
  }

  onUIValidateonROSubmit(): boolean {
    const errorMessage = [];
    this.serialDetailRowData.forEach(element => {
      if (this.serialDetailRowData.filter(x => x.serialNo.toString().toUpperCase() == element.serialNo.toString().toUpperCase()).length > 1) {
        let msg = this.translate.instant('InventoryModule.Item.Already exists in the list');
        if (errorMessage.filter(e => e.message == element.serialNo + ' ' + msg).length === 0)
          errorMessage.push({ message: element.serialNo + ' ' + msg });
      }
    });
    if ((this.serialDetailRowData.length) > this._requiredQuantity){
      let msg = this.translate.instant('InventoryModule.Item.Serial quantity should not be exceed than item unassigned quantity');  
      errorMessage.push({ message: msg });
    // if ((this.serialDetailRowData.length) < this._requiredQuantity)
    //   errorMessage.push({ message: 'Serial quantity should not be less than Receive order required quantity' });
    }
    if (errorMessage.length > 0) {
      this.alertMessage.errorSummaryNotifierWithAutoClose(errorMessage, 0);
      return false;
    } else {
      return true;
    }
  }

  onUIValidateonBillSubmit(): boolean {
    const errorMessage = [];
    this.serialDetailRowData.forEach(element => {
      if (this.serialDetailRowData.filter(x => x.serialNo.toString().toUpperCase() == element.serialNo.toString().toUpperCase()).length > 1) {
        let msg = this.translate.instant('InventoryModule.Item.Already exists in the list');
        if (errorMessage.filter(e => e.message.toString().toLowerCase() == element.serialNo + ' ' + msg.toLowerCase()).length === 0)
          errorMessage.push({ message: element.serialNo + ' ' + msg });
      }
    });
    if ((this.serialDetailRowData.length) > this._requiredQuantity){
      let msg = this.translate.instant('InventoryModule.Item.Serial quantity should not be exceed than item unassigned quantity');
      errorMessage.push({ message: msg });
    }
    if (errorMessage.length > 0) {
      this.alertMessage.errorSummaryNotifierWithAutoClose(errorMessage, 0);
      return false;
    } else {
      return true;
    }
  }

  onUIValidateonVCNSubmit(): boolean {
    const errorMessage = [];
    this.serialDetailRowData.forEach(element => {
      if (this.serialDetailRowData.filter(x => x.serialNo.toString().toUpperCase() == element.serialNo.toString().toUpperCase()).length > 1) {
        let msg = this.translate.instant('InventoryModule.Item.Already exists in the list');
        if (errorMessage.filter(e => e.message.toString().toLowerCase() == element.serialNo + ' ' + msg.toLowerCase()).length === 0)
          errorMessage.push({ message: element.serialNo + ' ' + msg });
      }
    });
    if ((this.serialDetailRowData.length) > this._requiredQuantity){
      let msg = this.translate.instant('InventoryModule.Item.Serial quantity should not be exceed than item unassigned quantity');
      errorMessage.push({ message: msg });
    }
    // if ((this.serialDetailRowData.length) < this._requiredQuantity)
    //   errorMessage.push({ message: 'Serial quantity should not be less than Receive order required quantity' });
    // if (this.submitted && this.settingsForm.controls.StartingNo.errors)
    //   return false;
    // if (this.submitted && this.settingsForm.controls.EndingNo.errors)
    //   return false;
    if (errorMessage.length > 0) {
      this.alertMessage.errorSummaryNotifierWithAutoClose(errorMessage, 0);
      return false;
    } else {
      return true;
    }
  }

  onUIValidateonROGenerate(): boolean {
    const errorMessage = [];
    let startingNo = this.settingsForm.controls.StartingNo.value;
    let endingNo = this.settingsForm.controls.EndingNo.value;
    let currentSerialQty = ((Number(endingNo) - Number(startingNo)) + 1);
    let msg = this.translate.instant('InventoryModule.Item.Serial quantity should not be exceed than item unassigned quantity');
    if (currentSerialQty > this._requiredQuantity)
      errorMessage.push({ message: msg });
    else if ((currentSerialQty + this.serialDetailRowData.length) > this._requiredQuantity)
      errorMessage.push({ message: msg });
    if (currentSerialQty <= 0){
      let errormsg = this.translate.instant('InventoryModule.Item.Cannot create descending serial numbers');
      errorMessage.push({ message: errormsg });
    }
    if (this.submitted && this.settingsForm.controls.StartingNo.errors)
      return false;
    if (this.submitted && this.settingsForm.controls.EndingNo.errors)
      return false;
    if (errorMessage.length > 0) {
      this.alertMessage.errorSummaryNotifierWithAutoClose(errorMessage, 0);
      return false;
    } else {
      return true;
    }
  }

  async getAssignedSerialByBillDetailId(billDetailId: number, itemID: number) {
    this.itemID = itemID;
    let data: any = await this.http.getserviceawait(environment.purchaseApiUrl + `/Bills/GetAssignedSerial?BillDetailId=${billDetailId}&ItemId=${itemID}`);
    if (data.isSuccess && data.responseData != null) {
      this.serialItemDetails = [];
      this.serialDetailRowData = [];
      this.serialItemDetails = data.responseData;
      if (this.serialItemDetails['assignedQuantity']!)
        this.assignedQty = this.serialItemDetails['assignedQuantity'];
      if (this.serialItemDetails['unassignedQuantity']!)
        this.unAssignedQty = this.serialItemDetails['unassignedQuantity'];
      if (this.serialItemDetails['warehouseID']!)
        this.defaultWareHouse = this.serialItemDetails['warehouseID'];
      this.serialDetailRowData = this.serialItemDetails.serialDetails.map(ele => ({
        serialID: ele.serialID,
        serialNo: ele.serialNo,
        manufacturedDate: ele.manufacturedDate ?? "",
        expiryDate: ele.expiryDate ?? "",
        quantity: ele.quantity,
        transactionTypeId: ele.transactionTypeId,
        isLocked: ele.isLocked,
        warehouseID: ele.warehouseID == 0 ? this.defaultWareHouse : ele.warehouseID
      }));
      this.serialDetailGridOptions.api.addItems(this.serialDetailRowData);
    }
  }

  async getAssignedSerialBySCNDetailId(SCNTransId: number, itemID: number) {
    this.itemID = itemID;
    let data: any = await this.http.getserviceawait(environment.salesApiUrl + `/SalesCreditnotes/GetAssignedSerial?SCNTransId=${SCNTransId}&ItemId=${itemID}`);
    if (data.isSuccess && data.responseData != null) {
      this.serialItemDetails = [];
      this.serialDetailRowData = [];
      this.serialItemDetails = data.responseData;
      if (this.serialItemDetails['assignedQuantity']!)
        this.assignedQty = this.serialItemDetails['assignedQuantity'];
      if (this.serialItemDetails['unassignedQuantity']!)
        this.unAssignedQty = this.serialItemDetails['unassignedQuantity'];
      if (this.serialItemDetails['warehouseID']!)
        this.defaultWareHouse = this.serialItemDetails['warehouseID'];
      this.serialDetailRowData = this.serialItemDetails.serialDetails.map(ele => ({
        serialID: ele.serialID,
        serialNo: ele.serialNo,
        manufacturedDate: ele.manufacturedDate ?? "",
        expiryDate: ele.expiryDate ?? "",
        quantity: ele.quantity,
        transactionTypeId: ele.transactionTypeId,
        isLocked: ele.isLocked,
        warehouseID: ele.warehouseID == 0 ? this.defaultWareHouse : ele.warehouseID
      }));
      this.serialDetailGridOptions.api.addItems(this.serialDetailRowData);
    }
  }

  onSerialMasterCellValueChanged(params: any) {
    const colId = params.column.getId();
    if (colId == 'FSN' || colId == 'TSN') {
      this.onFormatValidation();
      let rowField = {
        FSN: params.data.FSN,
        TSN: params.data.TSN
      }
      if (rowField.FSN != undefined && rowField.FSN != null && rowField.FSN != '' && rowField.TSN != undefined && rowField.TSN != null && rowField.TSN != '') {
        let count = this.getCountByFSNTSN(rowField);
        // this.serialMasterRowData[params.node.childIndex].quantity = count;
        params.api.refreshCells({
          force: true,
        });
      }
    }
  }

  getCountByFSNTSN(rowField: any): number {
    try {
      let FSN = Number(rowField.FSN.match(/\d+/g)[rowField.FSN.match(/\d+/g).length - 1]);
      let TSN = Number(rowField.TSN.match(/\d+/g)[rowField.TSN.match(/\d+/g).length - 1]);
      if (!isNaN(TSN) && !isNaN(FSN)) {
        return (TSN - FSN) + 1;
      }
    } catch (error) {

    }
    return 1;
  }

  onFormatValidation(): boolean {
    let errorMessage = [];
    let isSuccess = false;
    const Model = []// this.serialMasterRowData;
    Model?.forEach((element, index) => {
      index++;
      let rowData = {
        FSN: element.FSN,
        TSN: element.TSN
      }
      try {
        if (rowData.FSN != '' && rowData.TSN == '') {
          rowData.TSN = rowData.FSN;
          element.TSN = rowData.FSN;
        } else if (rowData.TSN != '' && rowData.FSN == '') {
          rowData.FSN = rowData.TSN;
          element.FSN = rowData.TSN;
        }
        let FSNStartingDigit = rowData.FSN.match(/\d+/g)[rowData.FSN.match(/\d+/g).length - 1];
        let TSNEndingDigit = rowData.TSN.match(/\d+/g)[rowData.TSN.match(/\d+/g).length - 1];
        let FSNStartingDigitIndex = rowData.FSN.toString().lastIndexOf(FSNStartingDigit);
        let TSNEndingDigitIndex = rowData.TSN.toString().lastIndexOf(TSNEndingDigit);
        let FSNPrefix = rowData.FSN.toString().substring(0, FSNStartingDigitIndex);
        let TSNPrefix = rowData.TSN.toString().substring(0, TSNEndingDigitIndex);
        if (FSNPrefix != TSNPrefix) {
          isSuccess = false;
        } else if (FSNStartingDigit.length != TSNEndingDigit.length) {
          isSuccess = false;
        } else {
          isSuccess = true;
        }
      } catch (ex) {
        isSuccess = false;
      }
      if (isSuccess == false) {
        errorMessage.push({ message: this.translate.instant('InventoryModule.Item.Row') +' ' + index + ' : ' + this.translate.instant('InventoryModule.Item.The size of Start Serial Number') + ' ' + "'" + rowData.FSN + "'" + ' ' + + this.translate.instant('InventoryModule.Item.is not same with the size of End Serial Number') + ' ' + "'" + rowData.TSN + "'" });
      }
    });
    if (errorMessage.length > 0) {
      this.alertMessage.errorNotifierList(errorMessage, 0);
      isSuccess = false;
    }
    return isSuccess;
  }

  initFormGroup() {
    this.settingsForm = new FormGroup({
      Prefix: new FormControl(''),
      StartingNo: new FormControl('', [Validators.required]),
      EndingNo: new FormControl('', [Validators.required]),
      Postfix: new FormControl(''),
      ManufacturedDate: new FormControl(''),
      ExpiryDate: new FormControl(''),
      WarehouseID: new FormControl(''),
      SerialNoBarcode: new FormControl(''),
      isAutoPush: new FormControl(true),
    });


    this.serialDetailForm = this.fb.group({
      SerialNo: ['', [Validators.required]],
      ManufacturedDate: [''],
      ExpiryDate: [''],
      Quantity: ['1', [Validators.required]]
    });
  }

  calculateQtyCount(startingNo, endingNo): number {
    let count = 0;
    try {
      count = (Number(endingNo) - Number(startingNo)) + 1;
    } catch (ex) {
    }
    return count;
  }

  onRowSelected(params: any) {
    this.selectedRows = params.api.getSelectedRows();
    this.selectedRowCount = this.selectedRows.length;
  }

  onUISerialDetailDataBinding() {
    this.submitted = true;
    let isDublicate = false;
    let errorList = [];
    // let isValidationSuccess = this.onFormatValidation();
    let isUIValidationSuccess = this.onUIValidateonROGenerate();
    if (isUIValidationSuccess == true) {
      // const masterRowData = this.serialMasterRowData;
      const settingsFormData = {
        "Prefix": this.settingsForm.controls.Prefix.value == null ? '' : this.settingsForm.controls.Prefix.value,
        "StartingNo": this.settingsForm.controls.StartingNo.value,
        "EndingNo": this.settingsForm.controls.EndingNo.value,
        "Postfix": this.settingsForm.controls.Postfix.value == null ? '' : this.settingsForm.controls.Postfix.value,
        "ItemID": this.itemID,
        "ManufacturedDate": this.settingsForm.controls.ManufacturedDate.value == null ? '' : this.settingsForm.controls.ManufacturedDate.value,
        "ExpiryDate": this.settingsForm.controls.ExpiryDate.value == null ? '' : this.settingsForm.controls.ExpiryDate.value,
        "Count": this.calculateQtyCount(this.settingsForm.controls.StartingNo.value, this.settingsForm.controls.EndingNo.value),
        "TransactionTypeId": 20, //Receive Order
        "WarehouseID": this.settingsForm.controls.WarehouseID.value
      }
      // this.serialDetailRowData = [];
      let loopingCount = settingsFormData.Count;
      let sNumber = Number(settingsFormData.StartingNo);
      for (var i = 1; i <= loopingCount; i++) {
        if (i > 1)
          sNumber += 1;
        let serialNo = settingsFormData.Prefix + sNumber.toString() + settingsFormData.Postfix;
        if (this.isDublicate(serialNo)) {
          isDublicate = true;
          errorList.push({ message: serialNo + ' ' + this.translate.instant('InventoryModule.Item.is already exists in the List') });
          break;
        } else {
          const detailRowData = {
            serialID: 0,//Newly added for Delete case
            serialNo: serialNo,
            manufacturedDate: settingsFormData.ManufacturedDate,
            expiryDate: settingsFormData.ExpiryDate,
            quantity: 1,
            isLocked: false,
            warehouseID: settingsFormData.WarehouseID,
            transactionTypeId:this._transactionTypeId
          };
          this.serialDetailRowData.push(detailRowData);
          this.serialDetailGridOptions.api.addItems([detailRowData]);
          this.assignedQty = this.serialDetailRowData.length;
          if (this.unAssignedQty > 0)
            this.unAssignedQty -= 1;
        }
      }
      if (errorList.length > 0) {
        this.alertMessage.errorNotifierList(errorList, 0);
      } else {
        this.submitted = false;
        this.clearValues();
      }
    }
  }

  clearValues() {
    this.submitted = false;
    this.settingsForm.reset({
      Prefix: '',
      StartingNo: '',
      EndingNo: '',
      Postfix: '',
      ManufacturedDate: '',
      ExpiryDate: '',
      WarehouseID: this.settingsForm.controls.WarehouseID.value,
      isAutoPush: true
    });
  }

  // onUISerialDetailDataBinding() {
  //   let isValidationSuccess = this.onFormatValidation();
  //   let isUIValidationSuccess = this.onUIValidate();
  //   if (isValidationSuccess == true && isUIValidationSuccess == true) {
  //     const masterRowData = this.serialMasterRowData;
  //     this.serialDetailRowData = [];
  //     masterRowData!.forEach(masterRow => {
  //       const masterRowField = {
  //         FSN: masterRow.FSN,
  //         TSN: masterRow.TSN
  //       };
  //       let loopingCount = this.getCountByFSNTSN(masterRowField);
  //       let FSNStartingDigit = masterRowField.FSN.match(/\d+/g)[masterRowField.FSN.match(/\d+/g).length - 1];
  //       let FSNStartingDigitIndex = masterRowField.FSN.toString().lastIndexOf(FSNStartingDigit);
  //       let FSNPrefix = masterRowField.FSN.toString().substring(0, FSNStartingDigitIndex);
  //       let sNumber = Number(FSNStartingDigit);
  //       for (var i = 1; i <= loopingCount; i++) {
  //         if (i > 1)
  //           sNumber += 1;
  //         let serialNo = FSNPrefix + this.paddingZero(FSNStartingDigit, sNumber);
  //         const detailRowData = {
  //           serialNo: serialNo,
  //           manufacturedDate: masterRow.manufacturedDate,
  //           expiryDate: masterRow.expiryDate,
  //           quantity: 1
  //         };
  //         this.serialDetailRowData.push(detailRowData);
  //         this.serialDetailGridOptions.api.addItems([detailRowData]);
  //       }
  //     });
  //   } else {
  //     this.serialDetailRowData = [];
  //   }
  // }

  paddingZero(FSNStartingDigit, sNumber): string {
    let lnStartingDigit = FSNStartingDigit.toString().length;
    let lnSNo = sNumber.toString().length;
    return '0'.repeat(lnStartingDigit - lnSNo) + sNumber;
  }

  findDuplicates(arrayWithDuplicates) {
    let duplicates = [];
    arrayWithDuplicates.forEach((value, index) => {
      const indexWhereValueFirstAppears = arrayWithDuplicates.findIndex((value_) => {
        return value_.FSN === value.FSN;
      });
      const indexWhereValueLastAppears = arrayWithDuplicates.lastIndexOf(value);
      if (index !== indexWhereValueFirstAppears && index === indexWhereValueLastAppears) {
        duplicates.push(value);
      }
    });
    return duplicates;
  }

  onPageSizeChanged() {
    this.serialDetailGridOptions.api.paginationSetPageSize(Number(this.paginationPageSize));
    this.serialDetailGridOptions.api.sizeColumnsToFit();
  }

  isDublicate(serialNo): boolean {
    let dublicateLength = this.serialDetailRowData.filter(x => x.serialNo.toString().toUpperCase() == serialNo.toString().toUpperCase()).length;
    if (dublicateLength > 0) {
      return true;
    } else {
      return false;
    }
  }

  onManufacturedDateSelect() {
    let manufacturedDate = this.f.ManufacturedDate.value;
    const current = new Date(manufacturedDate);
    this.minDate = {
      year: current.getFullYear(),
      month: current.getMonth() + 1,
      day: current.getDate(),
    };
  }

  onExpiryDateSelect() {
    let expiryDate = this.f.ExpiryDate.value;
    const current = new Date(expiryDate);
    this.maxDate = {
      year: current.getFullYear(),
      month: current.getMonth() + 1,
      day: current.getDate(),
    };
  }

  lookupValue(warehouseList: any, params: any) {
    if (params.value == null || params.value == "" || params.value == undefined || warehouseList == null) {
      return;
    }
    else {
      let index = 0;
      if (params.column.colId == 'warehouseID') {
        index = warehouseList.findIndex(item => item.warehouseID == params.value);
        if (index != -1) {
          return warehouseList[index].warehouseName;
        } else {
          return "";
        }
      }
    }
  }

  async getWarehouseDetails(): Promise<any> {
    let returnvalue: any[];
    let data: any;
    data = await this.http.getserviceawait(environment.purchaseApiUrl + `/Bills/GetWareHouseDetails`);
    if (data.isSuccess && data.responseData != null) {
      returnvalue = data.responseData.map(row => ({
        warehouseID: row.warehouseID,
        warehouseName: row.warehouseName
      }));
    }
    return returnvalue;
  }

  setNameCellEditorValues_Warehouse(values) {
    let colDefs: any = this.serialDetailGridOptions.api.getColumnDefs();
    colDefs[0].cellEditorParams.lstWarehouse = values;
    this.serialDetailGridOptions.api.setColumnDefs(colDefs);
  }

  async getAssignedSerialByCNTransId(VCNTransId: number, itemID: number) {
    this.itemID = itemID;
    let data: any = await this.http.getserviceawait(environment.purchaseApiUrl + `/Creditnotes/GetAssignedSerial?VCNTransId=${VCNTransId}&ItemId=${itemID}`);
    if (data.isSuccess && data.responseData != null) {
      this.serialItemDetails = [];
      this.serialDetailRowData = [];
      this.serialItemDetails = data.responseData;
      if (this.serialItemDetails['assignedQuantity']!)
        this.assignedQty = this.serialItemDetails['assignedQuantity'];
      if (this.serialItemDetails['unassignedQuantity']!)
        this.unAssignedQty = this.serialItemDetails['unassignedQuantity'];
      if (this.serialItemDetails['warehouseID']!)
        this.defaultWareHouse = this.serialItemDetails['warehouseID'];
      this.serialDetailRowData = this.serialItemDetails.serialDetails.map(ele => ({
        serialID: ele.serialID,
        serialNo: ele.serialNo,
        manufacturedDate: ele.manufacturedDate ?? "",
        expiryDate: ele.expiryDate ?? "",
        quantity: ele.quantity,
        transactionTypeId: ele.transactionTypeId,
        isLocked: ele.isLocked,
        warehouseID: ele.warehouseID == 0 ? this.defaultWareHouse : ele.warehouseID
      }));
      this.serialDetailGridOptions.api.addItems(this.serialDetailRowData);
    }
  }

  loadFromControls(warehouseId: number) {
    this.settingsForm.patchValue({ WarehouseID: warehouseId });
  }

  onBarCodeBind(event: ClipboardEvent) {  
    let clipboardText = event.clipboardData.getData('text');
    if (this.settingsForm.controls.isAutoPush.value && clipboardText) {
      let rowField = {
        serialID: 0,
        serialNo: clipboardText,
        manufacturedDate: '',
        expiryDate: '',
        quantity: 1,
        transactionTypeId: this._transactionTypeId,
        isLocked: false,
        warehouseID: this.settingsForm.controls.WarehouseID.value,
      };
      if (this.serialDetailRowData.filter(col => col.serialNo.toString().toUpperCase() == rowField.serialNo.toString().toUpperCase()).length == 0) {
        this.serialDetailRowData.push(rowField);
        this.serialDetailGridOptions.api.addItems([rowField]);
        this.settingsForm.patchValue({ SerialNoBarcode: '' });
        this.assignedQty += 1;
      } else {
        this.alertMessage.errorNotifier(rowField.serialNo + ' Already exists', 0);
      }
    }
  }
  async getAssignedSerialByVDebitNoteDetailId(VDebitNoteDetailId: number, itemID: number) {
    this.itemID = itemID;
    let data: any = await this.http.getserviceawait(environment.purchaseApiUrl + `/PurchaseDebitNote/GetAssignedSerial?VDebitNoteDetailId=${VDebitNoteDetailId}&ItemId=${itemID}`);
    if (data.isSuccess && data.responseData != null) {
      this.serialItemDetails = [];
      this.serialDetailRowData = [];
      this.serialItemDetails = data.responseData;
      if (this.serialItemDetails['assignedQuantity']!)
        this.assignedQty = this.serialItemDetails['assignedQuantity'];
      if (this.serialItemDetails['unassignedQuantity']!)
        this.unAssignedQty = this.serialItemDetails['unassignedQuantity'];
      if (this.serialItemDetails['warehouseID']!)
        this.defaultWareHouse = this.serialItemDetails['warehouseID'];
      this.serialDetailRowData = this.serialItemDetails.serialDetails.map(ele => ({
        serialID: ele.serialID,
        serialNo: ele.serialNo,
        manufacturedDate: ele.manufacturedDate ?? "",
        expiryDate: ele.expiryDate ?? "",
        quantity: ele.quantity,
        transactionTypeId: ele.transactionTypeId,
        isLocked: ele.isLocked,
        warehouseID: ele.warehouseID == 0 ? this.defaultWareHouse : ele.warehouseID
      }));
      this.serialDetailGridOptions.api.addItems(this.serialDetailRowData);
    }
  }
  onDeleteVDebitNoteSerialItemDetails() {
    if(this.assignedQty==0){
      this.tracktype='- Untracked';
    }
    else if(this.assignedQty==this._requiredQuantity){
      this.tracktype='- Fully Tracked'
    }
    else if(this._requiredQuantity>this.assignedQty){
      this.tracktype='- Partial Tracked';
    }
    this.http.deleteservice(environment.purchaseApiUrl + `/PurchaseDebitNote/DeleteVDebitNoteSerialItems?ItemId=${this.itemID}&VDebitNoteDetailId=${this._transactionDetailId}`).subscribe({
      next: (data: any) => {
        if (data.isSuccess === true) {
          let msg = this.translate.instant("Common.Saved successfully");
          this.alertMessage.successNotifier(msg, 0);
          this.emitAfterBatchorSerialSaved.emit({
            transactionTypeID: 31,
            transactionTypeName: 'Purchase Debit Note',
            transactionDetailID: this._transactionDetailId,
            track :this.tracktype
          }
          );
          this.closeModal();
        }
        else {
          let errorMessage = [];
          if (data.responseData.message.length > 0) {
            data.responseData.message.forEach(element => {
              errorMessage.push({ 'message': element });
            });
            this.alertMessage.errorNotifierList(errorMessage, 0);
          }
        }
      }
    });
  }
  onUIValidateonVDebitNoteSubmit(): boolean {
    const errorMessage = [];
    this.serialDetailRowData.forEach(element => {
      if (this.serialDetailRowData.filter(x => x.serialNo.toString().toUpperCase() == element.serialNo.toString().toUpperCase()).length > 1) {
        let msg = this.translate.instant('InventoryModule.Item.Already exists in the list');
        if (errorMessage.filter(e => e.message.toString().toLowerCase() == element.serialNo + ' ' + msg.toLowerCase()).length === 0)
          errorMessage.push({ message: element.serialNo + ' ' + msg });
      }
    });
    if ((this.serialDetailRowData.length) > this._requiredQuantity){
      let msg = this.translate.instant('InventoryModule.Item.Serial quantity should not be exceed than item unassigned quantity');
      errorMessage.push({ message: msg });
    }
    if (errorMessage.length > 0) {
      this.alertMessage.errorSummaryNotifierWithAutoClose(errorMessage, 0);
      return false;
    } else {
      return true;
    }
  }
  async onSaveUpdateVDebitNoteSerialDetails(serialDetailModel): Promise<boolean> {
    debugger;
    let isSuccess = false;
    if(this.assignedQty==0){
      this.tracktype='- Untracked';
    }
    else if(this.assignedQty==this._requiredQuantity){
      this.tracktype='- Fully Tracked'
    }
    else if(this._requiredQuantity>this.assignedQty){
      this.tracktype='- Partial Tracked';
    }
    if (serialDetailModel.filter(a=>a.TransactionTypeId==31).length > 0) {
      let APIResponse = await this.http.awaitPostservice(environment.purchaseApiUrl + '/PurchaseDebitNote/SaveUpdateVDebitNoteSerialDetails', serialDetailModel);
      if (APIResponse["isSuccess"] == false) {
        let responseData = JSON.parse(APIResponse["statusMessage"]);
        let errorMessage = [];
        responseData.forEach(element => {
          errorMessage.push({ 'message': element.Message });
        });
        let msg = this.translate.instant('InventoryModule.Item.Above Serial No(s) already exists');
        errorMessage.push({ 'message': msg });
        this.alertMessage.errorNotifierList(errorMessage, 0);
        isSuccess = false;
      } else {
        isSuccess = true;
        let msg = this.translate.instant("Common.Saved successfully");
        this.alertMessage.successNotifier(msg, 0);
      }
    }
    else {
      this.onDeleteVDebitNoteSerialItemDetails()
    }
    return isSuccess
  }
}
