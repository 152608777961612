<div bsModal #showmodal="bs-modal" id="ReorderColumn" class="modal" tabindex="-1" role="dialog"
  [config]="{backdrop: 'static', keyboard: false}" aria-labelledby="showitemImportmodalLabel" aria-hidden="true">
  <div class="modal-dialog modal-primary modal-md" role="document">
    <form class="form-horizontal" novalidate="novalidate">
      <div class="modal-content">
        <div class="modal-header h4">
          {{'ReportsModule.Re-Order Column' | translate}}
          <button id="btnCloseModel" class="close float-right" (click)="CloseModel()"><span class="text-white"
              aria-hidden="true">x</span></button>
        </div>
        <div class="modal-body">
          <div *ngIf="reporttitle != 'Invoice/Sales Debit Note Payment Collection Report' && reporttitle != 'Bills/Purchase Debit Note Payment Collection Report'" id="btncdkDropList" cdkDropList class="example-list" (cdkDropListDropped)="drop($event)">

            <div class="example-box ml-4 mr-2" *ngFor="let col of columns" cdkDrag [hidden]="col.title==''">
              <span cdkDragHandle>
                <mat-icon>drag_indicator</mat-icon>
              </span>
              {{col.title}}<span class="badge badge-secondary" *ngIf="col?.ReportType == 'Custom Fields'"> (CF) </span>
            </div>
          </div>
          <div *ngIf="reporttitle == 'Invoice/Sales Debit Note Payment Collection Report' || reporttitle == 'Bills/Purchase Debit Note Payment Collection Report'">
            <h2>{{reporttitle == 'Invoice/Sales Debit Note Payment Collection Report' ? 'Invoice Columns' : 'Bill Columns'}}</h2>
          <div id="btncdkDropList1" cdkDropList class="example-list" (cdkDropListDropped)="drop($event)">
            
            <div class="example-box ml-4 mr-2" *ngFor="let col of transactioncolumns" cdkDrag [hidden]="col.title==''">
              <span cdkDragHandle>
                <mat-icon>drag_indicator</mat-icon>
              </span>
              {{col.title}}<span class="badge badge-secondary" *ngIf="col?.ReportType == 'Custom Fields'"> (CF) </span>
            </div>
          </div>
          <br>
          <h2>Payment Columns</h2>
          <div id="btncdkDropList2" cdkDropList class="example-list" (cdkDropListDropped)="drop1($event)">

            <div class="example-box ml-4 mr-2" *ngFor="let col of paymentcolumns" cdkDrag [hidden]="col.title==''">
              <span cdkDragHandle>
                <mat-icon>drag_indicator</mat-icon>
              </span>
              {{col.title}}
            </div>
          </div>
          </div>
        </div>
        <div class="modal-footer">
          <button id="btnCloseModel-2" type="button" class="btn btn-secondary" (click)="CloseModel()">{{'Common.Close' | translate}}</button>
          <button id="btnapplyRorder" type="button" class="btn btn-info" (click)="applyRorder()">{{'ReportsModule.Apply' | translate}}</button>
        </div>
      </div>
    </form>
  </div>
</div>