import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpServices } from '../../../utilities/_services/http.service';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { HttpParams } from '@angular/common/http';
import { LocalCacheServices } from '../../../utilities/_services/acclocalcache.service';
import { EinvoiceServices } from '../../../utilities/_services/einvoice.service';
import { Subscription } from 'rxjs';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { AlertMessage } from '../../../utilities/_helpers/alert.message';

export class LDHNRes {
  uuid: string;
  submissionUid: string;
  longID: string;
  typeName: string;
  typeVersionName?: string;
  issuerTin: string;
  issuerName: string;
  receiverId: string;
  receiverName: string;
  dateTimeReceived: Date;
  dateTimeValidated: Date;
  totalExcludingTax?: number;
  totalDiscount?: number;
  totalNetAmount?: number;
  totalPayableAmount?: number;
  status: string;
  createdByUserId?: string;
  documentStatusReason?: null;
  cancelDateTime?: null;
  rejectRequestDateTime?: null;
  validationResults: ValidationResults;
  internalId: string;
  dateTimeIssued: Date;
  document:any;
}

export interface ValidationResults {
  status: string;
  validationSteps: ValidationStep[];
}

export interface ValidationStep {
  status: string;
  name: string;
  error?: Error;
}

export interface Error {
  propertyName: null | string;
  propertyPath: null | string;
  errorCode: string;
  error: string;
  errorMs: null | string;
  innerError: Error[] | null;
}

@Component({
  selector: 'app-lhdnvalidation',
  templateUrl: './lhdnvalidation.component.html',
  styleUrls: ['./lhdnvalidation.component.scss']
})
export class LHDNValidationComponent implements OnInit {
  @ViewChild("lhdnValidationModal") lhdnValidationModal: ModalDirective;
  @ViewChild("ErrorModel") ErrorModel: ModalDirective;
  @ViewChild("CancelPopup") CancelPopup: ModalDirective;
  @ViewChild("StatusModal") StatusModal: ModalDirective;
  @Output() onCancel: EventEmitter<any> = new EventEmitter<any>();
  LDHNRes: LDHNRes;
  activeTab:any='Details';
  ValidateMsg = [];
  result = [];
  ErrorMsg = [];
  ContactID = 0;
  canceldoctxt: any = ''
  submitted: boolean = false;
  orgSettings: any;
  subscription: Subscription = new Subscription();
  QRcode:any='';
  cancelReason:any='';
  CancelEinvoice: boolean = false;
  @Input() transID: number = null;
  @Input() transTypeID: number = null;
  loading:boolean=false;
  constructor(private http: HttpServices, private local: LocalCacheServices, private sanitizer: DomSanitizer,
    private alertMessage: AlertMessage,private einvoiceService :EinvoiceServices ) {
    this.subscription.add(this.einvoiceService.lhdn.subscribe((data: any) => {
      let ValidateMsg = data.data;
      this.result = [];
      ValidateMsg.forEach(e => {
        if (this.result.findIndex(r => r.contactID == e.contactID) < 0) {
          let error = ValidateMsg.filter(a => a.contactID == e.contactID);
          this.result.push({ contactID: e.contactID, ContactName: e.ContactName, errorList: error })
        }
      })
      this.lhdnValidationModal.show();
    })
    )
  }

  ngOnInit(): void {
    this.orgSettings = JSON.parse(this.local.getlocalStorage(this.local.localCurrentOrganization));
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
  closeModal() {
    this.lhdnValidationModal.hide();
  }
  openModal() {
    this.lhdnValidationModal.show();
  }

  async LHDNValidationForContact(ContactData: any, PageName) {

    let ValidateMsg = [];
    let Contact = 'Contact';
    if (PageName == 'Invoice' || PageName == 'crnote') {
      Contact = 'Buyer'
    }
    else if (PageName == 'Bill') {
      Contact = 'Supplier'
    }

    if (ContactData.tinNumber == null || ContactData.tinNumber == "") {
      ValidateMsg.push({ Field: Contact + " Tin", Error: "Mandatory Missing" });
    }
    // if (ContactData.toursimtaxRegNumber == null || ContactData.toursimtaxRegNumber == "") {
    //   ValidateMsg.push({ Field: Contact + " Tourism Tax Registration Number", Error: "Mandatory Missing" });
    // }
    if (ContactData.miscCode == null || ContactData.miscCode == "") {
      ValidateMsg.push({ Field: Contact + " Malaysia Standard Industrial Classification (MSIC) Code", Error: "Mandatory Missing" });
    }
    if (ContactData.actDesc == null || ContactData.actDesc == "") {
      ValidateMsg.push({ Field: Contact + " Business Activity Description", Error: "Mandatory Missing" });
    }
    if (ContactData.identificationNumber == null || ContactData.identificationNumber == "") {
      ValidateMsg.push({ Field: Contact + " Registration / Identification Number / Passport Number", Error: "Mandatory Missing" });
    }
    // if (ContactData.busRegNumber == null || ContactData.busRegNumber == "") {
    //   ValidateMsg.push({ Field: Contact + " SST Registration Number", Error: "Mandatory Missing" });
    // }
    if (ContactData.postalAddress == null || ContactData.postalAddress == "") {
      ValidateMsg.push({ Field: Contact + " Address", Error: "Mandatory Missing" });
    }
    if (ContactData.postalCity == null || ContactData.postalCity == "") {
      ValidateMsg.push({ Field: Contact + " City", Error: "Mandatory Missing" });
    }
    if (ContactData.postalZipCode == null || ContactData.postalZipCode == "") {
      ValidateMsg.push({ Field: Contact + " Zip Code", Error: "Mandatory Missing" });
    }
    if (ContactData.postalState == null || ContactData.postalState == "") {
      ValidateMsg.push({ Field: Contact + " State", Error: "Mandatory Missing" });
    }
    if (ContactData.personDetailTable[0]?.phoneNo == null || ContactData.personDetailTable[0]?.phoneNo == "") {
      ValidateMsg.push({ Field: Contact + " Contact Number", Error: "Mandatory Missing" });
    }
    if (ContactData.personDetailTable[0]?.email == null || ContactData.personDetailTable[0]?.email == "") {
      ValidateMsg.push({ Field: Contact + " E-mail", Error: "Mandatory Missing" });
    }
    return ValidateMsg;
  }
  encodebtoa(id: any) {
    return btoa(id);
  }

  TransTypeID: any;
  TransID: any;
  Document: any = null;
  Status:any;
  show:boolean=true
  EndPoint: any='';
  trustedUrl: SafeResourceUrl;
  @Output() UpdatedStatus = new EventEmitter();
  getLHDNStatus(TransTypeID: any, TransID: any, Status: any) {
    this.Status=Status;
    this.Document = null;
    this.TransTypeID = TransTypeID;
    this.TransID = TransID;
    this.ErrorMsg = [];
    this.QRcode='';
    this.show=true;
    let params = new HttpParams();
    params = params.set('TransID', TransID);
    params = params.set('TransTypeID', TransTypeID);
    if(Status=="Submitted" && this.orgSettings.accessPointID == 2){
      this.http.postservice(environment.administrationApiUrl + '/Admin/GetDocument', params).subscribe({
        next: (data: any) => {
          this.EndPoint = 'LHDN';
          if(data.status=="Failed"){
            let res=data.message? JSON.parse(data.message) :data.message
            this.alertMessage.errorNotifier(res?.error.details[0].message, 0)
          }
          else if(data.status=="Error"){
            this.alertMessage.errorNotifier(data.message, 0)
          }
          else{
          let res=data.message?JSON.parse(data.message) :data.message
          this.LDHNRes = new LDHNRes;
          this.LDHNRes = res;
          this.Status=this.LDHNRes.status
          this.UpdatedStatus.emit(this.Status);
          this.StatusModal.show();
          }
        }
      })
    }
    else if(Status=="Submitted" && this.orgSettings.accessPointID != 2){
      this.show=false
      this.StatusModal.show();
    }
    else{
    this.http.postservice(environment.administrationApiUrl + '/Admin/GetEinvoiceData', params).subscribe({
      next: (data: any) => {
        let res = data.responseData ? JSON.parse(data.responseData) : [];
        this.EndPoint = res? res[0].EndPoint :'';
        if (Status == 'Error') {
          let err = JSON.parse(res[0].ValidationResult);
          if (res[0].EndPoint == 'LHDN') {
            this.ErrorMsg = err.details.map(e => ({ details: e.message, source: "" }));
          }
          else {
            if (err?.event_type == "document_submission") {
              this.ErrorMsg = [
                {
                  details: err.details,
                  source: ""
                }
              ]
            }
            else {
              this.ErrorMsg=err.errors?err.errors.map(e => ({ details: e.details, source: e.source })): err.details.map(e => ({ details: e.message, source: "" }));
            }
          }
          this.ErrorModel.show();
        }
        else if(Status=='Failed'){
          let result=JSON.parse(res[0].ValidationResult);
          this.ErrorMsg=result.details
          this.ErrorModel.show();
        }
        else if (Status == 'Valid' || Status == 'Invalid') {
          if(res[0].EndPoint == 'StoreCove'){
            if(this.Status=='Invalid'){
              let validationresult=res[0].ValidationResult?JSON.parse(res[0].ValidationResult):"";
              this.LDHNRes.validationResults=validationresult.validationResults
            }
            else{
              this.LDHNRes = {
                uuid: res[0].UUID,
                submissionUid: res[0].SubmissionId,
                longID: res[0].LongID,
                typeName: res[0].TypeName,               
                issuerTin: res[0].IssuerTin,
                issuerName: res[0].IssuerName,
                receiverId: res[0].ReceiverTin,
                receiverName: res[0].ReceiverName,
                dateTimeReceived: res[0].ReceivedOn,
                dateTimeValidated: res[0].ValidatedOn,                 
                status: res[0].Status,              
                internalId:res[0].InternalId,
                dateTimeIssued:res[0].IssuedOn,
                document:res[0].DocumentURL ,
                validationResults:res[0].ValidationResult,
              }
              if(res[0].DocumentURL){
                this.trustedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(res[0].DocumentURL);
              }
            }           
          }
          else if (res[0].EndPoint == 'LHDN') {
            this.LDHNRes = new LDHNRes;
            this.LDHNRes = res[0].ResponseResult?JSON.parse(res[0].ResponseResult):"";
            let validationdata=res[0].ValidationResult?JSON.parse(res[0].ValidationResult):res[0].ValidationResult
            if(validationdata.validationResults){
              this.LDHNRes.validationResults=validationdata.validationResults
            }
            if(!this.LDHNRes && validationdata){
              this.LDHNRes=validationdata
            }
          }
          this.StatusModal.show();
        }
        else if(Status=='Cancelled'){
          this.LDHNRes=res[0].ResponseResult?JSON.parse(res[0].ResponseResult):"";
          this.StatusModal.show();
        }
        if((this.LDHNRes.status=='Valid' || this.LDHNRes.status=='Cancelled') && this.activeTab=='QR'){
          this.getQR();
        }
        if(this.LDHNRes.status=='Invalid' && this.activeTab=='QR'){
          this.activeTab='Details'
        }
      }
    });
  }
  
  }

  getDocument(){    
      let params = new HttpParams();
      params = params.set('TransID', this.TransID);
      params = params.set('TransTypeID', this.TransTypeID);
      this.http.postservice(environment.administrationApiUrl + '/Admin/GetDocument', params).subscribe({
        next: (data: any) => {
          let res = data.message ? JSON.parse(data.message) : [];
          this.LDHNRes =res
       
        }
      });
  }

  getValidation(){
    if(this.Status=='Invalid' && !this.LDHNRes.validationResults){
      let params = new HttpParams();
      params = params.set('TransID', this.TransID);
      params = params.set('TransTypeID', this.TransTypeID);
      this.http.postservice(environment.administrationApiUrl + '/Admin/GetDocumentDetails', params).subscribe({
        next: (data: any) => {
          let res = data.message ? JSON.parse(data.message) : [];
          if(data.status=="Failed"){
            this.alertMessage.errorNotifier(res.error.details[0].message, 0)
          }
          else if(data.status=="Error"){
            this.alertMessage.errorNotifier(data.message, 0)
          }
          else{
            this.LDHNRes.validationResults=res.validationResults
          }
        }
      });
    }
  }
  getQR(){
    if(!this.QRcode){
      this.loading=true;
    let params = new HttpParams();
    params = params.set('uuid',this.LDHNRes.uuid);
    params = params.set('longID',this.LDHNRes.longID);
    this.http.postservice(environment.templateApiUrl + '/templates/GetEinvoiceQrCode', params).subscribe({
      next: (data: any) => {
           this.loading=false;
           this.QRcode="data:image/png;base64," + data.responseData
      }
    });
  }
}
async cancelDocument(){
  if (!this.cancelReason) {
    this.CancelEinvoice = true;
    return;
  }
  this.CancelEinvoice = false
  this.CancelPopup.hide();
  await this.einvoiceService.CancelEinvoice(this.transID, this.transTypeID, this.cancelReason).then((data: any) => {
    if (data['data'] == "Error") {
      this.alertMessage.errorNotifier(data['message'], 0)
    }
    else {
      this.UpdatedStatus.emit(data['data']);
      this.alertMessage.successNotifier(data['message'], 0)
    }
    this.cancelReason = "";
  });
}
}
