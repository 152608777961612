<div bsModal #modalAssignSerial="bs-modal" class="modal fade" tabindex="-1" role="dialog"
  aria-labelledby="modalAssignSerialLabel" aria-hidden="true" [config]="{ backdrop: 'static' }">
  <div class="modal-dialog modal-xl modal-dialog-scrollable1" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title font-weight-bold">Tracking Method - {{trackingMethod}}</h4>
        <button type="button" class="close" aria-label="Close" (click)="closeModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form class="form-horizontal needs-validation">
          <div class="">
            <div class="row-eq-height">
              <div class="col track-box-success">
                <div class="bd-highlight text-center  p-1">
                  <div class="header-4 text-grey">Item Code: {{serialItemDetails.itemCode}}</div>
                  <div class="header-h-18 ">{{serialItemDetails.itemName}}</div>

                </div>
              </div>
              <div class="col track-box-warning">
                <div class="bd-highlight text-center p-1">
                  <div class="header-4 text-grey">{{_quantityLabel}} Quantity</div>
                  <div class="header-h-18">{{_requiredQuantity}}</div>
                </div>
              </div>
              <div class="col track-box-warning">
                <div class="bd-highlight text-center g p-1">
                  <div class="header-4 text-grey">Assigned Quantity</div>
                  <div class="header-h-18">{{assignedQty}}</div>
                </div>
              </div>
              <div class="col track-box-warning">
                <div class="bd-highlight text-center g p-1">
                  <div class="header-4 text-grey">Unassigned Quantity</div>
                  <div class="header-h-18">{{_requiredQuantity-assignedQty}}</div>
                  <!-- <div class="header-h-18">{{unAssignedQty}}</div> -->
                </div>
              </div>
            </div>
          </div>

          <div class="row mt-4">
            <!-- <div class="col-md-5">
              <div class="ml-1">
                <button class="btn btn-primary" id="btnNewBatch" type="button" (click)="addRows(1)">
                  <span class="c-icon material-icons-outlined text-warning m-0">add</span>
                  &nbsp;Add New Line
                </button>
              </div>
            </div> -->
            <div class="col-md-12 pull-right">
              <div class="d-flex justify-content-end">
                <!-- <div class="text-right" *userCanDelete="[true]">
                  {{totalGridRows}} Items<br>
                  <span class="t-link edit">{{selectedRowCount}} Item selected</span>
                </div> -->
                <div class="col-md-2 pr-0">
                  <div class="form-group search">
                    <span class="material-icons-outlined icon-search">search</span>
                    <input type="text" id="search-box" class="form-control" [(ngModel)]="searchText"
                      [ngModelOptions]="{standalone: true}" (input)="onQuickFilterChanged()" placeholder="Search"
                      xssDirective autocomplete="off" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="">
            <div class="custom-ag-grid-4 mt-2 mb-0 ag-grid-modal">
              <ag-grid-angular style="width: 100%; height: 100%" class="ag-theme-material" [modules]="modules"
                [singleClickEdit]="true" [rowData]="serialMasterData" [gridOptions]="serialItemsGridOptions"
                [columnDefs]="itemscolumnDefs" [frameworkComponents]="frameworkComponents"
                (cellValueChanged)="onCellValueChanged($event)" (cellClicked)="onCellClicked($event)" (sortChanged)="onSortChanged()"
                [pagination]="true">
              </ag-grid-angular>
              <!--<ag-grid-angular style="width: 100%" class="ag-theme-material" agGridXssDirective [modules]="modules"
              [columnDefs]="serialDetailColumnDefs" [singleClickEdit]="false" [rowData]="serialDetailRowData"
              rowSelection="multiple" [gridOptions]="serialDetailGridOptions" [pagination]="true"
              [paginationPageSize]="paginationPageSize" (cellClicked)="onCellClicked($event)"
              (rowSelected)="onRowSelected($event)" [suppressRowClickSelection]="true">
              </ag-grid-angular>-->
              <!-- <div class="d-flex ag-pagination">
                <label class="col-form-label" for="PageSize">Page Size: </label>
                <div style=" width: 70px;">
                  <ng-select [(ngModel)]="paginationPageSize" [ngModelOptions]="{standalone: true}"
                    (change)="onPageSizeChanged()" [items]="lstPagination" id="PageSize" bindValue="value"
                    bindLabel="text" [clearable]="false" [clearOnBackspace]="false">
                  </ng-select>
                </div>
              </div> -->
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer d-block" *ngIf="isEnabledActionButtons">
        <div class="row mb-3">
          <div class="col-md-5">
            <div class="ml-1">
              <button class="btn btn-primary" id="btnNewBatch" type="button" (click)="addRows(1)">
                <span class="c-icon material-icons-outlined text-warning m-0">add</span>
                &nbsp;Add New Line
              </button>
            </div>
          </div>
          <div class="col-md-7 sec-btn d-flex justify-content-end">
            <button class="btn btn-success btn-normal" *userCanEdit="[true]" (click)="onSubmit()">Save</button>
            <button class="btn btn-danger btn-normal" type="button" (click)="closeModal()">
              Cancel
            </button>
          </div>
        </div>
      </div>
      <!-- <div class="modal-footer">
        <div class="mb-3">
          <div class="col sec-btn">
            <button class="btn btn-success btn-normal" (click)="onSubmit()">Save</button>
            <button class="btn btn-danger btn-normal" type="button" (click)="closeModal()">
              Cancel
            </button>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</div>
