<div bsModal #showAddAccountModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
    aria-labelledby="showAddAccountModalLabel" aria-hidden="true" [config]="{ backdrop: 'static' }">
    <div class="modal-dialog modal-md" role="document">
        <form class="form-horizontal needs-validation" novalidate="novalidate" [formGroup]="addAccountForm">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title" *ngIf="addAccountForm.value.caAccountID == 0">
                        {{'SharedModule.Add New Account' | translate}}
                    </h4>
                    <h4 class="modal-title" *ngIf="addAccountForm.value.caAccountID > 0">
                        {{'SharedModule.Edit Account Details' | translate}}
                    </h4>
                    <button type="button" class="close" (click)="showAddAccountModal.hide();">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-md-6">
                            <label class="col-form-label" for="accountCode">
                                {{'AccountingModule.ChartOfAccounts.Account code' | translate}}
                                <span class="text-danger">*</span>
                            </label>
                            <input type="text" formControlName="accountCode" class="form-control" accountcode
                                (blur)="accountCodeExists();" maxlength="10"
                                [ngClass]="{ 'is-invalid': submitted && f.accountCode.errors }" xssDirective />
                            <div *ngIf="submitted && f.accountCode.errors" class="invalid-feedback">
                                <div *ngIf="f.accountCode.errors.required">{{'Common.Is required' | translate}}</div>
                            </div>
                            <div *ngIf="!f.accountCode?.errors?.pattern && accCodeExists.isShow">
                                <div *ngIf="accCodeExists.isExists==true" class="badge badge-pill badge-danger">
                                    '{{accCodeExists.code}}' {{'SharedModule.is not available' | translate}}:
                                    {{accCodeExists.code}} - {{accCodeExists.name}}
                                </div>
                                <div *ngIf="accCodeExists.isExists==false" class="badge badge-pill badge-success">
                                    '{{accCodeExists.code}}' {{'SharedModule.is available' | translate}}
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <label class="col-form-label" for="taxID">
                                {{'Common.Tax' | translate}}
                                <span class="text-danger">*</span>
                            </label>
                            <ng-select formControlName="taxID" class="auto-grow"
                                [ngClass]="{ 'is-invalid': submitted && f.taxID.errors }" [items]="lstTaxRateMaster"
                                bindValue="mstrTaxRateID" bindLabel="taxName">
                            </ng-select>
                            <div *ngIf="submitted && f.taxID.errors" class="invalid-feedback">
                                <div *ngIf="f.taxID.errors.required">{{'Common.Is required' | translate}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 mt-1" *ngIf="islocked">
                            <label>{{'SharedModule.System Account Name' | translate}} : {{systemName}}</label>
                        </div>
                        <div class="col-md-6">
                            <div class="row">
                                <div class="col-md-12">
                                    <label class="col-form-label" for="accountName">
                                        {{'SharedModule.Account Name' | translate}}
                                        <span class="text-danger">*</span>
                                    </label>
                                    <input type="text" formControlName="accountName" class="form-control"
                                        maxlength="100" (blur)="accountNameExists();"
                                        [ngClass]="{ 'is-invalid': submitted && f.accountName.errors }" xssDirective />
                                    <div *ngIf="submitted && f.accountName.errors" class="invalid-feedback">
                                        <div *ngIf="f.accountName.errors.required">{{'Common.Is required' | translate}}
                                        </div>
                                    </div>
                                    <div *ngIf="accountName" class="badge badge-pill badge-danger">
                                        {{f.accountName.value}} - {{'SharedModule.already exist' | translate}}!
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <label class="col-form-label" for="accountTypeID">
                                        {{'SharedModule.Account Type' | translate}}
                                        <span class="text-danger">*</span>
                                    </label>
                                    <ng-select [items]="lstAccountsType" class="auto-grow"
                                        [ngClass]="{ 'is-invalid': submitted && f.accountTypeID.errors }"
                                        bindLabel="accountName" bindValue="accTypeID" groupBy="accountGroupName"
                                        formControlName="accountTypeID" (change)="onAccountsType()">
                                        <ng-template ng-optgroup-tmp let-item="item">
                                            {{item.accountGroupName || 'Unnamed group'}}
                                        </ng-template>
                                    </ng-select>
                                    <div *ngIf="submitted && f.accountTypeID.errors" class="invalid-feedback">
                                        <div *ngIf="f.accountTypeID.errors.required">{{'Common.Is required' |
                                            translate}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <label class="col-form-label" for="accDescription">
                                {{'SharedModule.Description(optional)' | translate}}
                            </label>
                            <textarea formControlName="accDescription" class="form-control" rows="5" maxlength="500"
                                xssDirective></textarea>
                        </div>
                    </div>
                    <div class="row">
                        <div class="mt-3 col-md-6">
                            <div class="col-form-label" style="padding-left: 0px;">
                                <mat-checkbox formControlName="showOnWatchList">
                                    {{'SharedModule.Add in dashboard watchlist' | translate}}
                                </mat-checkbox>
                            </div>
                        </div>
                        <div class="mt-3 col-md-6" *ngIf="showSubAccountCtrl">
                            <div class="col-form-label" style="padding-left: 0px;">
                                <mat-checkbox formControlName="isSubAccount" #isPaymentAccount
                                    (change)="changeIsSubAccount($event)">
                                    Make this a sub-account
                                </mat-checkbox>
                            </div>
                        </div>
                        <div class="mt-3 col-md-6">
                            <div class="col-form-label" style="padding-left: 0px;">
                                <mat-checkbox formControlName="isPaymentAccount" #isPaymentAccount
                                    *ngIf="ShowPaymentAccount">
                                    {{'SharedModule.Is Payment Account' | translate}}
                                </mat-checkbox>
                            </div>
                        </div>
                        <div class="col-md-6" *ngIf="showSubAccountCtrl">
                            <label class="col-form-label" for="parentCAAccountID">
                                Parent Account
                                <span class="text-danger">*</span>
                            </label>
                            <ng-select [items]="lstCAOTypeMaster" class="auto-grow"
                                [ngClass]="{ 'is-invalid': submitted && f.parentCAAccountID.errors }"
                                bindLabel="DisplayAccountName" bindValue="CAAccountID"
                                formControlName="parentCAAccountID">
                            </ng-select>
                            <div *ngIf="submitted && !!f.parentCAAccountID.errors" class="invalid-feedback">
                                <div *ngIf="!!f.parentCAAccountID.errors.required">
                                    {{'Common.Is required' | translate}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button [disabled]="loading" class="btn btn-success btn-normal mr-2" *userCanEdit="[true]"
                        type="button" (click)="saveAddAccount();">
                        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                        {{'Common.Save' | translate}}
                    </button>
                    <button class="btn btn-light" type="button"
                        (click)="cancel();showAddAccountModal.hide();">{{'Common.Cancel' | translate}}</button>
                </div>
            </div>
        </form>
    </div>
</div>