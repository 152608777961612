import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { environment } from '../../../../environments/environment';
import { HttpServices } from '../../../utilities/_services/http.service';
import { MasterServices } from '../../../utilities/_services/master.service';
import { AlertMessage } from '../../../utilities/_helpers/alert.message';
import { GridOptions, Module, AllCommunityModules } from "@ag-grid-community/all-modules";
import { TransactionsModule } from '../../transactions/transactions.module';
import { json } from '@rxweb/reactive-form-validators';
import { LocalCacheServices } from '../../../utilities/_services/acclocalcache.service';
import { TranslateService } from '@ngx-translate/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-backorder',
  templateUrl: './backorder.component.html',
  styleUrls: ['./backorder.component.scss']
})
export class BackorderComponent implements OnInit {

  // activeModal = inject(NgbActiveModal);
  // @ViewChild('showBackOrdermodal') public showBackOrdermodal: ModalDirective;
  @Output() toParentAfterBackOrderOk = new EventEmitter<boolean>();
  overlayLoadingTemplate: string;
  columnDefs: any;
  @Input() rowData: any = [];
  // rowData: any = [];
  public gridOptions: GridOptions;

  constructor(private http: HttpServices, private masterServices: MasterServices, private formBuilder: FormBuilder,private activemodal:NgbActiveModal,
    private alertMessage: AlertMessage,private local: LocalCacheServices, private translate: TranslateService,) {
      this.createColumnDefs();
      this.translate.onDefaultLangChange.subscribe(async() => {
        this.gridOptions.api.refreshHeader();
      });
      this.translate.onLangChange.subscribe(async() => {
        this.gridOptions.api.refreshHeader();
      });
      var loading = this.translate.instant("OrgModlue.Currencies.Please wait while your rows are loading");
      this.overlayLoadingTemplate = `<span class="ag-overlay-loading-center">${loading}</span>`;
    }

  ngOnInit(): void {
    this.gridOptions = <GridOptions>{
      onGridReady: (params: any) => {
        this.gridOptions.api.sizeColumnsToFit();
      }
    }
  }
  private createColumnDefs() {
    this.columnDefs = [
      {
        headerName: 'Item Code',
        field: "itemCode",
        flex: 1,
        headerValueGetter:(params)=>this.translate.instant("InventoryModule.Item.Item Code"),
      },
      {
        headerName: "Item Name",
        field: "itemname",
        flex: 1,
        headerValueGetter:(params)=>this.translate.instant("InventoryModule.Item.Item Name"),
      },
    //   {
    //     headerName: "Warehouse",
    //     field: "warehouseName",
    //     headerValueGetter:(params)=>this.translate.instant("InventoryModule.Item.Warehouse")
    //   },
      {
        headerName: "Quantity Left",
        field: "availableQty",
        headerValueGetter:(params)=>this.translate.instant("InventoryModule.Item.Quantity Left"),
        cellRenderer: (params) => {
                  return '<div >' + params.value + '</div><div class="badge badge-pill badge-success text-wrap">' + params.data.itemUnitTypeName + '</div>';
        }
      },
      {
        headerName: "This Invoice Qty",
        field: "enteredQty",
        headerValueGetter:(params)=>this.translate.instant("InventoryModule.Item.This Invoice Qty")
      },
      {
        headerName: "Final Qty",
        field: "finalQty",
        headerValueGetter:(params)=>this.translate.instant("InventoryModule.Item.Final Qty")
      }
    ];
  }
  openBackOrderModal(backorderList) {
    this.rowData = backorderList;
    // this.showBackOrdermodal.show();
  }

  hideBackOrdermodal(closeOK? :boolean) {
    if(closeOK){
      this.activemodal.close(true);
    }else
    this.activemodal.dismiss();
    // this.showBackOrdermodal.hide();
  }

}
